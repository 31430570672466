import { Select } from "@adasupport/byron";
import React from "react";

import { VariablePill } from "components/Common";
import {
  type Variable,
  getVariableType,
  getVariableValueType,
} from "services/variables";

interface Props {
  errorMessage?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  value: string;
  variables: Variable[];
}

export function LeftOperandVariableSelector({
  errorMessage,
  isDisabled,
  isInvalid,
  onChange,
  value,
  variables,
}: Props) {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const filterVariablesBySearchQuery = (variable: Variable) =>
    variable.name.toLowerCase().includes(searchQuery.toLowerCase());

  const options = variables
    .filter(filterVariablesBySearchQuery)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((variable) => ({
      value: variable._id,
      label: (
        <span title={variable.name}>
          <VariablePill
            name={variable.name}
            type={getVariableType(variable)}
            valueType={getVariableValueType(variable)}
          />
        </span>
      ),
    }));

  return (
    <Select
      isDisabled={isDisabled}
      errorMessage={errorMessage}
      isInvalid={isInvalid}
      onChange={onChange}
      onSearch={handleSearch}
      options={options}
      placeholder="Choose a variable"
      value={value}
    />
  );
}
