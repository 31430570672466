// TODO FEED-12 FEED-19: Fix strict mode errors
// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import debounce from "lodash.debounce";
import React, { type RefObject } from "react";

import { withReadOnlyWrapper } from "components/Common/ReadOnlyWrapper";
import { NO_OP_FUNCTION } from "services/helpers";
import "./styles.scss";

const BLOCK_NAME = "DebouncedInput";

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
// TODO: replace `null` with more sensible default values - intentionally left by TS conversion initiative
interface DebouncedInputComponentProps {
  value: number | string;
  customClassName?: string;
  autoFocus?: boolean;
  forceValueRefresh?: boolean;
  smallFont?: boolean;
  isTextArea?: boolean;
  minHeight?: number;
  debounceTime?: number;
  characterCounter?: number | null;
  isInvalid?: boolean;
  id?: string | null;
  name?: string | null;
  type?: string;
  placeholder?: string | null;
  autoComplete?: string;
  disabled?: boolean;
  autoCorrect?: string;
  datalpignore?: string;
  spellCheck?: boolean;
  tabIndex?: number;
  onKeyPress?(...args: unknown[]): void;
  onChange?(val: string): void;
  onFocus?(...args: unknown[]): unknown;
  onBlur?(...args: unknown[]): unknown;
  editorRef?: RefObject<HTMLDivElement> | null;
  warningMessage?: string;
  label?: string | React.ReactNode;
}

interface DebouncedInputComponentState {
  focused: boolean;
  text: string;
  height: any;
}

class DebouncedInputComponent extends React.Component<
  DebouncedInputComponentProps,
  DebouncedInputComponentState
> {
  editor: any;
  debouncedUpdateMessage: any;
  constructor(props: any) {
    super(props);
    this.editor = React.createRef();

    const { debounceTime = 200, value, editorRef = null } = props;

    this.state = {
      text: value,
      focused: false,
      height: null,
    };

    this.debouncedUpdateMessage = debounce(this.updateMessage, debounceTime);

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    if (editorRef) {
      this.editor = editorRef;
    }
  }

  /**
   */
  componentDidMount() {
    const { autoFocus = false, isTextArea = false } = this.props;

    if (isTextArea) {
      this.updateTextHeight();
    }

    if (this.editor && autoFocus) {
      this.editor.current.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { forceValueRefresh = false } = this.props;
    const { focused, text } = this.state;

    if ((!focused || forceValueRefresh) && nextProps.value !== text) {
      this.setState({
        text: nextProps.value,
      });
    }
  }

  /**
   */
  componentDidUpdate() {
    const { isTextArea = false } = this.props;

    if (isTextArea) {
      this.updateTextHeight();
    }
  }

  get hasExceededLimit() {
    const { characterCounter = null } = this.props;
    const { text } = this.state;

    const textLength =
      text && typeof text === "string" ? text.trim().length : 0;

    return characterCounter === null ? false : textLength > characterCounter;
  }

  handleInputChange(e: any) {
    const text = e.target.value;

    this.setState(
      {
        text,
      },
      () => this.debouncedUpdateMessage(text),
    );
  }

  onFocus() {
    const { onFocus = NO_OP_FUNCTION } = this.props;
    this.setState({ focused: true });
    onFocus();
  }

  onBlur() {
    const { onBlur = NO_OP_FUNCTION } = this.props;
    this.setState({ focused: false });
    onBlur();
  }

  static onMouseDown(e: any) {
    // need this to focus input element inside Draggable element
    e.stopPropagation();
  }

  getInputAttributes() {
    const {
      id = "",
      name = null,
      type = "text",
      placeholder = null,
      autoComplete = "on",
      datalpignore = "",
      disabled = false,
      autoCorrect = "on",
      spellCheck = true,
      tabIndex = 0,
      onKeyPress = NO_OP_FUNCTION,
    } = this.props;

    const { text } = this.state;

    return {
      id,
      name,
      type: type || "text",
      placeholder,
      value: text || "",
      onChange: this.handleInputChange.bind(this),
      autoComplete,
      disabled,
      autoCorrect,
      spellCheck,
      tabIndex,
      // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
      onKeyPress: (e: any) => onKeyPress(e),
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onMouseDown: DebouncedInputComponent.onMouseDown,
      "data-lpignore": datalpignore,
    };
  }

  updateMessage(text: string) {
    const { onChange = NO_OP_FUNCTION } = this.props;

    onChange(text);
  }

  updateTextHeight() {
    const { height: stateHeight } = this.state;
    const { disabled = false } = this.props;
    const height = this.textareaAutosize();

    if (height !== stateHeight && !disabled) {
      this.setState({
        height,
      });
    }
  }

  /**
   * @returns {string|undefined}
   */
  textareaAutosize() {
    const { minHeight = 0 } = this.props;

    if (this.editor?.current) {
      const height = this.editor.current.scrollHeight || 0;

      return `${Math.max(height, minHeight)}px`;
    }

    return undefined;
  }

  renderTextArea() {
    const {
      smallFont = false,
      isInvalid = false,
      customClassName = "",
      characterCounter,
      disabled = false,
    } = this.props;

    const { height } = this.state;

    return (
      <textarea
        ref={this.editor}
        className={classNames(BLOCK_NAME, "g-input", "g-input--type-textarea", {
          [customClassName]: customClassName,
          [`${BLOCK_NAME}--character-counter`]: characterCounter,
          [`${BLOCK_NAME}--character-counter--invalid`]: this.hasExceededLimit,
          "g-input--font-size-small": smallFont,
          "g-input--invalid": isInvalid,
          [`${BLOCK_NAME}--disabled`]: disabled,
        })}
        {...this.getInputAttributes()}
        style={{ height }}
      />
    );
  }

  renderSingleLine() {
    const {
      isInvalid = false,
      customClassName = "",
      characterCounter = null,
    } = this.props;

    return (
      <input
        className={classNames(BLOCK_NAME, "g-input", {
          "g-input--invalid": isInvalid,
          [`${BLOCK_NAME}--character-counter`]: characterCounter,
          [`${BLOCK_NAME}--character-counter--invalid`]: this.hasExceededLimit,
          [customClassName]: customClassName,
        })}
        ref={this.editor}
        {...this.getInputAttributes()}
      />
    );
  }

  render() {
    const {
      isInvalid = false,
      isTextArea = false,
      characterCounter = null,
      warningMessage = null,
      label = "",
      id = null,
    } = this.props;

    const { text } = this.state;
    const textLength =
      text && typeof text === "string" ? text.trim().length : 0;

    const textInput = isTextArea
      ? this.renderTextArea()
      : this.renderSingleLine();

    const textInputContent = (() => {
      if (characterCounter) {
        return (
          <div className={`${BLOCK_NAME}__wrapper`}>
            {textInput}
            {characterCounter && (
              <div
                className={classNames(`${BLOCK_NAME}__character-counter`, {
                  [`${BLOCK_NAME}__character-counter--invalid`]:
                    this.hasExceededLimit,
                })}
              >
                {characterCounter - textLength}
              </div>
            )}
          </div>
        );
      }

      return textInput;
    })();

    return (
      <>
        {label && (
          <label className="g-input__label" htmlFor={id}>
            {label}
          </label>
        )}
        {textInputContent}
        {isInvalid && warningMessage && (
          <div className={`${BLOCK_NAME}__warning-message`}>
            {warningMessage}
          </div>
        )}
      </>
    );
  }
}

export const DebouncedInput = withReadOnlyWrapper(DebouncedInputComponent);
