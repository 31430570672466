import posthog, {
  type CaptureOptions,
  type CaptureResult,
  type Properties,
} from "posthog-js";

import { type User } from "resourceModels";
import { type Client } from "services/client";

export class UserAnalyticsService {
  private static isInitialized = false;

  static init(client: Client, user: User) {
    if (this.isInitialized) return;

    // We will use this to disable lots of tracking features for declarative bots,
    // as we the effort involved in identifying/redacting all PII in declarative
    // is too high for the value it would provide (which is very low)
    // eslint-disable-next-line no-restricted-syntax
    const isGenerative = client.product_type === "generative";

    posthog.init("phc_jGTABqJiWl21YJQ6LBOdKoJw7Bc9e3o0hRVCv69Mpj5", {
      api_host: "https://lovelace.ada.cx",
      loaded: (posthogInstance) => {
        posthogInstance.identify(user.id, {
          client_handle: client.handle,
          client_id: client.id,
          bot_status: client.botStatus,
          user_id: user.id, // Do we need this here if it's already the distinct id?
          // eslint-disable-next-line no-restricted-syntax
          bot_sku: client.product_type,
        });
      },
      disable_session_recording: !isGenerative,
      autocapture: true,
      capture_pageview: true,
      capture_pageleave: true,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true, // Highly recommended as a minimum!!
          // color: false,
          // date: false,
          // 'datetime-local': false,
          email: false,
          // month: false,
          // number: false,
          // range: false,
          // search: false,
          tel: false,
          // text: false,
          // time: false,
          // url: false,
          // week: false,
          // textarea: false,
          // select: false,
        },
      },
    });
    this.isInitialized = true;
  }

  static identify(
    newDistinctId?: string,
    userPropertiesToSet?: Properties,
    userPropertiesToSetOnce?: Properties,
  ): void {
    posthog.identify(
      newDistinctId,
      userPropertiesToSet,
      userPropertiesToSetOnce,
    );
  }

  static reset() {
    posthog.reset();
  }

  static capture(
    eventName: string,
    properties?: Properties | null,
    options?: CaptureOptions,
  ): CaptureResult | void {
    try {
      posthog.capture(eventName, properties, options);
    } catch (error) {
      // silently fail
    }
  }
}
