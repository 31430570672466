// @ts-strict-ignore
import Immutable from "immutable";

import { type CampaignActions } from "actions/campaigns/types";
import {
  type Campaign,
  type CampaignDto,
  type CampaignsState,
  DEFAULT_CAMPAIGNS_STATE,
  DEFAULT_SMS_CAMPAIGN,
  DEFAULT_WEB_CAMPAIGN,
  type SMSCampaign,
  type SMSCampaignDto,
  type WebCampaign,
  type WebCampaignDto,
} from "reducers/campaigns/types";
import {
  deserializeTriggerConditions,
  serializeTriggerConditions,
} from "source/reducers/common/helpers";

// I had to write my own versions of keyConverter because it can't handle the key "type" properly.
function deserializeCampaign(campaign: CampaignDto): Campaign {
  const baseCampaignFields = {
    id: campaign._id,
    campaignKey: campaign.campaign_key,
    name: campaign.name,
    description: campaign.description,
    status: campaign.status,
    updatedBy: campaign.updated_by,
    goals: campaign.goals,
    updated: campaign.updated,
    created: campaign.created,
    channel: campaign.channel,
  };

  if (campaign.channel === "web") {
    return {
      ...baseCampaignFields,
      triggerConditions: deserializeTriggerConditions(
        campaign.trigger_conditions,
      ),
      messageFrequency: campaign.message_frequency,
      messageDelay: campaign.message_delay,
      messageDuration: campaign.message_duration,
      followUpResponseId: campaign.follow_up_response_id,
      responseId: campaign.response_id,
      messageText: campaign.message_text,
    } as WebCampaign;
  }

  return {
    ...baseCampaignFields,
    isComplianceConfirmed: campaign.is_compliance_confirmed,
    responseId: campaign.response_id,
  } as SMSCampaign;
}

// Convert a campaign to the form expected by API, and update any specified attributes
export function serializeCampaign(
  campaign: Campaign,
  updatedAttributes = {},
): CampaignDto {
  const baseCampaignFields = {
    _id: campaign.id,
    campaign_key: campaign.campaignKey,
    name: campaign.name,
    description: campaign.description,
    status: campaign.status,
    updated_by: campaign.updatedBy,
    goals: campaign.goals,
    updated: campaign.updated,
    created: campaign.created,
    channel: campaign.channel,
  };

  if (campaign.channel === "web") {
    return {
      ...baseCampaignFields,
      ...updatedAttributes,
      trigger_conditions: serializeTriggerConditions(
        campaign.triggerConditions,
      ),
      message_frequency: campaign.messageFrequency,
      message_delay: campaign.messageDelay,
      message_duration: campaign.messageDuration,
      follow_up_response_id: campaign.followUpResponseId,
      // response_id and message_text keys should only be included if present
      ...(campaign.responseId ? { response_id: campaign.responseId } : {}),
      ...(campaign.messageText ? { message_text: campaign.messageText } : {}),
    } as WebCampaignDto;
  }

  return {
    ...baseCampaignFields,
    ...updatedAttributes,
    is_compliance_confirmed: campaign.isComplianceConfirmed,
    response_id: campaign.responseId,
  } as SMSCampaignDto;
}

export const campaigns = (
  state = DEFAULT_CAMPAIGNS_STATE,
  action: CampaignActions,
): CampaignsState => {
  switch (action.type) {
    case "FETCH_CAMPAIGNS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_CAMPAIGNS_SUCCESS":
      return {
        ...state,
        loaded: true,
        loading: false,
        // Transform to records of the right type so we get nice typing and attribute autocomplete
        campaigns: action.campaigns.map((campaign) =>
          deserializeCampaign(campaign),
        ),
      };

    case "FETCH_CAMPAIGNS_FAILURE":
      return {
        ...state,
        loading: false,
      };

    case "SET_EMPTY_CAMPAIGNS":
      return {
        ...state,
        loaded: true,
        loading: false,
        campaigns: [],
      };

    case "ADD_CAMPAIGN": {
      const defaultAttributes =
        action.channel === "sms" ? DEFAULT_SMS_CAMPAIGN : DEFAULT_WEB_CAMPAIGN;

      if (action.isEngageClient && action.channel === "web") {
        (defaultAttributes as WebCampaign).messageText = null;
      } else if (action.channel === "web") {
        (defaultAttributes as WebCampaign).messageText = Immutable.Map();
      }

      return {
        ...state,
        campaigns: [
          ...state.campaigns,
          {
            ...defaultAttributes,
            campaignKey: action.campaignKey,
            name: action.name,
            description: action.description,
          },
        ],
      };
    }

    case "SET_CAMPAIGNS_FILTERS":
      return {
        ...state,
        filters: action.filters,
      };

    case "SET_CAMPAIGNS_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.searchQuery,
      };

    default:
      return state;
  }
};
