// TODO FEED-12 FEED-18: Fix strict mode errors
// @ts-strict-ignore
import { type State } from "reducers/types";
import { selectClient } from "services/client";
import { type TrainedExpression } from "slices/training/types";

import { getLanguageName } from "./getLanguageName";

export function getBulkTrainingAddedMessage({
  state,
  expressions,
}: {
  state: State;
  expressions: TrainedExpression[];
}): string {
  let successMessage = "Training added.";
  const client = selectClient(state);

  if (client) {
    const language = getLanguageName({
      state,
      languageCode: expressions[0].language,
    });
    const allSameLanguage =
      expressions.filter(
        (expression) =>
          getLanguageName({
            state,
            languageCode: expression.language,
          }) !== language,
      ).length === 0;

    if (!allSameLanguage) {
      successMessage = "Training added in multiple languages.";
    } else if (language) {
      successMessage = `Training added in ${language}.`;
    } else {
      successMessage = "Training added in an unknown language.";
    }
  }

  return successMessage;
}
