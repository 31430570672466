// @ts-strict-ignore
import Immutable from "immutable";

import { uuid } from "services/generate-uuid";
import { type TypedMap } from "types";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

export type SelectableOption = Immutable.Map<
  "value" | "configuredFieldType",
  string
>;

export type LabelValueOption = Immutable.Map<
  "label" | "value" | "_ref",
  string | null
>;

export type LobListDataItem = Immutable.Map<string, string | null>;

export type LobList = TypedMap<{
  data?: Immutable.List<LobListDataItem>;
  dataVariableId?: string;
  labelKeypath: string;
}>;

export type VariableData = Immutable.Map<string, string | null>;

/**
 * Users are required to include at least one valid template key as denoted by
 * an open double bracket and a matching closing pair {{example}}, but could have many
 */
export function templateHasValidKey(templateString: string) {
  const stack = [];

  if (!templateString.includes("{{")) {
    return false;
  }

  if (!templateString.includes("}}")) {
    return false;
  }

  for (let i = 0; i < templateString.length; i += 1) {
    if (templateString[i] === "{") {
      stack.push(templateString[i]);
    }

    if (templateString[i] === "}") {
      if (stack.length === 0 || stack.length > 2) {
        return false;
      }

      stack.pop();
    }
  }

  return stack.length === 0;
}

interface Attributes extends BaseMessageRecordAttributes {
  type: "list_selection_template";
  prompt: string;
  errorResponseId: string;
  /**
   * @deprecated selectables will be removed after migration to lobList
   */
  selectables?: Immutable.List<SelectableOption>;
  multiple: boolean;
  hasForcedListOptionBlock: boolean;
  variableId?: string;
  hasValidVariable: boolean;
  showExitListOptionBlock: boolean;
  isOptional: boolean;
  hideOptions: boolean;
  isDateTime: boolean;
  lobList?: LobList;
  variablesData: Immutable.List<VariableData>;
}

export class ListSelectionRecord extends BaseMessageRecord<Attributes>({
  type: "list_selection_template",
  prompt: "",
  errorResponseId: "",
  selectables: undefined,
  multiple: false,
  hasForcedListOptionBlock: false,
  variableId: undefined,
  hasValidVariable: true,
  showExitListOptionBlock: true,
  isOptional: false,
  hideOptions: false,
  isDateTime: false,
  lobList: Immutable.Map({
    data: Immutable.List(),
    labelKeypath: "{{label}}",
  }),
  variablesData: Immutable.List(),
}) {
  static getInvalidFields = (
    message: ListSelectionRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (message.prompt.trim() === "") {
      invalidFields.add("prompt");
    }

    if (message.errorResponseId === "") {
      invalidFields.add("errorResponseId");
    }

    if (message.hasValidVariable === false) {
      invalidFields.add("variableId");
    }

    // Selectables is no longer required as the options could be stored in lobList
    const selectables = message.get("selectables");

    if (selectables) {
      const hasEmptyOption = selectables.some((s) => s.get("value") === "");

      if (hasEmptyOption) {
        invalidFields.add("selectables");
      }
    }

    // LOB List is a new property and may not exist if depending on feature flags
    const lobList = message.get("lobList");

    if (lobList && lobList.has("dataVariableId")) {
      if (!lobList.get("dataVariableId")) {
        invalidFields.add("lobList variableList");
      }

      // If "dynamic mode", a label keypath template must be provided and it
      // must contain at least one key reference.
      if (
        !lobList.get("labelKeypath") ||
        !templateHasValidKey(lobList.get("labelKeypath"))
      ) {
        invalidFields.add("labelKeypath");
      }
    } else if (lobList && lobList.get("data")) {
      // If the block is no longer using "selectables", but still only defining labels,
      // all values should be null but are in a valid state
      const data = <Immutable.List<LobListDataItem>>lobList.get("data");

      // Check if there are any values not null
      const valuesNotNull = data.some((object) => object.get("value") !== null);

      const emptyLabels = data.some((object) => !object.get("label"));

      const emptyValues = data.some((object) => !object.get("value"));

      // If any label is falsy, or any value is falsy and all values are not null
      if (emptyLabels || (valuesNotNull && emptyValues)) {
        invalidFields.add("lobList");
      }

      // In "manual mode" the keypath is not exposed and should be set automatically,
      // but this will make sure we didn't introduce a bug which will break on save
      if (
        !lobList.get("labelKeypath") ||
        !templateHasValidKey(lobList.get("labelKeypath"))
      ) {
        invalidFields.add("labelKeypath");
      }
    }

    // Block can now have multiple variables, make sure each has a key and variable id
    const variablesList = message.get("variablesData");

    if (lobList && lobList.get("dataVariableId") && variablesList.size === 0) {
      invalidFields.add("variablesData");
    } else if (variablesList.size > 0) {
      const dataMissingAField = variablesList.find(
        (data) => !data.get("key") || !data.get("variableId"),
      );

      if (dataMissingAField) {
        invalidFields.add("variablesData");
      }
    }

    return Immutable.List(invalidFields);
  };

  static createRow = (value: string | null = null): LabelValueOption =>
    Immutable.Map({
      _ref: uuid(),
      label: "",
      value,
    }) as LabelValueOption;

  static createRowLegacy = (value: string | null = null): LabelValueOption =>
    Immutable.Map({
      configuredFieldType: "string",
      value: value || "",
    }) as LabelValueOption;
}
