// TODO FEED-12 FEED-17: Fix strict mode errors
// @ts-strict-ignore
import Immutable from "immutable";
import { createSelector, createStructuredSelector } from "reselect";

import {
  ALL_MODALITIES,
  MODALITY_DETAILS,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import {
  hasResponseABTestChanged,
  isResponseABTestValid,
} from "features/ABTesting/services";
import { type BuilderABTestsState } from "features/ABTesting/types";
import { type ResponseRecord } from "reducers/responses/types";
import { type LanguageToMessagesMap } from "reducers/responsesLoaded/types";
import { type State } from "reducers/types";
import {
  hasInvalidVariables,
  hasUnsavedVariables,
} from "reducers/variables/helpers";
import { type VariableState } from "reducers/variables/types";
import { type BlockConfigMap, selectBlockConfig } from "selectors/blocks";
import { hasIncompatibleBlocks } from "services/responses/selectors/selectActiveModalityHasIncompatibleBlocks";

import { selectActiveResponseId } from "./selectActiveResponseId";

interface DesiredSelection {
  responseId: string | null;
  responsesLoaded: Immutable.Map<string, ResponseRecord>;
  builderABTestsState: BuilderABTestsState;
  idOfLoadingAnswer: string | null;
  variables: VariableState;
  blockConfigs: BlockConfigMap;
  response?: ResponseRecord;
}

const responseGoodToSaveStructuredSelector = createStructuredSelector<
  State,
  DesiredSelection
>({
  responseId: selectActiveResponseId,
  responsesLoaded: (state) => state.responsesLoaded,
  builderABTestsState: (state) => state.builderABTestsState,
  idOfLoadingAnswer: (state) => state.responsesPage.idOfLoadingAnswer,
  variables: (state) => state.variables,
  blockConfigs: selectBlockConfig,
  response: (state: State, response?: ResponseRecord) => response,
});

function hasLiveModalityWithIncompatibles(
  response: ResponseRecord,
  blockConfigs: BlockConfigMap,
) {
  return ALL_MODALITIES.map((modality) => {
    const modalityHasIncompatibles = hasIncompatibleBlocks(
      response.get(
        MODALITY_DETAILS[modality].messageField,
      ) as LanguageToMessagesMap,
      blockConfigs,
      modality,
    );

    const modalityIsLive = response.get(MODALITY_DETAILS[modality].liveField);

    return modalityHasIncompatibles && modalityIsLive;
  }).some(Boolean);
}

export const selectResponseGoodToSave = createSelector(
  responseGoodToSaveStructuredSelector,
  ({
    responseId: activeResponseId,
    responsesLoaded,
    builderABTestsState,
    idOfLoadingAnswer,
    variables,
    blockConfigs,
    response: providedResponse,
  }) => {
    const responseFromState =
      activeResponseId && responsesLoaded.get(activeResponseId);
    const response = !providedResponse ? responseFromState : providedResponse;

    if (!response) {
      return false;
    }

    const responseId = response.id;
    let hasABTestChangesToSave = false;
    let responseABTestIsValid = true;

    if (responseId) {
      hasABTestChangesToSave = hasResponseABTestChanged(
        responseId,
        builderABTestsState,
      );
      responseABTestIsValid =
        !hasABTestChangesToSave ||
        isResponseABTestValid(
          responseId,
          builderABTestsState.builderABTests,
          response.messages,
        );
    }

    const providedResponseHasChanges =
      providedResponse && !Immutable.is(providedResponse, responseFromState);

    const invalidVariables = hasInvalidVariables(variables, response.id);
    const liveAndIncompatible =
      // HACK: don't care about modality validation in processes right now...
      !response.isProcess &&
      hasLiveModalityWithIncompatibles(response, blockConfigs);
    const isPublishing = responseId && idOfLoadingAnswer === responseId;
    const hasChangesToSave =
      response.new ||
      response.isDirty ||
      providedResponseHasChanges ||
      hasUnsavedVariables(variables, response) ||
      hasABTestChangesToSave;

    return (
      response.handle &&
      hasChangesToSave &&
      response.isValid &&
      !invalidVariables &&
      !isPublishing &&
      !liveAndIncompatible &&
      responseABTestIsValid
    );
  },
) as (state: State, response?: ResponseRecord) => boolean;
