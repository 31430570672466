// TODO FEED-12 FEED-17: Fix strict mode errors
// @ts-strict-ignore
import Immutable from "immutable";
import LRU from "lru-cache";

interface MemoizedFunction<T> {
  (...args: unknown[]): T;
  immutableArgsMap?: unknown[];
  cache?: LRU<unknown, unknown>;
}

/**
 * Memoizes functions with potentially immutable arguments
 * The arguments to the function should either be primitive values
 * or immutable objects, OR one option object with primitive/immutable values
 * @param {Function} fn the function to memoize
 * @param {Number} max maximum entries in the cache
 */
// `any` really is ok here - we don't care what types the args are when memoizing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoize<T>(fn: (...args: any[]) => T, max = 250) {
  const memoizedFn: MemoizedFunction<T> = (...args: unknown[]) => {
    let newArgs = args;

    // if we're passing in an options object as the only argument
    if (newArgs.length === 1 && typeof newArgs[0] === "object") {
      newArgs = Object.values(newArgs[0]);
    }

    // keep track of which arguments are immutable
    if (!memoizedFn.immutableArgsMap) {
      memoizedFn.immutableArgsMap = newArgs.map(Immutable.isImmutable);
    }

    if (!memoizedFn.cache) {
      memoizedFn.cache = new LRU({ max });
    }

    const cacheKeyObj = newArgs.map((arg, i) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      memoizedFn.immutableArgsMap[i] && arg?.hashCode ? arg.hashCode() : arg,
    );
    const cacheKey = JSON.stringify(cacheKeyObj);

    if (memoizedFn.cache.has(cacheKey)) {
      return memoizedFn.cache.get(cacheKey);
    }

    const value = fn(...args);
    memoizedFn.cache.set(cacheKey, value);

    return value;
  };

  return memoizedFn;
}
