import { createApi } from "@reduxjs/toolkit/query/react";

import {
  type Conversation,
  type ConversationLog,
  type VariableGroup,
} from "components/Shared/Pages/Conversations/Conversations/types";
import { type ConversationSummaryResponse } from "reducers/conversationSummaries/types";
import { adaAPI } from "services/api";

export interface ConversationResponse {
  conversations: Conversation[];
  last_sort: string[];
  count: number;
  more_available: boolean;
}

export interface ConversationLogResponse {
  automated_resolution?: {
    status: string | null;
    reason: string | null;
    summary: string | null;
  };
  messages?: ConversationLog[];
  summary?: ConversationSummaryResponse;
  chatter_conversation_count: number;
  storage: [{ title: string; values: [VariableGroup] }];
  chatter: {
    id: string;
    name: string;
    end_user_id?: string;
  };
  call_recording?: {
    chatter_id: string;
    conversation_id: string;
    call_start_time: string;
    call_end_time: string;
    call_recording_duration: number;
    call_recording_url: string;
  };
}

export interface ConversationTopicsResponse {
  topics: [
    {
      _id: string;
      title: string;
    },
  ];
}

const BASE_URL = "/conversations/";

export const ConversationsApi = createApi({
  reducerPath: "conversations",
  baseQuery: (props) => adaAPI.request({ ...props }),
  endpoints: (build) => ({
    getConversations: build.query({
      query: (options) => ({
        method: "POST",
        url: `${BASE_URL}search`,
        data: options.filters,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: { data: ConversationResponse }) =>
        response.data,
    }),
    getUnreadConversations: build.query({
      query: (options) => {
        const newestConvo = new Date(`${options.start}Z`);

        if (newestConvo instanceof Date && !Number.isNaN(newestConvo)) {
          newestConvo.setMilliseconds(newestConvo.getMilliseconds() + 1);
        }

        return {
          method: "POST",
          url: `${BASE_URL}search`,
          data: {
            ...options.filters,
            date: {
              timeZone: "UTC",
              start: newestConvo.toISOString().replace("Z", ""),
              end: options.end,
            },
            size: 0,
            terminate_after: 1,
          },
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: { data: ConversationResponse }) =>
        response.data,
    }),
    getConversation: build.query({
      query: (conversationLogId) => ({
        method: "GET",
        url: `${BASE_URL}logs/${conversationLogId}/`,
      }),
    }),
    getConversationsTopics: build.query<ConversationTopicsResponse, void>({
      query: () => ({
        method: "GET",
        url: `${BASE_URL}topics`,
      }),
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useGetUnreadConversationsQuery,
  useGetConversationQuery,
  useGetConversationsTopicsQuery,
} = ConversationsApi;
