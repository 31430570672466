import * as Immutable from "immutable";

import RecordClassCreator from "services/record";

export class ChatterCsatRecord extends Immutable.Record({
  style: null,
  score: null,
  surveyType: null,
  isPositive: null,
}) {}

export class ChatterNew extends RecordClassCreator({
  type: "chatterNew",

  /** @memberof Chatter# */
  agents: Immutable.List(),

  /** @memberof Chatter# */
  created: null,

  /** @memberof Chatter# */
  csatScore: Immutable.Map(),

  /** @memberof Chatter# */
  email: null,

  /** @memberof Chatter# */
  lastInteraction: null,

  /** @memberof Chatter# */
  online: null,

  /** @memberof Chatter# */
  responses: Immutable.List(),

  /** @memberof Chatter# */
  state: null,

  /** @memberof Chatter# */
  storage: Immutable.Map(),

  /** @memberof Chatter# */
  token: null,

  /** @memberof Chatter# */
  updated: null,

  /** @memberof Chatter# */
  lastConversationLastUpdated: null,

  /** @memberof Chatter# */
  latestCsat: null,
  conversationId: null,
}) {
  static attributeMapper = {
    latestCsat: (value) =>
      value &&
      new ChatterCsatRecord({
        style: value.style,
        score: value.score,
        surveyType: value.surveyType,
        isPositive: value.isPositive,
      }),
  };

  /**
   * @param {String} id
   * @returns {String}
   */
  static getFetchOneUrl(id) {
    return `/chatters/${id}/`;
  }

  /**
   * @returns {String}
   */
  static getFetchManyUrl() {
    return "/chatters/search";
  }

  /**
   * @param {Object} params
   * @returns {Object}
   */
  static getFetchManyUrlParams(params) {
    const { last_sort: lastSort } = params;

    return {
      ...params.filter,
      page: params.page.page,
      size: params.page.size,
      sort: params.sort,
      ...(lastSort && { last_sort: lastSort }),
    };
  }

  /**
   * @param {Object} payload
   * @returns {Object}
   */
  static resolveFetchOneResult(payload) {
    return {
      data: payload.chatter,
    };
  }

  /**
   * @param {Object} payload
   * @returns {Object}
   */
  static resolveFetchManyResult(payload) {
    return {
      data: payload.results.chatters,
      payload,
    };
  }

  /**
   * @returns {String}
   */
  get nameToRender() {
    if (this.storage.get("name")) {
      return this.storage.get("name");
    }

    if (this.storage.get("phone")) {
      return this.storage.get("phone");
    }

    if (this.storage.get("phoneNumber")) {
      return this.storage.get("phoneNumber");
    }

    if (this.email) {
      return this.email;
    }

    if (this.storage.get("testUser")) {
      return "Test User";
    }

    return "Anonymous";
  }
}
