// TODO FEED-12 FEED-17: Fix strict mode errors
// @ts-strict-ignore
import { createSelector } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";

import { type KeyPath } from "services/responses";

import { getActiveMessages, getBaseKeyPath } from ".";

export const selectActiveRootMessagesIdKeyPathMap = createSelector(
  getActiveMessages,
  getBaseKeyPath,
  (activeMessages, baseKeyPath) =>
    activeMessages.reduce(
      (acc, message, index) =>
        acc.set(message.id as string, [...baseKeyPath, index]),
      new Map<string, KeyPath>(),
    ),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);
