import { useDispatch } from "react-redux";

import { confirmOAuthSuccess } from "actions/smartsearch";
import { POPUP_WINDOW_PROPS } from "components/Declarative/Pages/Settings/SettingsPlatforms/LiveAgents/SalesforceLAModal/";

export const useSmartSearchOAuth = (
  integration: "zendesk" | "salesforce" | "confluence",
) => {
  const dispatch = useDispatch();

  return (url: string) =>
    new Promise<void>((resolve) => {
      // Open the popup window
      const win = window.open(url, `${integration}_OAuth`, POPUP_WINDOW_PROPS);

      // Since popup.onbeforeunload doesn't let us execute code anymore (for security), we'll need
      // to set an interval to listen for when the popup window closes
      const interval = setInterval(async () => {
        if (win?.closed) {
          clearInterval(interval);
          await dispatch(confirmOAuthSuccess(integration));
          resolve();
        }
      }, 1000);
    });
};
