import colors from "stylesheets/utilities/colors.scss";

interface IconSpec {
  icon: string;
  color: string;
}

type IconMapType = Record<string, IconSpec>;

export const ICON_MAP: IconMapType = {
  warning: {
    icon: "Warning",
    color: colors.colorUIWarn,
  },
  success: {
    icon: "CircleCheckmark",
    color: colors.colorUIGood,
  },
  error: {
    icon: "ErrorCircle",
    color: colors.colorUIBad,
  },
  info: {
    icon: "InfoFilled",
    color: colors.colorUIPrimary,
  },
  question: {
    icon: "QuestionCircleFilled",
    color: colors.colorUIPrimary,
  },
} as const;
