import { Button } from "@adasupport/byron";
import React from "react";

import { LoginSplash } from "components/Common/LoginSplash";
import * as Modal from "components/Declarative/Pages/Folders/Modals/styles";
import colors from "stylesheets/utilities/colors.scss";

import { useGoToKnowledgeHub } from "./hooks";

export function IntegrationSuccessMessage() {
  const goToKnowledgeHub = useGoToKnowledgeHub();

  const BLOCK_NAME = "SettingsSmartSearchModal";

  return (
    <Modal.Modal>
      <Modal.ModalBody>
        <LoginSplash
          title="Successfully Connected"
          description="View your articles and start generating answers via the Knowledge page. Articles might take a few minutes to sync."
          icon="CircleCheckmark"
          fillColor={colors.colorUIGood}
        />
      </Modal.ModalBody>
      <Modal.ModalFooter className={`${BLOCK_NAME}__bottom `}>
        <Button
          variant="primary"
          text="Go to Knowledge page"
          onClick={goToKnowledgeHub}
        />
      </Modal.ModalFooter>
    </Modal.Modal>
  );
}
