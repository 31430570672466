import { Icon, Select, Tooltip } from "@adasupport/byron";
import React from "react";

import { InputHint } from "components/Common";
import { InputWithVariableAutocompleteDropdown } from "components/Common/InputWithVariableAutocompleteDropdown";
import {
  RULE_DSL,
  type RuleCondition,
  isRuleConditionUnary,
} from "services/rules";
import { type Variable } from "services/variables";

import { RightOperandNumericInput } from "./RightOperandNumericInput";
import * as S from "./styles";

interface Props<R> {
  highlightInvalidFields?: boolean;
  isDisabled?: boolean;
  onChange: (rule: R) => void;
  predefinedValues: {
    [key: string]: { value: string; label: string }[] | undefined;
  };
  rule: R;
  setUpdatedRule: (rule: R) => void;
  updatedRule: R;
  variables: Variable[];
}

export function RightOperand<R extends RuleCondition>({
  highlightInvalidFields,
  isDisabled,
  onChange,
  predefinedValues,
  rule,
  setUpdatedRule,
  updatedRule,
  variables,
}: Props<R>) {
  if (isRuleConditionUnary(rule)) {
    return null;
  }

  const ruleName = updatedRule.name;
  const [leftArg, rightArg, matchCase] = updatedRule.args;
  const selectedVariable = variables.find((v) => v._id === leftArg.id);

  if (selectedVariable?._type === "bool") {
    return (
      <Select
        value={typeof rightArg === "boolean" ? rightArg : null}
        onChange={(value: boolean) => {
          onChange({ ...rule, args: [leftArg, value] });
        }}
        options={[
          { label: "True", value: true },
          { label: "False", value: false },
        ]}
        isDisabled={isDisabled}
      />
    );
  }

  if (selectedVariable?._type === "long") {
    return (
      <RightOperandNumericInput
        leftArg={leftArg}
        rightArg={rightArg as number}
        isDisabled={isDisabled}
        setUpdatedRule={setUpdatedRule}
        rule={rule}
        onChange={onChange}
      />
    );
  }

  if (selectedVariable?._type === "list") {
    if (
      ruleName === RULE_DSL.LENGTH_IS ||
      ruleName === RULE_DSL.LENGTH_LESS_THAN ||
      ruleName === RULE_DSL.LENGTH_GREATER_THAN
    ) {
      return (
        <RightOperandNumericInput
          leftArg={leftArg}
          rightArg={rightArg as number}
          isDisabled={isDisabled}
          setUpdatedRule={setUpdatedRule}
          rule={rule}
          onChange={onChange}
        />
      );
    }
  }

  if (selectedVariable?.name && selectedVariable.name in predefinedValues) {
    const options = predefinedValues[selectedVariable.name];

    return (
      <Select
        isDisabled={isDisabled}
        value={rightArg ?? null}
        onChange={(value: unknown) => {
          onChange({ ...rule, args: [leftArg, value] });
        }}
        options={options ?? []}
      />
    );
  }

  const getValueValidationMessage = (value: unknown) =>
    highlightInvalidFields && !value ? "Required" : undefined;

  const validationMessage = getValueValidationMessage(rightArg);

  return (
    <div style={{ position: "relative" }}>
      <InputWithVariableAutocompleteDropdown
        variableId={leftArg.id}
        isDisabled={isDisabled}
        value={`${rule.args[1]}`}
        isInvalid={validationMessage !== undefined}
        onChange={(value) => {
          onChange({
            ...rule,
            args:
              matchCase !== undefined
                ? [leftArg, value, matchCase]
                : [leftArg, value],
          });
        }}
        placeholder="Value"
      />
      {validationMessage && (
        <InputHint type="error">{validationMessage}</InputHint>
      )}
      <S.MatchCaseButtonContainer>
        <Tooltip text="Case-sensitive">
          <S.MatchCaseButton
            active={matchCase === true}
            onClick={() => {
              onChange({
                ...rule,
                args: [leftArg, rightArg, !matchCase],
              });
            }}
          >
            <Icon.Text width={18} />
          </S.MatchCaseButton>
        </Tooltip>
      </S.MatchCaseButtonContainer>
    </div>
  );
}
