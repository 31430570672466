import { type AnyAction } from "@reduxjs/toolkit";
import { type Dispatch } from "react";

import { type Store } from "./types";

// Enhances the LOCATION_CHANGE action to include rootPath and URL,
// needed for remembering page paths
export default (store: Store) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (action.type !== "@@router/LOCATION_CHANGE") {
      return next(action);
    }

    const { location } = store.getState().router;

    const currentPath = location.pathname;
    const rootPath = currentPath && currentPath.split("/")[1];
    const url = location.pathname + location.search;

    return next({ ...action, rootPath, url });
  };
