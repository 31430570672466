import PropTypes from "prop-types";
import React from "react";

import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";

import { InputRow } from "../LiveAgents/SalesforceLAModal/InputRow";
import { SUNSHINE_CONVERSATIONS_INTEGRATION_GUIDE_URL } from "../constants";

const INVALID_CREDENTIALS_ERROR_MESSAGE =
  "We couldn’t connect to your account. Verify your credentials and try again.";
const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again.";

const ErrorBanner = ({ errorInfo }) => {
  if (errorInfo) {
    let message;

    if (errorInfo.includes("validation_exception")) {
      message = INVALID_CREDENTIALS_ERROR_MESSAGE;
    } else {
      message = GENERIC_ERROR_MESSAGE;
    }

    return (
      <Banner icon="Error" intent="negative">
        {message}
      </Banner>
    );
  }

  return null;
};

ErrorBanner.propTypes = { errorInfo: PropTypes.string };
ErrorBanner.defaultProps = { errorInfo: null };

const SettingsScreen = ({
  channel,
  save,
  update,
  disabled,
  modalRef,
  errorInfo,
  disconnect,
  loading,
}) => {
  const invalidFields = channel?.invalidFields || [];

  return (
    <>
      <h5 className="Modal__title">Configure Sunshine Conversations</h5>
      <div className="Modal__content SunshineConversations__Settings">
        <Banner icon="QuestionCircleFilled">
          Need help?{" "}
          <a
            href={SUNSHINE_CONVERSATIONS_INTEGRATION_GUIDE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Sunshine Conversations Guide
          </a>{" "}
          to assist you in completing this setup.
        </Banner>
        <ErrorBanner errorInfo={errorInfo} />
        <section className="Modal__section SunshineConversations__Settings__connected-app">
          <h6 className="SunshineConversations__Settings__connected-app__heading">
            Connected Sunshine App
          </h6>
          <div className="SunshineConversations__Settings__connected-app__row">
            <span className="app-id">
              <SvgIcon icon="CheckmarkCircle" fillColor="green" />
              <p>{channel.appId}</p>
            </span>
            <Button
              size="small"
              onClick={disconnect}
              lightPrimary
              text="Disconnect"
              linkLike
            />
          </div>
        </section>
        <section className="Modal__section">
          <InputRow
            // N.B.: "split" rows get an extra class name:
            className="SunshineConversations__Settings__cell ph-no-capture"
            label="API Key"
            tooltip="API Key can be found under the \“Settings\” in your Sunshine Conversations app."
            value={channel?.apiKey}
            placeholder="app_5cfa68012430b67758317c08"
            containerRef={modalRef.current}
            onChange={({ target: { value: apiKey } }) => update({ apiKey })}
            invalid={invalidFields.includes("apiKey")}
          />
          <InputRow
            // N.B.: "split" rows get an extra class name:
            className="SunshineConversations__Settings__cell ph-no-capture"
            label="API Secret"
            tooltip={`API Secret can be found under the “Settings”
          in your Sunshine Conversations app.`}
            value={channel?.apiSecret}
            placeholder="mDhqqgwwtDXAZTGNyPOhGrCVd6HjrhxL6cPevQZTX7-5oECtQ6iK5uGHn1mdeoDriAZtyY4SkIgybNRpGiz2Tg"
            containerRef={modalRef.current}
            onChange={({ target: { value: apiSecret } }) =>
              update({ apiSecret })
            }
            invalid={invalidFields.includes("apiSecret")}
          />
        </section>
      </div>
      <div className="Modal__bottom SunshineConversations__Settings__bottom">
        <Button
          onClick={save}
          text="Save"
          title="Save"
          icon="Cloud"
          disabled={disabled}
          isLoading={loading}
          customClassName="SunshineConversationsModal__bottom__save"
        />
      </div>
    </>
  );
};

SettingsScreen.defaultProps = {
  errorInfo: null,
};

SettingsScreen.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    appId: PropTypes.string,
    apiKey: PropTypes.string,
    apiSecret: PropTypes.string,
    enabled: PropTypes.bool,
    invalidFields: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  modalRef: PropTypes.string.isRequired,
  errorInfo: PropTypes.string,
  disconnect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SettingsScreen;
