import { Button, Icon } from "@adasupport/byron";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex } from "components/Common";
import { VariableConfigRow } from "components/Shared/Pages/TestVariablesModal/VariableConfigRow";
import {
  applyTestVariables,
  getValidVariablesCount,
} from "components/Shared/Pages/TestVariablesModal/services";
import { useVariables } from "services/variables";

import { Collapsed } from "./Collapsed";
import { Header } from "./Header";
import * as S from "./styles";

export function TestVariablesModal() {
  const { isTestBotOpen, isVariablePanelCollapsed, variablesInfo } =
    useSelector((state) => state.testVariablesModalState);
  const dispatch = useDispatch();
  const { variables } = useVariables();

  // Rules support only a subset of variables
  const availableVariables =
    variables?.filter(
      (variable) =>
        variable.scope !== "client_secret" &&
        variable.scope !== "oauth" &&
        variable.scope !== "local" &&
        variable.scope !== "builder_ab_tests",
    ) || [];

  const numConfiguredVariables = getValidVariablesCount(
    variablesInfo,
    availableVariables,
  );

  if (!isTestBotOpen) {
    return null;
  }

  if (isVariablePanelCollapsed) {
    return <Collapsed variableCount={numConfiguredVariables} />;
  }

  return (
    <S.Root>
      <Header variableCount={numConfiguredVariables} />
      <S.VariablesConfig>
        {variablesInfo.map((variableInfo, i) => (
          <VariableConfigRow
            index={i}
            variableId={variableInfo.id}
            value={variableInfo.value}
            variables={availableVariables}
          />
        ))}
        <S.AddButtonContainer>
          <Button
            text="Add"
            icon={Icon.Add}
            variant="tertiary"
            onClick={() => {
              dispatch({
                type: "ADD_VARIABLE_INFO",
              });
            }}
          />
        </S.AddButtonContainer>
      </S.VariablesConfig>
      <S.Footer>
        <Flex gap justifyContent="flex-end">
          <Button
            variant="tertiary"
            text="Clear all"
            onClick={() => {
              dispatch({
                type: "RESET_VARIABLES_INFO",
              });
            }}
          />
          {/* This div with a classname is for pendo */}
          <div className="test-variables-modal-apply-button">
            <Button
              text="Apply"
              onClick={() => dispatch(applyTestVariables(variables || []))}
            />
          </div>
        </Flex>
      </S.Footer>
    </S.Root>
  );
}
