// TODO FEED-12 FEED-21: Fix strict mode errors
// @ts-strict-ignore
// Fetch shallow response versions on certain actions

import { type Middleware } from "@reduxjs/toolkit";

import { type Dispatch } from "actions/types";
import { fetchResponseVersionsShallow } from "components/Shared/Pages/Responses/ResponseVersions/actions";
import { RESPONSE_URL_RE } from "constants/responses";

const actionsThatNeedShallowVersionsRefetch = [
  "CREATE_RESPONSE_SUCCESS",
  "CREATE_EXPRESSION_SUCCESS",
  "CREATE_EXPRESSIONS_SUCCESS",
  "PATCH_EXPRESSION_SUCCESS",
  "DELETE_EXPRESSION_SUCCESS",
  "DELETE_EXPRESSIONS_SUCCESS",
  "MOVE_EXPRESSIONS_SUCCESS",
  "RESTORE_TRAINING_SUCCESS",
  "FETCH_RESPONSE_SUCCESS",
  "SAVE_RESPONSE_SUCCESS",
  "SAVE_BOT_CONTENT_SUCCESS",
];

export const versioningMiddleware: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (!actionsThatNeedShallowVersionsRefetch.includes(action.type)) {
      return next(action);
    }

    const state = getState();
    const { location } = state.router;

    // Fetch versions when only this request is from /answers/<response_id> route
    const match = RESPONSE_URL_RE.exec(location?.pathname);

    if (match && match.length > 1) {
      (<Dispatch>dispatch)(
        fetchResponseVersionsShallow({ responseId: match[1] }),
      );
    }

    return next(action);
  };
