// TODO FEED-12 FEED-15: Fix strict mode errors
// @ts-strict-ignore
import { type AxiosResponse } from "axios";
import { push } from "connected-react-router";
import Immutable from "immutable";

import { createAlert } from "actions/alerts";
import { closeModalAction, openModalAction } from "actions/modal";
import { type Dispatch, type ThunkAction } from "actions/types";
import { createUnsavedVariable, updateVariableState } from "actions/variables";
import { versioningViewSelector } from "components/Shared/Pages/Responses/ResponseVersions/selectors";
import { type ExpressionRecord } from "reducers/expressions/types";
import { type BaseMessageRecordAttributes } from "reducers/responses/messageRecords/BaseMessageRecord";
import { type ResponseRecord } from "reducers/responses/types";
import { adaApiRequest } from "services/api";
import { fromEntries, removeFalsyValues } from "services/object";
import { flattenVariables } from "services/records/variables";
import { type VariableRecord } from "services/variables";
import { type State } from "types";

import { type VersionAttributes } from "./reducers/versions";

function getMessageIdsFromResponse(response: ResponseRecord) {
  return response.messages
    .valueSeq()
    .flatMap((v) => v)
    .toList()
    .map((x) => (x as BaseMessageRecordAttributes).id ?? "")
    .filter(Boolean);
}

interface FetchResponseVersionsShallow {
  responseId: string;
  getNextPage?: boolean;
}

export function fetchResponseVersionsShallow({
  responseId,
  getNextPage = false,
}: FetchResponseVersionsShallow) {
  return async (
    dispatch: Dispatch,
    getState: () => State,
  ): Promise<{ versions: VersionAttributes[] } | undefined> => {
    let lastVersionLoadedId;

    if (getNextPage) {
      const state = getState();
      lastVersionLoadedId = state.responseVersions
        .getIn(["shallow", responseId])
        ?.last()
        ?.get("id");
    }

    const params = new URLSearchParams(
      removeFalsyValues({
        after: lastVersionLoadedId,
      }),
    );
    const queryString = params.toString();

    let url = `/responses/${responseId}/versions`;

    if (queryString) {
      url += `?${queryString}`;
    }

    try {
      const { data } = await dispatch(adaApiRequest({ method: "GET", url }));

      dispatch({
        type: "FETCH_RESPONSE_VERSIONS_SHALLOW_SUCCESS",
        responseId,
        data,
        replace: !getNextPage,
      });

      return data as { versions: VersionAttributes[] };
    } catch (error) {
      console.error(error);
    }

    return undefined;
  };
}

interface SaveVersionTitleDescriptionArgs {
  responseId: string;
  versionId?: string;
  title: string;
  description: string;
}

export const saveVersionTitleDescription =
  ({
    responseId,
    versionId,
    title,
    description,
  }: SaveVersionTitleDescriptionArgs): ThunkAction<void> =>
  async (dispatch: Dispatch) => {
    const response = await dispatch(
      adaApiRequest({
        method: "PATCH",
        url: `/versions/${versionId}`,
        data: {
          responseId,
          versionId,
          title,
          description,
        },
      }),
    );

    dispatch({
      type: "PATCH_VERSION_TITLE_DESCRIPTION_SUCCESS",
      response,
      responseId,
      versionId,
    });
  };

export function saveAnswerVersionTitleDescription({
  responseId,
  versionId,
  title = "",
  description = "",
}: SaveVersionTitleDescriptionArgs): ThunkAction<Promise<void>> {
  return async (dispatch: Dispatch, getState: () => State) => {
    const { responseVersions } = getState();

    let versionIdToModify = versionId;

    if (!versionIdToModify) {
      versionIdToModify = responseVersions.getIn([
        "shallow",
        responseId,
        0,
        "id",
      ]);
    }

    try {
      await dispatch(
        saveVersionTitleDescription({
          responseId,
          versionId: versionIdToModify,
          title,
          description,
        }),
      );
    } catch (error) {
      dispatch(createAlert({ message: error.message }));
    }
  };
}

interface FetchResponseVersion {
  responseId: string;
  versionId: string;
}

export function fetchResponseVersion({
  responseId,
  versionId,
}: FetchResponseVersion) {
  return async (dispatch: Dispatch) => {
    if (!responseId || !versionId) {
      return null;
    }

    const url = `/responses/${responseId}/versions/${versionId}`;

    try {
      dispatch({
        type: "FETCH_RESPONSE_VERSION_REQUEST",
        responseId,
      });
      const { data } = await dispatch(adaApiRequest({ method: "GET", url }));
      dispatch({
        type: "FETCH_RESPONSE_VERSION_SUCCESS",
        responseId,
        versionId,
        data,
      });

      return data;
    } catch (error) {
      return error;
    }
  };
}

export const setHighlightMessages = (messageIds: Immutable.List<string>) =>
  ({
    type: "SET_HIGHLIGHT_MESSAGES",
    messageIds,
  } as const);

export interface VariableConflict {
  current: VariableRecord;
  versioned: VariableRecord;
  conflictType: "scope" | "name";
  responseId: string | null;
}

function getVariableConflicts(
  snapshotVariablesList: Immutable.List<VariableRecord>,
  variablesById: Record<string, VariableRecord>,
  response: ResponseRecord,
) {
  const conflicts = new Array<VariableConflict>();

  snapshotVariablesList.forEach((variable: VariableRecord) => {
    const currentVariable = variablesById[variable.id];

    if (!currentVariable) {
      return;
    }

    if (currentVariable.scope !== variable.scope) {
      conflicts.push({
        current: currentVariable,
        versioned: variable,
        conflictType: "scope",
        responseId: response.id,
      });
    }

    if (currentVariable.name !== variable.name) {
      conflicts.push({
        current: currentVariable,
        versioned: variable,
        conflictType: "name",
        responseId: response.id,
      });
    }
  });

  return conflicts;
}

export function replaceBasicDialogContentBlocks(response: ResponseRecord) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "REPLACE_CONTENT_BLOCKS",
      response,
    });
    dispatch(setHighlightMessages(getMessageIdsFromResponse(response)));
    dispatch(push(`/content/basic-dialog/${response.id}/versions`));
  };
}

export function replaceContentBlocks(response: ResponseRecord) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "REPLACE_CONTENT_BLOCKS",
      response,
    });
    dispatch(setHighlightMessages(getMessageIdsFromResponse(response)));
    dispatch(push(`/answers/${response.id}/versions`));
  };
}

export function replaceDeclarativeStepContent(
  response: ResponseRecord,
  path: string,
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "REPLACE_DECLARATIVE_STEP_CONTENT",
      response,
    });
    dispatch(setHighlightMessages(getMessageIdsFromResponse(response)));
    dispatch(push(path));
  };
}

function displayVariableWarnings(
  conflicts: Array<VariableConflict> | undefined,
  response: ResponseRecord,
  dispatch: Dispatch,
  onVariableConflictsResolved: () => void,
) {
  const conflict = conflicts?.pop();
  const responseId = response.id || "";

  if (!responseId) {
    throw new Error("responseId wasn't defined on response");
  }

  if (!conflict) {
    return;
  }

  function nextWarningOrDone() {
    if (conflicts.length) {
      displayVariableWarnings(
        conflicts,
        response,
        dispatch,
        onVariableConflictsResolved,
      );
    } else {
      dispatch(closeModalAction());
      dispatch(replaceContentBlocks(response));
      onVariableConflictsResolved();
      dispatch(push(`/answers/${responseId}/versions`));
    }
  }

  dispatch(
    openModalAction("MODAL_RESTORE_VARIABLE_WARNING", {
      conflict,
      onUseLatest: () => {
        nextWarningOrDone();
      },
      onUseVersioned: () => {
        dispatch(
          updateVariableState({
            variable: conflict.current,
            newVariable: conflict.versioned,
            responseId,
          }),
        );

        nextWarningOrDone();
      },
      currentVariable: conflict.current,
      versionedVariable: conflict.versioned,
      closeModal: () => dispatch(closeModalAction()),
    }),
  );
}

export const replaceTraining = (
  expressions: Immutable.List<ExpressionRecord>,
) =>
  ({
    type: "REPLACE_TRAINING",
    expressions,
  } as const);

export function replaceContentBlocksWithWarnings() {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const { responseVersions } = state;
    const versioning = versioningViewSelector(state);
    const versionId = versioning?.versionId;
    const responseId = versioning?.responseId;
    const restoringDeleted = versioning?.restore;

    const { variables } = getState();

    const snapshot = responseVersions.getIn([
      "full",
      responseId,
      versionId,
      "snapshot",
    ]);
    const snapshotVariables = snapshot.get("variables", Immutable.Map());
    let response = snapshot.get("response");

    if (restoringDeleted) {
      response = response.set("restoringFromVersionId", versionId);
    }

    const snapshotVariablesList = flattenVariables(snapshotVariables);
    const variablesList = flattenVariables(variables);

    const variablesById = fromEntries(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      variablesList.map((variable: VariableRecord) => [variable.id, variable]),
    );

    const conflicts = getVariableConflicts(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      snapshotVariablesList,
      variablesById,
      response,
    );

    const onVariableConflictsResolved = () => {
      snapshotVariablesList.forEach((snapshotVariable: VariableRecord) => {
        dispatch(
          createUnsavedVariable({
            variable: snapshotVariable,
            responseId,
          }),
        );
      });

      if (restoringDeleted) {
        dispatch(replaceTraining(snapshot.get("expressions")));
      }
    };

    if (!conflicts.length) {
      dispatch(replaceContentBlocks(response));
      onVariableConflictsResolved();
    } else {
      displayVariableWarnings(
        conflicts,
        response,
        dispatch,
        onVariableConflictsResolved,
      );
    }
  };
}

export function replaceAnswerSettings(response: ResponseRecord) {
  return {
    type: "REPLACE_ANSWER_SETTINGS",
    response,
  } as const;
}

interface RestoreTraining {
  responseId: string;
  versionId: string;
}

export function restoreTrainingAction({
  responseId,
  versionId,
}: RestoreTraining) {
  return async (dispatch: Dispatch) => {
    const resp = await dispatch(
      adaApiRequest({
        method: "POST",
        url: "/expressions/rpc/restore_training",
        data: {
          response_id: responseId,
          version_id: versionId,
        },
      }),
    );
    dispatch({
      type: "RESTORE_TRAINING_SUCCESS",
    });

    return resp;
  };
}

interface GetLatestAvailableVersionArgs {
  responseId: string;
}

export function getLatestAvailableVersion({
  responseId,
}: GetLatestAvailableVersionArgs) {
  return async (dispatch: Dispatch) => {
    const res: AxiosResponse = await dispatch(
      adaApiRequest({
        method: "GET",
        url: `/responses/${responseId}/versions/latest`,
      }),
    );

    return res.data.version._id;
  };
}
