import { type colorTokens } from "constants/css";
import { TODAY_LOCAL } from "services/date-strings";

import archOne from "./images/arch-1.png";
import circleOne from "./images/circle-1.png";
import circleTwo from "./images/circle-2.png";
import circleThree from "./images/circle-3.png";
import triangleOne from "./images/triangle-1.png";
import triangleTwo from "./images/triangle-2.png";

export type analyticsColors = keyof typeof colorTokens;
interface MetricConfigAttributes {
  minimumValue?: number;
  title: string;
  description: string[];
  points?: string[];
  color: analyticsColors | analyticsColors[];
  note?: string;
  url?: string;
  nextButtonText: string;
  chat: {
    bg: "plum200" | "orange400" | "blue200";
    bottom: boolean;
    right: boolean;
  };
  data: {
    position: "left" | "center" | "right";
    bottom: boolean;
  };
  background: {
    line: number[]; // [left, rotation]
    circle: number[]; // [left, top]
    src: [string, number, number]; // [src, left, top]
  };
}

const START_DATE = "2022-01-01";
const END_DATE = "2022-12-11";

export const WIDTH = 600;
export const HEIGHT = 479;

export const METRIC_CONFIG: Record<string, MetricConfigAttributes> = {
  disclaimer: {
    title: "Before we get started:",
    description: [],
    points: [
      "👉 The data is for {value}",
      "👉 The data is in\n{value} per your\nUser Profile settings",
    ],
    nextButtonText: "Got it",
    color: ["textHeadings", "textHeadings"],
    chat: {
      bg: "orange400",
      bottom: false,
      right: true,
    },
    data: {
      position: "left",
      bottom: true,
    },
    background: {
      line: [80, 0],
      circle: [120, 0],
      src: ["triangleOne", 0, 192],
    },
  },
  engaged_conversations: {
    minimumValue: 2,
    title: "Let’s get into it! 🎉",
    description: [
      "In the last year, there were",
      "{value} conversations",
      "with your bot",
    ],
    nextButtonText: "Tell me more",
    color: "analyticsChart1",
    note: "We’re looking at engaged conversations—conversations where at least one message was sent to the bot",
    chat: {
      bg: "blue200",
      bottom: false,
      right: false,
    },
    data: {
      position: "right",
      bottom: true,
    },
    background: {
      line: [14, 0],
      circle: [-250, -95],
      src: ["archOne", 0, 283],
    },
  },
  total_answers: {
    minimumValue: 50,
    title: "You had responses!",
    description: ["{value} responses", "to be exact 👌"],
    nextButtonText: "Go on",
    color: "analyticsChart2",
    note: "The total number of Answers that were shown to customers, excluding Greetings ",
    chat: {
      bg: "plum200",
      bottom: true,
      right: false,
    },
    data: {
      position: "right",
      bottom: false,
    },
    background: {
      line: [50, 45],
      circle: [-250, 0],
      src: ["circleOne", 100, -100],
    },
  },
  topics_created: {
    minimumValue: 3,
    title: "And you were curious too!",
    description: [
      "Your team tracked",
      "{value} topics",
      "to better understand key themes of interest",
    ],
    nextButtonText: "Cool",
    color: "analyticsChart2",
    chat: {
      bg: "blue200",
      bottom: true,
      right: true,
    },
    data: {
      position: "left",
      bottom: false,
    },
    background: {
      line: [50, 135],
      circle: [30, 95],
      src: ["circleThree", 340, 30],
    },
  },
  topics_created_placeholder: {
    title: "Curious what customers said?",
    description: [
      "Head to our 'Topics' feature to gain insight into conversational trends 🧐",
    ],
    nextButtonText: "Cool",
    color: "analyticsChart3",
    note: "Topics can be added under Conversations > Topics—we’ll link you there after 🔗",
    chat: {
      bg: "blue200",
      bottom: true,
      right: true,
    },
    data: {
      position: "left",
      bottom: false,
    },
    background: {
      line: [50, 135],
      circle: [30, 95],
      src: ["circleThree", 340, 30],
    },
  },
  thumbs_up: {
    minimumValue: 50,
    title: "Customers loved talking to you!",
    description: [
      "They gave ",
      "{value} thumbs up",
      "to Answers they found helpful 👍",
    ],
    nextButtonText: "Sounds good",
    color: "analyticsChart3",
    chat: {
      bg: "plum200",
      bottom: false,
      right: true,
    },
    data: {
      position: "left",
      bottom: true,
    },
    background: {
      line: [90, 0],
      circle: [50, -145],
      src: ["circleOne", 475, 192],
    },
  },
  agent_hours: {
    minimumValue: 50,
    title: "But that's not all!",
    description: ["Your bot saved your agents", "{value} hours", "this year"],
    nextButtonText: "Keep going",
    color: "analyticsChart2",
    note: "We're looking at (Engaged Conversations - Escalated Conversations) * 6.05 minutes, the industry standard Average Handle Time",
    url: "https://www.callcentrehelper.com/industry-standards-metrics-125584.htm",
    chat: {
      bg: "blue200",
      bottom: false,
      right: false,
    },
    data: {
      position: "right",
      bottom: true,
    },
    background: {
      line: [8, 0],
      circle: [-234, -160],
      src: ["triangleTwo", 301, 192],
    },
  },
  answers_created: {
    minimumValue: 5,
    title: "Your team worked hard!",
    description: [
      "There were",
      "{value} new Answers",
      "published in your bot  ✨",
    ],
    nextButtonText: "Good to know",
    color: "analyticsChart3",
    chat: {
      bg: "plum200",
      bottom: true,
      right: false,
    },
    data: {
      position: "right",
      bottom: false,
    },
    background: {
      line: [50, 60],
      circle: [-175, -125],
      src: ["circleTwo", 350, 400],
    },
  },
  ab_tests_shown: {
    minimumValue: 3,
    title: "Your team focused on results ",
    description: ["With a total of", "{value} A/B tests ran 📈"],
    nextButtonText: "Interesting",
    color: "analyticsChart1",
    chat: {
      bg: "blue200",
      bottom: true,
      right: true,
    },
    data: {
      position: "left",
      bottom: false,
    },
    background: {
      line: [65, 315],
      circle: [50, -200],
      src: ["circleOne", 425, 350],
    },
  },
  languages_enabled: {
    minimumValue: 2,
    title: "What about language support?",
    description: ["You supported your customers in", "{value} languages 🌐"],
    nextButtonText: "Awesome",
    color: "analyticsChart2",
    note: "We’re looking at the number of different languages your bot spoke to customers in",
    chat: {
      bg: "blue200",
      bottom: false,
      right: true,
    },
    data: {
      position: "left",
      bottom: true,
    },
    background: {
      line: [95, 0],
      circle: [225, -95],
      src: ["triangleOne", 0, 192],
    },
  },
  report_links: {
    title: "Curious to learn more?",
    description: ["Check out these reports to dig deeper into the data:"],
    nextButtonText: "Continue",
    color: "textHeadings",
    chat: {
      bg: "blue200",
      bottom: false,
      right: false,
    },
    data: {
      position: "right",
      bottom: true,
    },
    background: {
      line: [14, 45],
      circle: [-150, 0],
      src: ["archOne", 0, 283],
    },
  },
};

export const BACKGROUND_IMGS = {
  archOne,
  circleOne,
  circleTwo,
  circleThree,
  triangleOne,
  triangleTwo,
};

export const BASE_REPORT_LINK = `{url}?sd=${START_DATE}&ed=${
  TODAY_LOCAL < END_DATE ? TODAY_LOCAL : END_DATE
}`;
