import type Immutable from "immutable";

import {
  type setCurrentlyDraggingAction,
  type setCurrentlyDraggingToolboxMessageTypeAction,
  type setCurrentlySortingAction,
} from "actions/dragAndDrop";
import { type selectMessageAction } from "actions/responses/selectMessageAction";
import { type setLastCopiedMessageIdAction } from "actions/responses/setLastCopiedMessageIdAction";
import {
  type replaceTraining,
  type setHighlightMessages,
} from "components/Shared/Pages/Responses/ResponseVersions/actions";
import { TRAINING_EDITOR_ID } from "components/Shared/Pages/Responses/ResponseVersions/constants";
import { type setActiveModalityAction } from "components/Shared/Pages/Responses/ResponsesEditor/ChannelTabs";
import {
  MESSAGING_MODALITY,
  type Modality,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type ResponseMessageDto } from "reducers/responses/types";
import { type LanguageCode } from "services/language";
import {
  type KeyPath,
  type addLanguageToResponseMessagesAction,
  type setResponseEditorActiveLanguageAction,
} from "services/responses";

export interface ResponsesPageState {
  activeLanguage: LanguageCode;
  responseQueryFilter: string;
  isLoading: boolean;
  idOfLoadingAnswer: string | null;
  isVariablesLoading: boolean;
  selectedMessageIds: string[];
  collapsedMessageIds: string[];
  lastCopiedMessageId?: string;
  failedToFetchAnswerUrl: string;
  selectedMessageIdsInClipboard: string[]; // Selected messages for copying and pasting

  responseEditorActiveModalities: Record<string, Modality>;

  // keep track of the last page we loaded to the page
  lastPageLoaded: number;

  query: {
    page: number;
    filter: {
      userinput: string;
    };
    sort: {
      sortBy: string;
      sortDirection: string;
    };
  };

  highlightElements: string[] | Immutable.List<string>;
  currentlyDraggingKeyPath: null | KeyPath;
  currentlySortingKeyPath: Partial<Record<string, KeyPath | null>>;
  currentlyDraggingToolboxMessageType: null | string;
  isTranslationPreviewLoading: boolean;
}

export const DEFAULT_RESPONSES_PAGE_STATE: ResponsesPageState = {
  activeLanguage: "en",
  responseQueryFilter: "",
  isLoading: false,
  failedToFetchAnswerUrl: "",
  idOfLoadingAnswer: null,
  isVariablesLoading: true, // True by default in order to make it true before request is made
  selectedMessageIds: [],
  collapsedMessageIds: [],
  selectedMessageIdsInClipboard: [],
  responseEditorActiveModalities: {},

  lastPageLoaded: 1,

  query: {
    page: 1,
    filter: {
      userinput: "",
    },
    sort: {
      sortBy: "created",
      sortDirection: "last",
    },
  },

  highlightElements: [],
  currentlyDraggingKeyPath: null,
  currentlySortingKeyPath: {},
  currentlyDraggingToolboxMessageType: null,
  isTranslationPreviewLoading: false,
};

type Action =
  | ReturnType<typeof addLanguageToResponseMessagesAction>
  | ReturnType<typeof setActiveModalityAction>
  | ReturnType<typeof setHighlightMessages>
  | ReturnType<typeof setResponseEditorActiveLanguageAction>
  | ReturnType<typeof replaceTraining>
  | ReturnType<typeof setCurrentlyDraggingAction>
  | ReturnType<typeof setCurrentlySortingAction>
  | ReturnType<typeof setCurrentlyDraggingToolboxMessageTypeAction>
  | ReturnType<typeof selectMessageAction>
  | ReturnType<typeof setLastCopiedMessageIdAction>
  | { type: "TRANSLATION_PREVIEW_REQUEST" }
  | { type: "TRANSLATION_PREVIEW_COMPLETE" }
  | { type: "GET_ALL_VARIABLES_REQUEST" }
  | { type: "GET_ALL_VARIABLES_SUCCESS" }
  | { type: "GET_ALL_VARIABLES_FAILURE" }
  | { type: "CREATE_VARIABLE_REQUEST"; variable: { originId: string } }
  | { type: "UPDATE_VARIABLE_REQUEST"; variable: { originId: string } }
  | { type: "DELETE_VARIABLE_REQUEST"; variable: { originId: string } }
  | { type: "CREATE_RESPONSE_REQUEST"; oldId: string }
  | { type: "SAVE_BOT_CONTENT_REQUEST"; responseId: string }
  | { type: "SAVE_RESPONSE_REQUEST"; responseId: string }
  | { type: "CREATE_RESPONSE_SUCCESS" }
  | { type: "CREATE_RESPONSE_FAILURE" }
  | { type: "SAVE_RESPONSE_SUCCESS" }
  | { type: "SAVE_RESPONSE_FAILURE" }
  | { type: "CREATE_VARIABLE_FAILURE" }
  | { type: "UPDATE_VARIABLE_FAILURE" }
  | { type: "SAVE_BOT_CONTENT_SUCCESS" }
  | { type: "DELETE_VARIABLE_SUCCESS" }
  | { type: "DELETE_VARIABLE_FAILURE" }
  | { type: "SAVE_BOT_CONTENT_FAILURE" }
  | { type: "SET_RESPONSES_PAGE"; payload: Partial<ResponsesPageState> }
  | { type: "GET_RESPONSES_BY_ID_REQUEST" }
  | { type: "GET_RESPONSES_REQUEST" }
  | { type: "GET_RESPONSES_SHALLOW_REQUEST" }
  | { type: "FETCH_RESPONSE_VERSION_REQUEST" }
  | {
      type: "GET_RESPONSES_BY_ID_SUCCESS";
      response: { data: { response: ResponseMessageDto } };
    }
  | {
      type: "GET_RESPONSES_BY_ID_FAILURE";
      response: { config: { url: string } };
    }
  | { type: "GET_RESPONSES_SUCCESS" }
  | { type: "GET_RESPONSES_SHALLOW_SUCCESS" }
  | { type: "GET_RESPONSES_FAILURE" }
  | { type: "GET_RESPONSES_SHALLOW_FAILURE" }
  | { type: "FETCH_RESPONSE_VERSION_SUCCESS" }
  | {
      type: "SET_COLLAPSED_MESSAGE_IDS";
      payload: string[];
    };

export const responsesPage = (
  state = DEFAULT_RESPONSES_PAGE_STATE,
  action: Action,
): ResponsesPageState => {
  switch (action.type) {
    case "GET_RESPONSES_BY_ID_FAILURE": {
      return {
        ...state,
        failedToFetchAnswerUrl: action.response.config.url,
        isLoading: false,
      };
    }

    case "GET_ALL_VARIABLES_REQUEST":
      return { ...state, isVariablesLoading: true };
    case "GET_ALL_VARIABLES_SUCCESS":
    case "GET_ALL_VARIABLES_FAILURE":
      return { ...state, isVariablesLoading: false };

    case "SET_RESPONSE_EDITOR_ACTIVE_LANGUAGE":
      return { ...state, activeLanguage: action.languageCode };

    case "CREATE_VARIABLE_REQUEST":
    case "UPDATE_VARIABLE_REQUEST":
    case "DELETE_VARIABLE_REQUEST":
      return { ...state, idOfLoadingAnswer: action.variable.originId };

    case "CREATE_RESPONSE_REQUEST":
      return { ...state, idOfLoadingAnswer: action.oldId };

    case "SAVE_BOT_CONTENT_REQUEST":
    case "SAVE_RESPONSE_REQUEST":
      return { ...state, idOfLoadingAnswer: action.responseId };

    case "CREATE_RESPONSE_SUCCESS":
    case "CREATE_RESPONSE_FAILURE":
    case "SAVE_RESPONSE_SUCCESS":
    case "SAVE_RESPONSE_FAILURE":
    case "CREATE_VARIABLE_FAILURE":
    case "UPDATE_VARIABLE_FAILURE":
    case "DELETE_VARIABLE_SUCCESS":
    case "SAVE_BOT_CONTENT_SUCCESS":
    case "SAVE_BOT_CONTENT_FAILURE":
      return { ...state, idOfLoadingAnswer: null };
    case "DELETE_VARIABLE_FAILURE":
      return { ...state, idOfLoadingAnswer: null };

    case "SET_RESPONSES_PAGE":
      return { ...state, ...action.payload };

    case "GET_RESPONSES_BY_ID_REQUEST":
    case "GET_RESPONSES_REQUEST":
    case "GET_RESPONSES_SHALLOW_REQUEST":
    case "FETCH_RESPONSE_VERSION_REQUEST":
      return { ...state, isLoading: true };

    case "GET_RESPONSES_BY_ID_SUCCESS": {
      const responseId = action.response.data.response._id;

      return {
        ...state,
        isLoading: false,
        failedToFetchAnswerUrl: "",
        responseEditorActiveModalities: {
          [responseId]: MESSAGING_MODALITY,
        },
      };
    }

    case "GET_RESPONSES_SUCCESS":
    case "GET_RESPONSES_SHALLOW_SUCCESS":
    case "GET_RESPONSES_FAILURE":
    case "GET_RESPONSES_SHALLOW_FAILURE":
    case "FETCH_RESPONSE_VERSION_SUCCESS":
      return { ...state, isLoading: false };

    case "SET_ACTIVE_MODALITY": {
      return {
        ...state,
        responseEditorActiveModalities: {
          ...state.responseEditorActiveModalities,
          [action.responseId]: action.modality,
        },
      };
    }

    case "SET_HIGHLIGHT_MESSAGES": {
      return { ...state, highlightElements: action.messageIds };
    }

    case "REPLACE_TRAINING": {
      return {
        ...state,
        highlightElements: [...state.highlightElements, TRAINING_EDITOR_ID],
      };
    }

    case "SET_CURRENTLY_DRAGGING": {
      return {
        ...state,
        currentlyDraggingKeyPath: action.keyPath,
      };
    }

    case "SET_CURRENTLY_SORTING": {
      if (!action.containerId) return state;

      return {
        ...state,
        currentlySortingKeyPath: {
          ...state.currentlySortingKeyPath,
          [action.containerId]: action.keyPath,
        },
      };
    }

    case "SET_CURRENTLY_DRAGGING_TOOLBOX_MESSAGE_TYPE": {
      return {
        ...state,
        currentlyDraggingToolboxMessageType: action.messageType,
      };
    }

    case "SET_COLLAPSED_MESSAGE_IDS": {
      return {
        ...state,
        collapsedMessageIds: action.payload,
      };
    }

    case "SET_SELECTED_MESSAGE_IDS": {
      return {
        ...state,
        selectedMessageIds: action.payload,
      };
    }

    case "SET_LAST_COPIED_MESSAGE_ID": {
      return {
        ...state,
        lastCopiedMessageId: action.payload,
      };
    }

    case "TRANSLATION_PREVIEW_REQUEST": {
      return {
        ...state,
        isTranslationPreviewLoading: true,
      };
    }

    case "TRANSLATION_PREVIEW_COMPLETE": {
      return {
        ...state,
        isTranslationPreviewLoading: false,
      };
    }

    default:
      return state;
  }
};
