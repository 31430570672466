import { Button } from "@adasupport/byron";
import { type Key } from "rc-tree/lib/interface";
import { type LegacyDataNode } from "rc-tree-select/lib/interface";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchOneFolderAction } from "actions/folders";
import { closeModalAction } from "actions/modal";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { FolderSelect } from "components/Common/FolderSelect";
import { type FolderRecord } from "reducers/folders/types/folderRecord";
import { createTreeStructure } from "services/folders";

import * as S from "../styles";

interface FolderModalProps {
  onSave: (title: string, parentId?: Key) => void;
  folderNode?: FolderRecord;
  parentFolderId?: Key;
}

export function FolderModal(props: FolderModalProps) {
  const { onSave, folderNode, parentFolderId } = props;
  const foldersState = useSelector((state) => state.foldersState);
  const { rootFolders, rootFolderId, foldersById } = foldersState;

  const [title, setTitle] = useState<string>(folderNode?.title || "");
  const [parentId, setParentId] = useState<Key>(
    parentFolderId || folderNode?.parentId || rootFolderId,
  );
  const dispatch = useDispatch();

  const treeSelectData = useMemo(() => {
    if (rootFolderId) {
      // remove currently updating folder (if exists) from the list
      const filteredFoldersById = foldersById.filter(
        (folder) => folder.get("id") !== folderNode?.id,
      );

      return createTreeStructure({
        rootFolders,
        rootFolderId,
        foldersById: filteredFoldersById,
        isTreeSelect: true,
      });
    }

    return [];
  }, [rootFolders, rootFolderId, foldersById, folderNode]);

  const modalHeader = folderNode ? "Edit folder" : "Create a new folder";

  const isFormValid = () => {
    if (!(title.trim().length > 0)) {
      return false;
    }

    return true;
  };

  const handleCreateOrUpdate = () => {
    onSave(title, parentId || rootFolderId);
  };

  const handleCloseModal = () => {
    dispatch(closeModalAction());
  };

  const onExpandFolder = async (dataNode: LegacyDataNode) => {
    const { key } = dataNode;

    if (key) {
      await dispatch(fetchOneFolderAction(key));
    }
  };

  return (
    <S.Modal>
      <S.ModalHeader>
        <S.ModalTitle data-testid="modal-title">{modalHeader}</S.ModalTitle>
        <S.ModalDescription data-testid="modal-description">
          Give your folder a name to help others find it easily.
        </S.ModalDescription>
      </S.ModalHeader>
      <S.ModalBody>
        <S.FormInput data-testid="folder-name">
          <DebouncedInput
            id="New_folder_modal_title"
            label="Name"
            placeholder="e.g. Presales"
            value={title}
            autoFocus
            isInvalid={title.trim().length < 1}
            onChange={(modalTitle: string) => {
              setTitle(modalTitle);
            }}
          />
        </S.FormInput>
        <S.FormInput>
          <FolderSelect
            initialValue={parentId || rootFolderId}
            onSelectFolder={setParentId}
            folders={treeSelectData}
            label={
              <>
                Add to folder <S.OptionalLabel>(Optional)</S.OptionalLabel>
              </>
            }
            onExpandFolder={onExpandFolder}
          />
        </S.FormInput>
      </S.ModalBody>
      <S.ModalFooter>
        <Button text="Cancel" variant="secondary" onClick={handleCloseModal} />
        <Button
          onClick={handleCreateOrUpdate}
          isDisabled={!isFormValid()}
          text={folderNode ? "Update" : "Create"}
          variant="primary"
        />
      </S.ModalFooter>
    </S.Modal>
  );
}
