import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { SelectSearchDropdown } from "components/Common/SelectSearchDropdown";
import SvgIcon from "components/Common/SvgIcon";
import {
  handleAddScheduleRowAction,
  handleDeleteScheduleRowAction,
  handleSaveScheduleModalAction,
  handleUpdateScheduleModalAction,
  handleUpdateScheduleRowAction,
} from "components/Shared/Pages/Settings/SettingsSchedules/actions";
import { TIME_ZONES_CANONICAL_ARR } from "services/timezones";
import colors from "stylesheets/utilities/colors.scss";

import { SettingsScheduleRow } from "./SettingsScheduleRow";

import "./style.scss";

const BLOCK_NAME = "SettingsSchedulesModal";
const MODAL_ERROR_MESSAGE = "Resolve errors to save this schedule";

export function SettingsSchedulesModal() {
  const settingsScheduledBlockSchedules = useSelector(
    (state) => state.settingsScheduledBlockSchedules,
  );
  const schedule = useSelector((state) => state.settingsScheduleModal);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  function scheduleNameHasConflicts() {
    const currentModalIndex = modal.modalProps.scheduleIndex;

    // If it is invalid, return the error message
    return settingsScheduledBlockSchedules.some(
      (existingSchedule, index) =>
        // Don't want to compare against the schedule we're editing

        index !== currentModalIndex && existingSchedule.name === schedule.name,
    );
  }

  /**
   * Count up and return the top 3 most used timezones.
   */
  function getTimezoneDropdownTopOptions() {
    // Create a memo of the number of occurrences of each timezone
    // in the existing schedules
    const topOptions = settingsScheduledBlockSchedules
      .reduce((acc: { timezone: string; count: number }[], currentSchedule) => {
        if (!acc.find((entry) => currentSchedule.timezone === entry.timezone)) {
          return [...acc, { timezone: currentSchedule.timezone, count: 1 }];
        }

        return acc.map((entry) => {
          if (entry.timezone === currentSchedule.timezone) {
            return {
              ...entry,
              count: entry.count + 1,
            };
          }

          return entry;
        });
      }, [])
      .sort((a, b) => b.count - a.count);

    return topOptions
      .slice(0, 3)
      .map(
        (entry) =>
          TIME_ZONES_CANONICAL_ARR.find(
            (timezone) => timezone.value === entry.timezone,
          ) || { value: entry.timezone, label: entry.timezone },
      ); // fallback to the string if it's not in the map
  }

  const handleUpdateRow = useCallback(
    (
      value: Record<string, unknown>,
      scheduleType: "normalOperatingDaysRows" | "specialOperatingDaysRows",
    ) => {
      dispatch(handleUpdateScheduleRowAction({ ...value, scheduleType }));
    },
    [dispatch],
  );

  const handleUpdateNormalOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleUpdateRow(value, "normalOperatingDaysRows");
    },
    [handleUpdateRow],
  );

  const handleUpdateSpecialOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleUpdateRow(value, "specialOperatingDaysRows");
    },
    [handleUpdateRow],
  );

  const handleDeleteRow = useCallback(
    (
      value: Record<string, unknown>,
      scheduleType: "normalOperatingDaysRows" | "specialOperatingDaysRows",
    ) => {
      dispatch(handleDeleteScheduleRowAction({ ...value, scheduleType }));
    },
    [dispatch],
  );

  const handleDeleteNormalOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleDeleteRow(value, "normalOperatingDaysRows");
    },
    [handleDeleteRow],
  );

  const handleDeleteSpecialOperatingDaysRows = useCallback(
    (value: Record<string, unknown>) => {
      handleDeleteRow(value, "specialOperatingDaysRows");
    },
    [handleDeleteRow],
  );

  const isScheduleInvalid = Boolean(
    !schedule.name || scheduleNameHasConflicts() || !schedule.isValid,
  );

  const { normalOperatingDaysRows, specialOperatingDaysRows } = schedule;

  return (
    <div className={`${BLOCK_NAME} Modal__modal`}>
      <h5 className="Modal__title">
        {modal.modalProps.newSchedule
          ? "New Recurring Schedule"
          : `Edit ${schedule.name}`}
      </h5>
      <div className={`Modal__content ${BLOCK_NAME}__content`}>
        <div
          className={`${BLOCK_NAME}__section ${BLOCK_NAME}__name-timezone-section`}
        >
          <div className={`${BLOCK_NAME}__name-input`}>
            <h2
              className={`g-form__block__label ${BLOCK_NAME}__name-input__label`}
            >
              Schedule Name
            </h2>
            <DebouncedInput
              placeholder="Type a name for this schedule"
              value={schedule.name}
              onChange={(name: string) =>
                dispatch(handleUpdateScheduleModalAction({ name }))
              }
              isInvalid={scheduleNameHasConflicts()}
            />
            {scheduleNameHasConflicts() && (
              <div className="SettingsSchedulesModal__input__error-message">
                This schedule name is already in use
              </div>
            )}
          </div>
          <div className={`${BLOCK_NAME}__timezone-selector`}>
            <h2
              className={`g-form__block__label ${BLOCK_NAME}__name-input__label`}
            >
              Timezone
            </h2>
            <SelectSearchDropdown
              placeholder="Select time zone"
              placeholderIcon="Globe"
              topOptions={getTimezoneDropdownTopOptions()}
              options={TIME_ZONES_CANONICAL_ARR}
              value={schedule.timezone}
              onChange={(timezone) =>
                dispatch(handleUpdateScheduleModalAction({ timezone }))
              }
            />
          </div>
        </div>
        <div
          className={`${BLOCK_NAME}__section ${BLOCK_NAME}__repeat-every-section`}
        >
          <div className={`${BLOCK_NAME}__panel`}>
            <div className={`${BLOCK_NAME}__panel-header`}>
              <SvgIcon
                customClassName={`${BLOCK_NAME}__panel-icon`}
                icon="BrowserRefresh"
                height={18}
              />
              <h6 className={`${BLOCK_NAME}__panel-title`}>Repeat every</h6>
            </div>
            <div className={`${BLOCK_NAME}__panel-content`}>
              {normalOperatingDaysRows.map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`normalOperating${index}`}>
                  <hr className={`${BLOCK_NAME}__panel-divider`} />
                  <SettingsScheduleRow
                    rowIndex={index}
                    row={row}
                    onChange={handleUpdateNormalOperatingDaysRows}
                    onDelete={handleDeleteNormalOperatingDaysRows}
                  />
                </React.Fragment>
              ))}
            </div>

            <div className={`${BLOCK_NAME}__panel-footer`}>
              <hr className={`${BLOCK_NAME}__panel-divider`} />
              <button
                className={`${BLOCK_NAME}__panel-footer__button`}
                type="button"
                onClick={() =>
                  dispatch(
                    handleAddScheduleRowAction({
                      scheduleType: "normalOperatingDaysRows",
                    }),
                  )
                }
              >
                <SvgIcon
                  customClassName={`${BLOCK_NAME}__panel-icon`}
                  icon="Add"
                  height={18}
                />
                Add a new rule
              </button>
              <hr className={`${BLOCK_NAME}__panel-divider`} />
            </div>
          </div>
        </div>
        <div
          className={`${BLOCK_NAME}__section ${BLOCK_NAME}__exception-section`}
        >
          <div className={`${BLOCK_NAME}__panel`}>
            <h6 className={`${BLOCK_NAME}__panel-header`}>
              <SvgIcon
                customClassName={`${BLOCK_NAME}__panel-icon`}
                icon="No"
                height={18}
              />
              <h6 className={`${BLOCK_NAME}__panel-title`}>
                Except&nbsp;
                <span
                  className={`${BLOCK_NAME}__panel-title ${BLOCK_NAME}__panel-title--greyed`}
                >
                  (Optional)
                </span>
              </h6>
            </h6>
            <hr className={`${BLOCK_NAME}__panel-divider`} />
            <div className={`${BLOCK_NAME}__panel-content`}>
              {specialOperatingDaysRows.map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`specialOperating${index}`}>
                  <SettingsScheduleRow
                    rowIndex={index}
                    row={row}
                    onChange={handleUpdateSpecialOperatingDaysRows}
                    onDelete={handleDeleteSpecialOperatingDaysRows}
                    hasExceptionDatePicker
                  />
                  <hr className={`${BLOCK_NAME}__panel-divider`} />
                </React.Fragment>
              ))}
            </div>
            <div className={`${BLOCK_NAME}__panel-footer`}>
              <button
                className={`${BLOCK_NAME}__panel-footer__button`}
                type="button"
                onClick={() =>
                  dispatch(
                    handleAddScheduleRowAction({
                      scheduleType: "specialOperatingDaysRows",
                    }),
                  )
                }
              >
                <SvgIcon
                  customClassName={`${BLOCK_NAME}__panel-icon`}
                  icon="Add"
                  height={18}
                />
                {specialOperatingDaysRows.length > 0
                  ? "Add a new exception"
                  : "Add an exception"}
              </button>
              <hr className={`${BLOCK_NAME}__panel-divider`} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${BLOCK_NAME}__controls Modal__bottom`}>
        <Button
          customClassName={`${BLOCK_NAME}__button-cancel`}
          title="Cancel Schedule"
          text="Cancel"
          onClick={() => dispatch(closeModalAction())}
          light
        />
        <div className="g-warning Modal__bottom__warning">
          {schedule.hasErrors && (
            <div className="SettingsSchedulesModal__errors" key="errors">
              <SvgIcon
                icon="Warning"
                customClassName="Modal__bottom__warning__icon"
                fillColor={colors.colorUIWarn}
              />
              <div>
                <p className="SettingsSchedulesModal__errors__description">
                  {!normalOperatingDaysRows.length
                    ? "Add at least 1 rule to save this schedule"
                    : MODAL_ERROR_MESSAGE}
                </p>
              </div>
            </div>
          )}
        </div>
        <Button
          title="Save Schedule"
          text="Save"
          icon="Cloud"
          disabled={isScheduleInvalid}
          onClick={() => dispatch(handleSaveScheduleModalAction())}
        />
      </div>
    </div>
  );
}
