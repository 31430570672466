// TODO FEED-12 FEED-17: Fix strict mode errors
// @ts-strict-ignore
export function validateJSON(jsonString: string): boolean {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }

  return true;
}

function isParseError(parsedDocument: Document): boolean {
  // parser and parsererrorNS could be cached on startup for efficiency
  const parser = new DOMParser();
  const errorneousParse = parser.parseFromString("<", "text/xml");
  const parsererrorNS =
    errorneousParse.getElementsByTagName("parsererror")[0].namespaceURI;

  return (
    parsedDocument.getElementsByTagNameNS(parsererrorNS, "parsererror").length >
    0
  );
}

export function validateXML(xmlString: string): boolean {
  const parser = new DOMParser();
  // attempt to parse the passed-in xml
  const dom = parser.parseFromString(xmlString, "text/xml");

  return !isParseError(dom);
}
