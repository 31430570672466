// TODO FEED-12 FEED-18: Fix strict mode errors
// @ts-strict-ignore
import { batch } from "react-redux";

import { createAlert } from "actions/alerts";
import { closeModalAction, openModalAction } from "actions/modal";
import { saveResponse, updateResponse } from "actions/responses/index";
import { type ThunkAction } from "actions/types";
import {
  MESSAGING_MODALITY,
  MODALITY_DETAILS,
  type Modality,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { getResponseABTest } from "features/ABTesting/services";
import { type ResponseRecord } from "reducers/responses/types";
import { adaApiRequest } from "services/api";

/**
 * Similar to saveResponse, but updates the live field on the response
 * without updating the rest of the response fields
 */
export function toggleResponseStatus(
  response: ResponseRecord,
  modality: Modality = MESSAGING_MODALITY,
): ThunkAction {
  return async (dispatch) => {
    const responseId = response.id;
    const modalityDetails = MODALITY_DETAILS[modality];
    const responseStatus = !response.get(modalityDetails.liveField);
    const { apiLiveField } = modalityDetails;

    const data = {
      [apiLiveField]: responseStatus,
      ada__versioning__generated_description: responseStatus
        ? modalityDetails.liveFieldToggledOnMessage
        : modalityDetails.liveFieldToggledOffMessage,
    };

    try {
      await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: `/responses/${responseId}`,
          data,
        }),
      );

      batch(() => {
        dispatch({
          type: "UPDATE_RESPONSE_STATUS_SUCCESS",
          responseId,
          responseStatus,
          modality,
        });

        dispatch({
          type: "UPDATE_FOLDER_RESPONSE_STATUS_SUCCESS",
          responseId,
          responseStatus,
          modality,
        });
      });
    } catch (error) {
      dispatch(createAlert({ message: error.response.data.message }));
    }
  };
}

/**
 * Handle updating live state or showing ModalWarning
 */
export function handleLiveToggle(
  responseSnapshot: ResponseRecord,
  modality: Modality = MESSAGING_MODALITY,
): ThunkAction {
  return (dispatch, getState) => {
    const modalityLiveField = MODALITY_DETAILS[modality].liveField;
    const liveFieldCurrentValue = responseSnapshot.get(modalityLiveField);
    const toggleAction = liveFieldCurrentValue ? "off" : "on";
    const responseABTest = getResponseABTest(
      responseSnapshot.id,
      getState().builderABTestsState.builderABTests,
    );

    // Toggle response status and close modal, but do NOT save unsaved changes in response
    const toggleAndCloseModal = () => {
      dispatch(toggleResponseStatus(responseSnapshot, modality));
      dispatch(closeModalAction());
    };

    const toggleWithoutChangesButton = {
      title:
        toggleAction === "off"
          ? "Hide Without Changes"
          : "Make Live Without Changes",
      onClick: toggleAndCloseModal,
    };

    if (responseSnapshot.isDirty) {
      if (!responseSnapshot.isValid) {
        return dispatch(
          openModalAction("MODAL_WARNING", {
            title: "Unsaved Changes",
            message: `
            You have invalid unsaved changes in your Answer.
            ${
              responseABTest?.status === "active" && toggleAction === "off"
                ? "Turning off the Answer will mean A/B Test data collected so far will not be meaningful."
                : ""
            }
            What would you like to do?
          `,
            actions: [
              toggleWithoutChangesButton,
              {
                title: "Cancel",
                onClick() {
                  dispatch(closeModalAction());
                },
              },
            ],
          }),
        );
      }

      return dispatch(
        openModalAction("MODAL_WARNING", {
          title: "Unsaved Changes",
          message: `
          You have unsaved changes in your Answer.
          ${
            responseABTest?.status === "active" && toggleAction === "off"
              ? "Turning off the Answer will mean A/B Test data collected so far will not be meaningful."
              : ""
          }
          What would you like to do?
        `,
          actions: [
            {
              title:
                toggleAction === "off"
                  ? "Save Changes & Hide"
                  : "Save Changes & Make Live",
              buttonTint: "primary",
              onClick: () => {
                dispatch(
                  updateResponse(responseSnapshot.id, {
                    [modalityLiveField]: !liveFieldCurrentValue,
                  }),
                );
                dispatch(saveResponse(responseSnapshot));
                dispatch(closeModalAction());
              },
            },
            toggleWithoutChangesButton,
          ],
        }),
      );
    }

    return dispatch(toggleResponseStatus(responseSnapshot, modality));
  };
}
