/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import "services/AdaLocalize/i18n";
import { DevToolbar } from "components/Common/DevToolbar";
import { GlobalErrorBoundary } from "components/Common/GlobalErrorBoundary";
import { SecurityContainer } from "components/containers/SecurityContainer";
import { history, store } from "services/store";

// Import global styles
import "stylesheets/application.scss";

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
const initLoggingAndRenderApp = () => {
  // Initialize Sentry
  if (window.__env.SENTRY_DSN) {
    const { hostname } = window.location;
    const hostParts = hostname.split(".");

    Sentry.setTag("handle", hostParts[0]);

    Sentry.init({
      dsn: window.__env.SENTRY_DSN,
      environment: window.__env.SENTRY_ENVIRONMENT,
      beforeSend(event: any, hint: any) {
        const error = hint.originalException;

        // we only want to add this extra info for API errors
        // Note: API errors can get here via the CALL_API middleware, or by calling adaAPI directly.
        // This causes inconsistencies in the data available in the error. The following code tries
        // to reconcile as much as it can to make the Sentry issues useful.
        if (error && error.config && error.config.url) {
          const statusCode = error.status || error.response.status;

          // We just want the url path as the fingerprint, ignoring any objectIds that would
          // cause the grouping to break
          const urlPath = error.config.url
            .replace(`https://${window.location.host}`, "")
            .replace(/[0-9a-fA-F]{24}/, "<objectId>");

          // eslint-disable-next-line no-param-reassign
          event.fingerprint = [urlPath, statusCode];
          // eslint-disable-next-line no-param-reassign
          event.exception.values[0].type = "UncaughtApiError";
          // eslint-disable-next-line no-param-reassign
          event.exception.values[0].value = `${urlPath} failed with code ${statusCode}`;

          // eslint-disable-next-line no-param-reassign
          event.tags.statusCode = statusCode;
          // eslint-disable-next-line no-param-reassign
          event.tags.urlPath = urlPath;
        }

        return event;
      },
    });
  }

  if (window.__env.DATADOG_RUM_TOKEN) {
    datadogRum.init({
      applicationId: "3245c635-1dba-46b3-887a-29113a18ba70",
      clientToken: window.__env.DATADOG_RUM_TOKEN,
      site: "datadoghq.com",
      service: "app",
      // Specify a version number to identify the deployed version of your application in Datadog
      version: window.__env.SHA1,
      sampleRate: 100,
      trackInteractions: true,
    });
  }

  const container: any = document.getElementById("app");
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GlobalErrorBoundary>
          {process.env.NODE_ENV === "development" && <DevToolbar />}
          <SecurityContainer />
        </GlobalErrorBoundary>
      </ConnectedRouter>
    </Provider>,
  );
};

initLoggingAndRenderApp();
