import { primitives, tokens } from "@adasupport/byron";
import styled, { css, keyframes } from "styled-components";

export const HLine = styled.div`
  border-top: 1px solid ${primitives.palette.slate200};
`;

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const FadeIn = styled.span<{ time: number }>`
  animation: ${fadeIn} ${(p) => p.time}ms ease-in-out;
`;

export const Pill = styled.span<{
  background?: keyof typeof primitives.palette;
  color?: keyof typeof tokens.colors.text | keyof typeof primitives.palette;
  outline?: boolean;
  font?: "mono" | "sans"; // default is "mono"
}>`
  position: relative;
  display: inline-flex;
  height: 20px;
  align-items: center;
  border-radius: 10px;

  font-family: ${(p) =>
    p.font === "sans"
      ? primitives.fontFamily.sans
      : primitives.fontFamily.mono};

  font-weight: 700;
  font-size: 12px;
  padding: 0 8px;
  white-space: nowrap;
  max-width: 100%;

  background-color: ${(p) =>
    p.background
      ? primitives.palette[p.background]
      : primitives.palette.slate200};

  color: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.color
      ? p.color in tokens.colors.text
        ? tokens.colors.text[p.color as keyof typeof tokens.colors.text]
        : primitives.palette[p.color as keyof typeof primitives.palette]
      : tokens.colors.text.muted};

  ${(p) =>
    p.outline
      ? css`
          outline: 1px solid;
          background-color: transparent;
        `
      : null}
`;
export const Block = styled.div<{
  bgColor?: keyof typeof primitives.palette | "transparent";
  borderColor?: keyof typeof primitives.palette | "transparent";
}>`
  border: 1px solid;
  border-radius: ${primitives.borderRadius.medium};

  border-color: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.borderColor
      ? p.borderColor === "transparent"
        ? "transparent"
        : primitives.palette[p.borderColor]
      : tokens.colors.border.default};

  background-color: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.bgColor
      ? p.bgColor === "transparent"
        ? "transparent"
        : primitives.palette[p.bgColor]
      : tokens.colors.background.main};
`;
