// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import Immutable from "immutable";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { updateOne } from "actions/resources";
import { CheckboxPanel } from "components/Common/CheckboxPanel";
import { type User } from "resourceModels";

interface Props {
  user: User;
}

export function ModalProfileErrorEmails({ user }: Props) {
  const dispatch = useDispatch();

  const isEmailToggleChecked = Boolean(
    user.emails?.get("requestFailedNotifications") ||
      user.emails?.get("variableFailedNotifications") ||
      user.emails?.get("scheduledCallbackFailedNotifications"),
  );

  const updateEmailPreference = useCallback(() => {
    const isEmailNotificationActive = !(
      user.emails.get("requestFailedNotifications") ||
      user.emails.get("variableFailedNotifications") ||
      user.emails.get("scheduledCallbackFailedNotifications")
    );

    // note: any email notification setting that isn't sent here gets erased
    dispatch(
      updateOne("user", user.id, {
        emails: Immutable.Map({
          requestFailedNotifications: isEmailNotificationActive,
          variableFailedNotifications: isEmailNotificationActive,
          scheduledCallbackFailedNotifications: isEmailNotificationActive,
        }),
      }),
    );
  }, [user.emails, user.id, dispatch]);

  return (
    <section>
      <div className="ModalProfile__email-preference">
        <h5 className="Modal__section__title">Email Preferences</h5>
        <CheckboxPanel
          label="Error Emails"
          checked={isEmailToggleChecked}
          handleToggle={updateEmailPreference}
          showTooltip
          tooltipMessage="Errors are generated when a Request Block or Variable fails"
        >
          Notify me of any errors in our bot’s Answer content by email.
        </CheckboxPanel>
      </div>
    </section>
  );
}
