// TODO FEED-12 FEED-17: Fix strict mode errors
// @ts-strict-ignore
import Immutable from "immutable";
import moment from "moment-timezone";

import { type CsatResultAttributes } from "./types";

export * from "./types";

export const CSAT_MESSAGE_SHOWN_TYPE = "csat_shown";
export const CSATT_MESSAAGE_TYPE = "csat";

export function getTimeStringFromTimestamp(timestamp: number | string): string {
  if (typeof timestamp === "number") {
    return moment.unix(timestamp).format("LTS");
  }

  return moment(timestamp).format("LTS");
}

export class CsatResult extends Immutable.Record<CsatResultAttributes>({
  style: null,
  score: null,
  originalScore: null,
  surveyType: null,
  feedback: null,
  isPositive: null,
  resolved: null,
  comment: null,
}) {}
