import { type Record } from "immutable";
import stringify from "json-stable-stringify";
import moment from "moment-timezone";

export function getDateRangeLabel(
  startDate: string,
  endDate: string,
  previousPeriodStartDate?: string,
  previousPeriodEndDate?: string,
): string {
  let dateRangeTitle =
    startDate === endDate
      ? moment(startDate).format("MMM D, YYYY")
      : `${moment(startDate).format("MMM D, YYYY")} – ${moment(endDate).format(
          "MMM D, YYYY",
        )}`;

  if (previousPeriodStartDate && previousPeriodEndDate) {
    dateRangeTitle += ` vs ${
      previousPeriodStartDate === previousPeriodEndDate
        ? moment(previousPeriodStartDate).format("MMM D, YYYY")
        : `${moment(previousPeriodStartDate).format("MMM D, YYYY")} – ${moment(
            previousPeriodEndDate,
          ).format("MMM D, YYYY")}`
    }`;
  }

  return dateRangeTitle;
}

export interface AnalyticsRequestParamsAttributes {
  // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  filter: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export class AnalyticsRequestParams {
  public filter: AnalyticsRequestParamsAttributes["filter"];

  constructor({ filter }: AnalyticsRequestParamsAttributes) {
    this.filter = filter;
  }

  get periodLengthDays(): number {
    return moment(this.filter.endDate).diff(
      moment(this.filter.startDate),
      "days",
    );
  }

  get previousPeriod(): AnalyticsRequestParams {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new this.constructor({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter: this.constructor.Filter({
        ...this.filter.toObject(),

        startDate: moment(this.filter.startDate)
          .subtract(this.periodLengthDays + 1, "day")
          .format("YYYY-MM-DD"),

        endDate: moment(this.filter.startDate)
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
      }),
    });
  }

  get allowedUnitValues(): string[] {
    const { periodLengthDays } = this;

    if (periodLengthDays < 6) {
      return ["day"];
    }

    if (periodLengthDays < 28) {
      return ["day", "week"];
    }

    if (periodLengthDays > 180) {
      return ["week", "month"];
    }

    return ["day", "week", "month"];
  }

  getDateRangeLabel(shouldRequestPreviousPeriod = false): string {
    if (shouldRequestPreviousPeriod) {
      const previousPeriodParams = this.previousPeriod;

      return getDateRangeLabel(
        this.filter.startDate,
        this.filter.endDate,
        previousPeriodParams.filter.startDate,
        previousPeriodParams.filter.endDate,
      );
    }

    return getDateRangeLabel(this.filter.startDate, this.filter.endDate);
  }

  isEqual(analyticsRequestParams: AnalyticsRequestParams): boolean {
    return stringify(this) === stringify(analyticsRequestParams);
  }
}
