// TODO FEED-12 FEED-17: Fix strict mode errors
// @ts-strict-ignore
import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import api from "middleware/api";
import multi from "middleware/multi";
import routeHistory from "middleware/route-history";
import { versioningMiddleware } from "middleware/versioning";
import reducer from "reducers";
import { storage } from "services/storage";
import { webActionsApi } from "services/webActions";
import { AdaApiSlice } from "slices";
import { AresReportApi } from "slices/ares/aresReportsApi";
import { ConversationsApi } from "slices/conversations/conversationsApi";
import { CSATApi } from "slices/csat/csatApi";
import { GeneratedTopicsApi } from "slices/generatedTopics/generatedTopicsApi";
import { GuidanceApi } from "slices/guidance/guidanceApi";
import { OnboardingQuickQuestionsApi } from "slices/onboardingQuickQuestions/onboardingQuickQuestionsApi";
import { ReasoningLogApi } from "slices/reasoningLog/reasoningLogSlice";
import { ResponsesApi } from "slices/responses/responsesApi";

export const history = createBrowserHistory();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: reducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(
      api,
      multi,
      routeHistory,
      routerMiddleware(history),
      versioningMiddleware,
      ConversationsApi.middleware,
      ResponsesApi.middleware,
      AdaApiSlice.middleware,
      AresReportApi.middleware,
      GeneratedTopicsApi.middleware,
      webActionsApi.middleware,
      GuidanceApi.middleware,
      ReasoningLogApi.middleware,
      CSATApi.middleware,
      OnboardingQuickQuestionsApi.middleware,
    ),
  enhancers: [sentryReduxEnhancer],
  devTools: process.env.NODE_ENV !== "production",
});

store.subscribe(() => {
  const { warnings } = store.getState();
  storage.store("warnings", warnings);
});

export { store };
