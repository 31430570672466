// @ts-strict-ignore
import Immutable from "immutable";

import { type FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { validateResponseMessageURL } from "services/strings";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "link";
  altText: string;
  linkText: string;
  generateLinkPreview: boolean;
  newWindow: boolean;
  title: string;
  linkIcon: string;
  linkDescription: string;
  isHandoff: boolean;
  url: string;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export class LinkMessageRecord extends BaseMessageRecord<Attributes>({
  type: "link",
  altText: "",
  linkText: "",
  generateLinkPreview: true,
  newWindow: true,
  title: "",
  linkIcon: "",
  linkDescription: "",
  isHandoff: false,
  url: "",
  successBusinessEvent: null,
}) {
  static getInvalidFields = (
    message: LinkMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (!validateResponseMessageURL(message.url)) {
      invalidFields.add("url");
    }

    return Immutable.List(invalidFields);
  };
}
