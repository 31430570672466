export const VERSIONS_PATHNAME_RE =
  /\/answers\/(\w{24})\/versions(?:\/(\w{24}))?(?:\/(restore))?/;
export const GENERATIVE_VERSIONS_PATHNAME_RE =
  /\/(?:basic-dialog|processes|handoffs)\/(\w{24})\/versions(?:\/(\w{24}))?(?:\/(restore))?/;
export const TRAINING_EDITOR_ID = "training-editor";
export const SIDEBAR_PAGE_SIZE = 10;
export const SETTINGS_PAGE_SIZE = 10;

// version descriptions
export const TRAINING_MOVED_DESCRIPTION = "Training moved";
export const TRAINING_ADDED_DESCRIPTION = "Training added";
export const TRAINING_DELETED_DESCRIPTION = "Training deleted";
export const TRAINING_UPDATED_DESCRIPTION = "Training updated";
export const BATCH_TRAINING_ADDED_DESCRIPTION = "Batch training added";
export const CREATED_ANSWER_DESCRIPTION = "Answer created";
export const DELETED_ANSWER_DESCRIPTION = "Answer deleted";
export const RESTORED_ANSWER_DESCRIPTION = "Answer restored";
export const EDIT_TO_ANSWER_SETTINGS = "Edit to Answer settings";
export const EDIT_TO_ANSWER_CONTENT = "Edit to Answer content";

export const VERSION_LIMIT_WARNING =
  "Versions older than 30 days will be archived.";
