import {
  DSL_OPERATORS_TO_PLAIN_TEXT,
  RULE_DSL,
  type RuleConditionUnary,
  type RuleDefinition,
  type RuleGroup,
} from "./dsl";

export const isRuleConditionUnary = (
  rule: RuleDefinition,
): rule is RuleConditionUnary =>
  rule.name === RULE_DSL.IS_SET || rule.name === RULE_DSL.IS_NOT_SET;

export const isRuleGroup = (rule: RuleDefinition): rule is RuleGroup =>
  rule.name === RULE_DSL.ALL || rule.name === RULE_DSL.ANY;

export const translateRuleToPlainText = (
  rule: RuleDefinition,
  getVariableName: (variableId: string) => string,
  argIndex?: number,
  isPartOfOrGroup?: boolean,
): string => {
  if (rule.name === RULE_DSL.ALL) {
    const isConjunction = rule.args.length > 1;

    return rule.args
      .map((subRule, index) =>
        translateRuleToPlainText(subRule, getVariableName, index, false),
      )
      .join(isConjunction ? ", " : "");
  }

  if (rule.name === RULE_DSL.ANY) {
    const isDisjunction = rule.args.length > 1;

    return rule.args
      .map((subRule, index) =>
        translateRuleToPlainText(subRule, getVariableName, index, true),
      )
      .join(isDisjunction ? " or " : "");
  }

  // At this point we know that the rule is a condition (not a group), so we can safely cast it to RuleCondition
  // "value" will be undefined for unary conditions
  const [variable, value] = rule.args;

  const variableName = getVariableName(variable.id);
  const operatorName = DSL_OPERATORS_TO_PLAIN_TEXT[rule.name];

  const shouldOmitVariableName = isPartOfOrGroup && argIndex && argIndex > 0;

  if (isRuleConditionUnary(rule)) {
    if (shouldOmitVariableName) {
      return operatorName;
    }

    return `${variableName} ${operatorName}`;
  }

  // Omit variable name in each condition except the first one in an OR group
  if (shouldOmitVariableName) {
    return `${operatorName} ${value}`;
  }

  return `${variableName} ${operatorName} ${value}`;
};

export const INITIAL_RULESET: RuleGroup = {
  name: "all",
  args: [
    {
      name: "any",
      args: [{ name: "equals", args: [{ type: "variable", id: "" }, ""] }],
    },
  ],
};
