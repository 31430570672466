import { Icon, Select, Tooltip, primitives, tokens } from "@adasupport/byron";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { Flex, FlexColumn, InputHint, VariablePicker } from "components/Common";
import { InputWithVariableAutocompleteDropdown } from "components/Common/InputWithVariableAutocompleteDropdown";
import { ALL_SCOPES } from "constants/variables";
import {
  RULE_DSL,
  type RuleCondition,
  type RuleGroup,
  isRuleConditionUnary,
  isRuleGroup,
} from "services/rules";
import { type Variable } from "services/variables";

import { RuleGroupOperator } from "./RuleGroupOperator";
import { RuleOperatorSelector } from "./RuleOperatorSelector";
import {
  BOOLEAN_OPERATOR_OPTIONS,
  DEFAULT_BOOLEAN_ARGUMENT,
  DEFAULT_NUMBER_ARGUMENT,
  DEFAULT_STRING_ARGUMENT,
  LIST_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  STRING_OPERATOR_OPTIONS,
} from "./helpers";

const S = {
  MatchCaseButtonContainer: styled.div`
    position: absolute;
    top: 6px;
    right: 6px;
    white-space: nowrap;
  `,

  MatchCaseButton: styled.button<{ active: boolean }>`
    background: none;
    border: none;
    color: ${tokens.colors.text.muted};
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    padding: 0;

    ${(p) =>
      p.active &&
      css`
        color: ${tokens.colors.text.mainInverted};
        font-weight: 600;
        background-color: ${primitives.palette.slate500};
      `}
  `,
};

export const RuleConditionComponentV1 = <R extends RuleCondition>({
  rule,
  onChange,
  ruleGroup,
  variables,
  highlightInvalidFields,
  predefinedValues = {},
  isDisabled,
}: {
  rule: R;
  onChange: (rule: R) => void;
  ruleGroup: RuleGroup;
  variables: Variable[];
  highlightInvalidFields?: boolean;
  predefinedValues?: {
    [key: string]: { value: string; label: string }[] | undefined;
  };
  isDisabled?: boolean;
}) => {
  // We want to keep rule object in state in case it's not valid
  // In that case we don't want to call onChange
  const [updatedRule, setUpdatedRule] = useState(rule);
  useEffect(() => setUpdatedRule(rule), [rule]);

  const [leftArg, rightArg, matchCase] = updatedRule.args;
  const ruleName = updatedRule.name;
  const selectedVariable = variables.find((v) => v._id === leftArg.id);

  const renderNumericValueInput = () => {
    const getValueValidationMessage = (value: unknown) => {
      // Check if value represents a number
      if (/^[-+]?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?$/g.test(`${value}`)) {
        return undefined;
      }

      return "Please enter a valid number";
    };

    const validationMessage = getValueValidationMessage(rightArg);

    return (
      <>
        <InputWithVariableAutocompleteDropdown
          variableId={leftArg.id}
          isDisabled={isDisabled}
          value={`${rightArg}`}
          isInvalid={validationMessage !== undefined}
          onChange={(value) => {
            setUpdatedRule({ ...rule, args: [leftArg, value] });

            if (getValueValidationMessage(value) === undefined) {
              onChange({ ...rule, args: [leftArg, Number(value)] });
            }
          }}
          placeholder="Value"
        />
        {validationMessage && (
          <InputHint type="error">{validationMessage}</InputHint>
        )}
      </>
    );
  };

  const renderValueInput = () => {
    if (isRuleConditionUnary(rule)) {
      return null;
    }

    if (selectedVariable?._type === "bool") {
      return (
        <Select
          value={typeof rightArg === "boolean" ? rightArg : null}
          onChange={(value: boolean) => {
            onChange({ ...rule, args: [leftArg, value] });
          }}
          options={[
            { label: "True", value: true },
            { label: "False", value: false },
          ]}
          isDisabled={isDisabled}
        />
      );
    }

    if (selectedVariable?._type === "long") {
      return renderNumericValueInput();
    }

    if (selectedVariable?._type === "list") {
      if (
        ruleName === RULE_DSL.LENGTH_IS ||
        ruleName === RULE_DSL.LENGTH_LESS_THAN ||
        ruleName === RULE_DSL.LENGTH_GREATER_THAN
      ) {
        return renderNumericValueInput();
      }
    }

    if (selectedVariable?.name && selectedVariable.name in predefinedValues) {
      const options = predefinedValues[selectedVariable.name];

      return (
        <Select
          isDisabled={isDisabled}
          value={rightArg ?? null}
          onChange={(value: unknown) => {
            onChange({ ...rule, args: [leftArg, value] });
          }}
          options={options ?? []}
        />
      );
    }

    const getValueValidationMessage = (value: unknown) =>
      highlightInvalidFields && !value ? "Required" : undefined;

    const validationMessage = getValueValidationMessage(rightArg);

    return (
      <div style={{ position: "relative" }}>
        <InputWithVariableAutocompleteDropdown
          variableId={leftArg.id}
          isDisabled={isDisabled}
          value={`${rule.args[1]}`}
          isInvalid={validationMessage !== undefined}
          onChange={(value) => {
            onChange({
              ...rule,
              args:
                matchCase !== undefined
                  ? [leftArg, value, matchCase]
                  : [leftArg, value],
            });
          }}
          placeholder="Value"
        />
        {validationMessage && (
          <InputHint type="error">{validationMessage}</InputHint>
        )}
        <S.MatchCaseButtonContainer>
          <Tooltip text="Case-sensitive">
            <S.MatchCaseButton
              active={matchCase === true}
              onClick={() => {
                onChange({
                  ...rule,
                  args: [leftArg, rightArg, !matchCase],
                });
              }}
            >
              <Icon.Text width={18} />
            </S.MatchCaseButton>
          </Tooltip>
        </S.MatchCaseButtonContainer>
      </div>
    );
  };

  const onVariableChange = (id: string | null) => {
    const newVariable = variables.find((v) => v._id === id);

    // Update operator if new variable type is incompatible with current operator
    const newRuleName = (() => {
      switch (newVariable?._type) {
        case "bool":
          return BOOLEAN_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS;
        case "long":
          return NUMBER_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS;
        case "list":
          return LIST_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS_SET;
        default:
          return STRING_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS;
      }
    })();

    // Update right argument if new variable type is incompatible with current right argument type
    const newRightArg = (() => {
      switch (newVariable?._type) {
        case "long":
          return typeof rightArg === "number"
            ? rightArg
            : DEFAULT_NUMBER_ARGUMENT;
        case "bool":
          return typeof rightArg === "boolean"
            ? rightArg
            : DEFAULT_BOOLEAN_ARGUMENT;
        default:
          return typeof rightArg === "string"
            ? rightArg
            : DEFAULT_STRING_ARGUMENT;
      }
    })();

    const newRule = {
      ...rule,
      name: newRuleName,
    };

    onChange({
      ...newRule,

      // Remove right argument if operator changes to unary (IS_SET, IS_NOT_SET)
      args: isRuleConditionUnary(newRule)
        ? [{ ...leftArg, id }]
        : [{ ...leftArg, id }, newRightArg],
    });
  };

  const previousRule = ruleGroup.args[ruleGroup.args.indexOf(rule) - 1];
  const isSecondary =
    previousRule &&
    !isRuleGroup(previousRule) &&
    JSON.stringify(rule.args[0]) === JSON.stringify(previousRule.args[0]);

  return (
    <>
      <FlexColumn shrink={1} style={{ flexBasis: "30%" }}>
        <Flex gap justifyContent="flex-end">
          {previousRule && (
            <RuleGroupOperator
              ruleGroup={ruleGroup}
              index={ruleGroup.args.indexOf(rule)}
            />
          )}

          {!isSecondary && (
            <FlexColumn grow={1} shrink={1}>
              <VariablePicker
                value={leftArg.id}
                onChange={onVariableChange}
                variables={variables}
                isInvalid={highlightInvalidFields && !leftArg.id}
                isDisabled={isDisabled}
                allowedScopesForCreation={[]}
                allowedScopes={ALL_SCOPES.filter(
                  (scope) => scope !== "auto_capture",
                )}
              />
              {highlightInvalidFields && !leftArg.id && (
                <InputHint type="error">Required</InputHint>
              )}
            </FlexColumn>
          )}
        </Flex>
      </FlexColumn>

      <FlexColumn style={{ flexBasis: "30%", maxWidth: 200 }}>
        <RuleOperatorSelector
          rule={rule}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </FlexColumn>
      <FlexColumn grow={1} shrink={1} noBasis>
        {renderValueInput()}
      </FlexColumn>
    </>
  );
};
