import isMatch from "lodash.ismatch";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { type LanguageCode } from "services/language";

import {
  resetClientAction,
  saveClientAction,
  saveClientAndAlertAction,
  updateClientAction,
} from "./actions";
import { selectClient, selectClientInvalidFields } from "./selectors";
import { type Client } from "./types";

export function useClient() {
  const client = useSelector(selectClient);
  const clientInvalidFields = useSelector(selectClientInvalidFields);
  const dispatch = useDispatch();

  if (!client) {
    throw new Error(
      "`client` is null. Make sure client is fetched before using useClient() hook. Use `useFetchClient()` hook for that.",
    );
  }

  return {
    client,

    clientInvalidFields,

    clientIsDirty: !isMatch(
      useSelector((state) => state.client.resource || {}),
      useSelector((state) => state.client.changedAttributes),
    ),

    updateClient: (attributes: Partial<Client>) =>
      dispatch(updateClientAction(attributes)),

    saveClient: () => dispatch(saveClientAction()),

    saveClientAndAlert: (successMessage = "Bot settings have been updated.") =>
      dispatch(saveClientAndAlertAction(successMessage)),

    resetClient: () => dispatch(resetClientAction()),

    getBotNameValue: useCallback(
      (languageCode: LanguageCode) =>
        client.uiSettings.chat.bot_name[languageCode] || "",
      [client],
    ),

    getBotDescription: useCallback(
      (languageCode: LanguageCode) =>
        client.uiSettings.chat.bot_description[languageCode] || "",
      [client],
    ),

    getFallbackLinks: useCallback(
      (languageCode: LanguageCode) =>
        client.uiSettings.chat.fallback_ui_settings.links[languageCode] || [],
      [client],
    ),

    clientHasLiveAgentFeature: Boolean(
      client.features.ada_glass_nuance ||
        client.features.ada_glass_salesforce ||
        client.features.ada_glass_zendesk,
    ),

    /**
     * Returns `true` if the client is a free trial client.
     */
    isFreeTrial: client.botStatus === "free_trial",

    /**
     * Returns `true` if the client is a free trial client who's trial has expired.
     *
     * Potential pitfall: this will return `false` if the client is not a free trial client.
     */
    isFreeTrialExpired: Boolean(
      client.botStatus === "free_trial" &&
        client.trialEndDate !== null &&
        // Convert trialEndDate to milliseconds
        client.trialEndDate * 1000 < Date.now(),
    ),

    /**
     * Returns `true` if the client is a free trial client who's trial is still active.
     *
     * Potential pitfall: this will return `false` if the client is not a free trial client.
     */
    isFreeTrialActive: Boolean(
      client.botStatus === "free_trial" &&
        client.trialEndDate !== null &&
        // Convert trialEndDate to milliseconds
        client.trialEndDate * 1000 > Date.now(),
    ),
  };
}
