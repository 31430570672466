import { AdaApiSlice } from "slices";

import { type Variable } from "./types";

const AdaApiSliceWithTags = AdaApiSlice.enhanceEndpoints({
  addTagTypes: ["Variable"],
});

export const variablesApi = AdaApiSliceWithTags.injectEndpoints({
  endpoints: (build) => ({
    getVariables: build.query({
      query: ({ scope }) => ({
        method: "GET",
        url: "/v2/variable",
        params: { scope },
      }),
      transformResponse: (response: { variables: Variable[] }) =>
        response.variables,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({
                type: "Variable" as const,
                id,
              })),
              { type: "Variable", id: "LIST" },
            ]
          : [{ type: "Variable", id: "LIST" }],
    }),
    createVariable: build.mutation<
      { _id: string },
      Pick<Variable, "name" | "scope" | "description" | "_type">
    >({
      query: (variable) => ({
        method: "POST",
        url: "/variables",
        data: variable,
      }),
      transformResponse: (response: { variable: Variable }) => ({
        _id: response.variable._id,
      }),
      invalidatesTags: [{ type: "Variable", id: "LIST" }],
    }),
    editVariable: build.mutation<
      Variable,
      Pick<Variable, "_id" | "name" | "description" | "capture_modes">
    >({
      query: (variable) => ({
        method: "PATCH",
        url: `/variables/${variable._id}`,
        data: variable,
      }),
      invalidatesTags: [{ type: "Variable", id: "LIST" }],
    }),
  }),
});

export const {
  useGetVariablesQuery,
  useCreateVariableMutation,
  useEditVariableMutation,
} = variablesApi;
