// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import classnames from "classnames";
import React, {
  type ChangeEvent,
  type ReactNode,
  useRef,
  useState,
} from "react";
import useOnClickOutside from "react-cool-onclickoutside";

import { InputSearch } from "components/Common/InputSearch";
import SvgIcon from "components/Common/SvgIcon";
import { cssVariables } from "constants/css";
import { getHeight, getListHeight, shouldOpenUp } from "services/dropdown";
import { escapeRegexp } from "services/strings";

import * as S from "./styles";

import "./style.scss";

const BLOCK_NAME = "SelectSearchDropdown";

interface Option {
  label: string;
  value: string;
}

interface Props {
  placeholder: string;
  placeholderIcon?: string | null;
  options: Option[];
  topOptions?: Option[];
  topOptionsLimit?: number;
  value?: string | null;
  onChange: (selectedValue: string) => void;
  onSearch?: (searchQuery: string) => void;
  /* A react element that will be rendered above the search element */
  dropdownHeader?: ReactNode;
  emptyState?: ReactNode;
  disabled?: boolean;
  warningMessage?: string;
  isInvalid?: boolean;
  minWidth?: number | null;
}

export function SelectSearchDropdown({
  topOptions = [],
  options,
  value = null,
  placeholder,
  placeholderIcon = null,
  topOptionsLimit = 3,
  dropdownHeader = null,
  onChange,
  onSearch,
  emptyState,
  disabled = false,
  warningMessage,
  isInvalid = false,
  minWidth = null,
}: Props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [listOpen, setListOpen] = useState(false);
  const [inputSearchFocus, setInputSearchFocus] = useState(false);
  const triggerRef = useRef(null);

  /**
   * find the option object that contains the selected value
   * value can exist in options or topOptions
   * @returns {Object|null}
   */
  const findOptionByValue = () => {
    const currentOption = options.find((option) => option.value === value);
    const selectedTopOption = topOptions.find(
      (topOption) => topOption.value === value,
    );

    if (currentOption) {
      return currentOption;
    }

    if (selectedTopOption) {
      return selectedTopOption;
    }

    return null;
  };

  /**
   * Show or hide the dropdown menu
   */
  const handleToggleList = () => {
    if (disabled) {
      return;
    }

    setListOpen(!listOpen);
    setInputSearchFocus(false);
    setSearchQuery("");
  };

  /**
   * Keep track of input search bar focus event
   */
  const handleInputSearchFocus = () => {
    setInputSearchFocus(!inputSearchFocus);
  };

  const handleInputSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    onSearch && onSearch(e.target.value); // eslint-disable-line
  };

  const handleItemSelect = (val: string) => {
    onChange(val);
    handleToggleList();
  };

  const ref = useOnClickOutside(() => {
    setListOpen(false);
    setInputSearchFocus(false);
    setSearchQuery("");
  });

  const selectedOption = findOptionByValue();
  const limitedTopOptions = topOptionsLimit
    ? topOptions.slice(0, topOptionsLimit)
    : topOptions;
  const serachRegexp = new RegExp(escapeRegexp(searchQuery), "i");
  const filteredOptions = onSearch
    ? options
    : options.filter((option) => serachRegexp.test(option.label));

  const emptyStateRendered = emptyState || (
    <div
      className={`${BLOCK_NAME}__search-no-results`}
      data-testid="empty-message"
    >
      No results found&hellip;
    </div>
  );

  return (
    <div ref={ref}>
      <S.HeaderButton
        ref={triggerRef}
        type="button"
        disabled={disabled}
        onClick={handleToggleList}
        isActive={listOpen && !inputSearchFocus}
        isInvalid={!listOpen && isInvalid}
      >
        {placeholderIcon ? (
          <div className={`${BLOCK_NAME}__header-icon`}>
            <SvgIcon icon={placeholderIcon} height={24} />
          </div>
        ) : null}
        <S.HeaderTitle>
          {selectedOption ? selectedOption.label : placeholder}
        </S.HeaderTitle>
        <div className={`${BLOCK_NAME}__header-arrow`}>
          <SvgIcon icon="ChevronDown" height={24} />
        </div>
      </S.HeaderButton>

      {listOpen && (
        <S.DropdownContainer
          minWidth={minWidth}
          triggerHeight={getHeight(triggerRef.current)}
          shouldOpenUp={shouldOpenUp(triggerRef.current)}
          listHeight={getListHeight(triggerRef.current)}
        >
          {dropdownHeader && (
            <div className={`${BLOCK_NAME}__dropdown-header`}>
              {dropdownHeader}
            </div>
          )}

          <div className={`${BLOCK_NAME}__search-block`}>
            <InputSearch
              customClassName={`${BLOCK_NAME}__search-block-input`}
              value={searchQuery}
              onChange={handleInputSearchChange}
              onFocus={handleInputSearchFocus}
              placeholder="Search"
              hideChevron
              invalid={isInvalid}
            />
          </div>

          {filteredOptions.length > 0 ? (
            <ul className={`${BLOCK_NAME}__list-group`}>
              {limitedTopOptions.length > 0 && searchQuery === "" && (
                <>
                  {limitedTopOptions.map((topOption) => (
                    <li
                      className={`${BLOCK_NAME}__list-item-container`}
                      key={`${topOption.value}-${topOption.label}`}
                    >
                      <button
                        type="button"
                        className={classnames(`${BLOCK_NAME}__list-item`, {
                          [`${BLOCK_NAME}__list-item--active`]:
                            selectedOption &&
                            selectedOption.value === topOption.value,
                        })}
                        onClick={() => handleItemSelect(topOption.value)}
                      >
                        {topOption.label}
                        <div
                          className={`${BLOCK_NAME}__list-item-selected-icon`}
                        >
                          {selectedOption &&
                            selectedOption.value === topOption.value && (
                              <SvgIcon
                                icon="Checkmark"
                                height={cssVariables.svgIconHeight.numHeight}
                              />
                            )}
                        </div>
                      </button>
                    </li>
                  ))}
                  <li className={`${BLOCK_NAME}__list-item-container`}>
                    <div className={`${BLOCK_NAME}__list-separator`} />
                  </li>
                </>
              )}
              {filteredOptions.map((filteredOption) => (
                <li
                  className={`${BLOCK_NAME}__list-item-container`}
                  key={`${filteredOption.value}-${filteredOption.label}`}
                >
                  <button
                    type="button"
                    className={classnames(`${BLOCK_NAME}__list-item`, {
                      [`${BLOCK_NAME}__list-item--active`]:
                        selectedOption &&
                        selectedOption.value === filteredOption.value,
                    })}
                    onClick={() => handleItemSelect(filteredOption.value)}
                  >
                    {filteredOption.label}
                    <div className={`${BLOCK_NAME}__list-item-selected-icon`}>
                      {selectedOption &&
                        selectedOption.value === filteredOption.value && (
                          <SvgIcon
                            icon="Checkmark"
                            height={cssVariables.svgIconHeight.numHeight}
                          />
                        )}
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            emptyStateRendered
          )}
        </S.DropdownContainer>
      )}
      {isInvalid && warningMessage && (
        <div className={`${BLOCK_NAME}__warning-message`}>{warningMessage}</div>
      )}
    </div>
  );
}
