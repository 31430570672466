// TODO FEED-12 FEED-18: Fix strict mode errors
// @ts-strict-ignore
import { type Action } from "@reduxjs/toolkit";
import queryString from "query-string";

import { createAlert } from "actions/alerts";
import {
  CREATE_EXPRESSION_FAILURE,
  CREATE_EXPRESSION_REQUEST,
  CREATE_EXPRESSION_SUCCESS,
  PATCH_EXPRESSION_LANGUAGE_FAILURE,
  PATCH_EXPRESSION_LANGUAGE_REQUEST,
  PATCH_EXPRESSION_LANGUAGE_SUCCESS,
  PATCH_EXPRESSION_REQUEST,
  PATCH_EXPRESSION_SUCCESS,
} from "actions/expressions/types";
import { openModalAction } from "actions/modal";
import { type Dispatch, type ThunkAction } from "actions/types";
import { trainGroupExpression } from "components/Declarative/Pages/Questions/actions";
import {
  BATCH_TRAINING_ADDED_DESCRIPTION,
  TRAINING_ADDED_DESCRIPTION,
  TRAINING_UPDATED_DESCRIPTION,
} from "components/Shared/Pages/Responses/ResponseVersions/constants";
import { type ExpressionRecord } from "reducers/expressions/types";
import { adaApiRequest } from "services/api";
import { selectClient } from "services/client";
import { ResponsesApi } from "slices/responses/responsesApi";
import { deserializeTrainedExpression } from "slices/training/deserialization";
import {
  type SerializedTrainedExpression,
  type TrainedExpression,
} from "slices/training/types";

import { alreadyTrainedAlert } from "./helpers/alreadyTrainedAlert";
import { invalidateExpressionLanguagesCache } from "./helpers/invalidateExpressionLanguagesCache";
import { trainingAddedAlert } from "./helpers/trainingAddedAlert";

const alreadyTrained = (message: string) =>
  message && message.includes("already trained");

export function getTrainedExpressionsAction(payload: {
  responseId: string;
  lastExpressionId?: string;
  clear?: boolean;
  language?: string;
}): Action {
  const { responseId, lastExpressionId, clear = true, language } = payload;
  const params = queryString.stringify({
    language,
    after: lastExpressionId,
  });
  let getExpressionsURL = `/responses/${responseId}/expressions/`;

  if (params) {
    getExpressionsURL = `${getExpressionsURL}?${params}`;
  }

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "get",
      endpoint: getExpressionsURL,
      args: {
        responseId,
        clear,
      },
      types: [
        "GET_TRAINED_EXPRESSIONS_REQUEST",
        "GET_TRAINED_EXPRESSIONS_SUCCESS",
        "GET_TRAINED_EXPRESSIONS_FAILURE",
      ],
    },
  } as unknown as Action;
}

export function getTrainedExpressionsCountAction(response: {
  responseId: string;
}): Action {
  const { responseId } = response;

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "get",
      endpoint: `/responses/${responseId}/expressions/count`,
      args: {
        responseId,
      },
      types: [
        "GET_TRAINED_EXPRESSIONS_COUNT_REQUEST",
        "GET_TRAINED_EXPRESSIONS_COUNT_SUCCESS",
        "GET_TRAINED_EXPRESSIONS_COUNT_FAILURE",
      ],
    },
  } as unknown as Action;
}

export function createExpressionAction(
  responseId: string,
  body: string,
  source = "MANUAL_ENTRY",
  showResponseNavigationButton = false,
): ThunkAction {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_EXPRESSION_REQUEST,
    });

    try {
      const response = await dispatch(
        adaApiRequest({
          method: "POST",
          url: "/expressions/",
          data: {
            response_id: responseId,
            body,
            ada__versioning__generated_description: TRAINING_ADDED_DESCRIPTION,
            source,
          },
        }),
      );
      dispatch({
        type: CREATE_EXPRESSION_SUCCESS,
        response: { data: { expression: response.data.expression } },
      });
      dispatch(
        trainingAddedAlert({
          responseId,
          expression: response.data.expression as ExpressionRecord,
          showResponseNavigationButton,
        }),
      );

      if (
        (response.data.expression as SerializedTrainedExpression).language ===
        null
      ) {
        dispatch(
          openModalAction("MODAL_SET_EXPRESSION_LANGUAGE", {
            expression: deserializeTrainedExpression(
              response.data.expression as SerializedTrainedExpression,
            ),
          }),
        );
      }

      dispatch(ResponsesApi.util.invalidateTags(["Languages"]));
    } catch (error) {
      dispatch({
        type: CREATE_EXPRESSION_FAILURE,
      });

      const { message } = error.response.data;

      if (alreadyTrained(message)) {
        dispatch(
          alreadyTrainedAlert({
            response: error.response,
            currentResponseId: responseId,
            body,
          }),
        );
      } else {
        dispatch(createAlert({ message: error.response.data.message }));
      }
    }
  };
}

export function updateExpressionAction({
  expression,
  body,
}: {
  expression: TrainedExpression;
  body: string;
}): ThunkAction {
  const { id, responseId, language } = expression;

  return async (dispatch: Dispatch, getState) => {
    try {
      dispatch({ type: PATCH_EXPRESSION_REQUEST });

      const state = getState();
      const client = selectClient(state);

      let updatedPreTranslationBody;

      if (client && language !== client.language) {
        updatedPreTranslationBody = body;
      }

      const response = await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: `/expressions/${id}`,
          data: {
            body,
            pre_translation_body: updatedPreTranslationBody,
            response_id: responseId,
            ada__versioning__generated_description:
              TRAINING_UPDATED_DESCRIPTION,
          },
        }),
      );

      dispatch({
        type: PATCH_EXPRESSION_SUCCESS,
        response: { data: { updatedExpressions: [response.data.expression] } },
        payload: {
          previousLanguage: expression.language,
          language: (response.data.expression as ExpressionRecord).language,
        },
      });
      dispatch(
        createAlert({
          message: "Training updated.",
          alertType: "success",
        }),
      );
      dispatch(ResponsesApi.util.invalidateTags(["Languages"]));
    } catch (error) {
      dispatch({ type: "PATCH_EXPRESSION_FAILURE" });
      const { message } = error.response.data;

      if (alreadyTrained(message)) {
        dispatch(
          alreadyTrainedAlert({
            response: error.response,
            currentResponseId: responseId,
            body,
          }),
        );
      } else {
        dispatch(createAlert({ message: error.response.data.message }));
      }
    }
  };
}

export function updateExpressionLanguageAction(
  expression: TrainedExpression,
  languageCode: string,
): ThunkAction {
  return async (dispatch: Dispatch) => {
    const { id, preTranslationBody, body, responseId } = expression;

    try {
      dispatch({ type: PATCH_EXPRESSION_LANGUAGE_REQUEST });

      const response = await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: `/expressions/${id}`,
          data: {
            language: languageCode,
            pre_translation_body: preTranslationBody || body,
            response_id: responseId,
            ada__versioning__generated_description:
              TRAINING_UPDATED_DESCRIPTION,
          },
        }),
      );
      dispatch({
        type: PATCH_EXPRESSION_LANGUAGE_SUCCESS,
        response: { data: { updatedExpressions: [response.data.expression] } },
        payload: {
          previousLanguage: expression.language,
          language: (response.data.expression as ExpressionRecord).language,
        },
      });
      dispatch(
        createAlert({
          message: "Training updated.",
          alertType: "success",
        }),
      );
      dispatch(ResponsesApi.util.invalidateTags(["Languages"]));
    } catch (error) {
      dispatch({ type: PATCH_EXPRESSION_LANGUAGE_FAILURE });

      const { message } = error.response.data;

      if (alreadyTrained(message)) {
        dispatch(
          alreadyTrainedAlert({
            response: error.response,
            currentResponseId: responseId,
            body,
          }),
        );
      } else {
        dispatch(createAlert({ message: error.response.data.message }));
      }
    }
  };
}

export function trainExpressionsAction({
  ids,
  search,
  responseId,
  fetchNextExpressions = true,
  groupId,
}: {
  ids: string[];
  search: string;
  responseId: string;
  fetchNextExpressions?: boolean;
  groupId?: string;
}): Action {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "patch",
      endpoint: "/expressions/",
      payload: {
        _ids: ids,
        search,
        response_id: responseId,
        ada__versioning__generated_description:
          BATCH_TRAINING_ADDED_DESCRIPTION,
      },
      args: {
        ids,
        responseId,
        clear: false,
      },
      types: [
        "TRAIN_EXPRESSIONS_REQUEST",
        "TRAIN_EXPRESSIONS_SUCCESS",
        "TRAIN_EXPRESSIONS_FAILURE",
      ],
      dispatchCallbacks: fetchNextExpressions
        ? [
            {
              request: getTrainedExpressionsAction,
              fireOnStatus: 200,
            },
            {
              request: getTrainedExpressionsCountAction,
              fireOnStatus: 200,
            },
            {
              request: trainGroupExpression,
              fireOnStatus: 200,
              args: {
                expressionIds: ids,
                groupId,
              },
            },
            {
              request: invalidateExpressionLanguagesCache,
              fireOnStatus: 200,
            },
          ]
        : [
            {
              request: trainGroupExpression,
              fireOnStatus: 200,
              args: {
                expressionIds: ids,
                groupId,
              },
            },
          ],
    },
  } as unknown as Action;
}

export const trainExpressions = trainExpressionsAction;
