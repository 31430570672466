// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import ResponseStatus from "components/Common/ResponseStatus";
import { Tag } from "components/Common/Tag";

import * as S from "./styles";

export type ResponseReferencesList = {
  languages: {
    language: string;
    index: number;
  }[];
  response: {
    id: string;
    handle: string;
    live: boolean;
    tags: string[];
  };
}[];

interface Props {
  references: ResponseReferencesList;
}

export function ReferenceList({ references }: Props) {
  const tags = useSelector((state) => state.tags);
  const dispatch = useDispatch();

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolledToBottom(
      event.currentTarget.scrollTop >=
        event.currentTarget.scrollHeight - event.currentTarget.offsetHeight,
    );
  };

  return (
    <S.ReferenceListContainer>
      <S.ReferenceListContent onScroll={handleScroll}>
        {references.map((reference) => (
          <div key={reference.response.id}>
            {reference.languages.map((lang) => {
              const { response } = reference;
              const { language } = lang;

              return (
                <S.ReferenceButton
                  type="button"
                  key={`${response.id}-${language}`}
                  onClick={() => {
                    dispatch(closeModalAction());
                    // TODO: fix these links (they always go to the default language)
                    dispatch(
                      setPageAction(
                        `/answers/${response.id}?language=${language}`,
                      ),
                    );
                  }}
                >
                  <S.InfoGroup>
                    <S.Title>{response.handle}</S.Title>
                    <div>
                      <ResponseStatus isSmall isOn={response.live} />
                    </div>
                    <S.Language>{language}</S.Language>
                  </S.InfoGroup>
                  {response.tags.length > 0 && (
                    <S.TagGroup>
                      {response.tags.map((tagID) => (
                        <S.Tag key={tagID}>
                          <Tag
                            tag={tags.find((tag) => tag.id === tagID)}
                            showAsLine
                          />
                        </S.Tag>
                      ))}
                    </S.TagGroup>
                  )}
                </S.ReferenceButton>
              );
            })}
          </div>
        ))}
      </S.ReferenceListContent>
      <S.ReferenceListGradient
        visible={references.length > 3 && !isScrolledToBottom}
      />
    </S.ReferenceListContainer>
  );
}
