// TODO FEED-12 FEED-16: Fix strict mode errors
// @ts-strict-ignore
import classNames from "classnames";
// Moment is no longer supported. Slack channel #p-remove-moment for details
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment, { type Moment } from "moment";
import React from "react";

import { Button } from "components/Common/Button";
import { ButtonGroupMultiSelect } from "components/Common/ButtonGroupMultiSelect";
import { ReadOnlyCheckbox as Checkbox } from "components/Common/Checkbox";
import { DatePickerDropdown } from "components/Common/DatePickerDropdown";
import { Select } from "components/Common/Select";
import {
  type NormalScheduleRuleRow,
  type SpecialScheduleRuleRow,
} from "components/Shared/Pages/Settings/SettingsSchedules/types";
import { AdaDateTime } from "services/AdaDateTime";
import colors from "stylesheets/utilities/colors.scss";

import "./style.scss";

const BLOCK_NAME = "SettingsScheduleRow";
const DAY_SELECTOR_OPTIONS = [
  { label: "Su", value: "Sunday", altText: "Sunday" },
  { label: "Mo", value: "Monday", altText: "Monday" },
  { label: "Tu", value: "Tuesday", altText: "Tuesday" },
  { label: "We", value: "Wednesday", altText: "Wednesday" },
  { label: "Th", value: "Thursday", altText: "Thursday" },
  { label: "Fr", value: "Friday", altText: "Friday" },
  { label: "Sa", value: "Saturday", altText: "Saturday" },
];
const LABEL_TIME_FORMAT = "h:mma";
const VALUE_TIME_FORMAT = "HH:mm";
const DEFAULT_START_TIME = "00:00";
const DEFAULT_END_TIME = "23:59";

function generateTimeRange(startTime: Moment, endTime: Moment, interval = 30) {
  const timeRanges = [];

  while (startTime.isBefore(endTime) || startTime.isSame(endTime)) {
    timeRanges.push({
      label: startTime.format(LABEL_TIME_FORMAT),
      value: startTime.format(VALUE_TIME_FORMAT),
    });
    startTime.add(interval, "minutes");
  }

  // Add final interval
  timeRanges.push({
    label: "12:00am",
    value: DEFAULT_END_TIME,
  });

  return timeRanges;
}

interface SettingsScheduleRowProps {
  rowIndex: number;
  row: NormalScheduleRuleRow | SpecialScheduleRuleRow;
  onDelete: (val: Record<string, unknown>) => void;
  onChange: (val: Record<string, unknown>) => void;
  hasExceptionDatePicker?: boolean;
}

export function SettingsScheduleRow({
  rowIndex,
  row,
  onDelete,
  onChange,
  hasExceptionDatePicker = false,
}: SettingsScheduleRowProps) {
  /**
   * Helper function to determine if a particular field is found to be invalid
   */
  function isFieldInvalid(field: string) {
    const rowInvalidFields = row.invalidFields;

    return (
      rowInvalidFields.findIndex(
        (invalidFields) => invalidFields.field === field,
      ) > -1
    );
  }

  function getFieldErrorMessage(field: string) {
    if (!isFieldInvalid(field)) {
      return "";
    }

    const rowInvalidFields = row.invalidFields;
    const invalidField = rowInvalidFields.find(
      (invalidFields) => invalidFields.field === field,
    );

    return invalidField && invalidField.errorMessage;
  }

  function handleRowUpdate(value: Record<string, unknown>) {
    onChange({ index: rowIndex, value });
  }

  const fromTimeSelectOptions = generateTimeRange(
    moment(DEFAULT_START_TIME, VALUE_TIME_FORMAT),
    moment(DEFAULT_END_TIME, VALUE_TIME_FORMAT),
  );

  // add 30 minutes to prevent overlapping time with the from-times
  const toTimeSelectOptions = generateTimeRange(
    moment(DEFAULT_START_TIME, VALUE_TIME_FORMAT),
    moment(DEFAULT_END_TIME, VALUE_TIME_FORMAT),
  );

  return (
    <div className={`${BLOCK_NAME}__row`}>
      {hasExceptionDatePicker ? (
        <div className={`${BLOCK_NAME}__date-selector`}>
          <DatePickerDropdown
            onChange={(val) => handleRowUpdate({ date: val })}
            selectedDate={row.date}
            minDate={AdaDateTime.format("", "LL")}
            disabled={false}
          />
        </div>
      ) : (
        <div className={`${BLOCK_NAME}__day-selector`}>
          <ButtonGroupMultiSelect
            isInvalid={isFieldInvalid("days")}
            customClassName={`${BLOCK_NAME}__day-selector-buttons`}
            options={DAY_SELECTOR_OPTIONS}
            values={(row as NormalScheduleRuleRow).days}
            invalidFields={[
              ...(row as NormalScheduleRuleRow).conflictingDays.days,
            ]}
            onChange={(val) => handleRowUpdate({ days: val })}
          />
          {Boolean(getFieldErrorMessage("days")) && (
            <p className={`${BLOCK_NAME}__row__error`}>
              {getFieldErrorMessage("days")}
            </p>
          )}
          {Boolean(
            (row as NormalScheduleRuleRow).conflictingDays.days.size,
          ) && (
            <p className={`${BLOCK_NAME}__row__error`}>
              {(row as NormalScheduleRuleRow).conflictingDays.errorMessage}
            </p>
          )}
        </div>
      )}

      <div>
        <div className={`${BLOCK_NAME}__time-selector-container`}>
          <Select
            isInvalid={isFieldInvalid("startEnd")}
            disabled={row.allDay}
            customClassName={classNames(
              `${BLOCK_NAME}__time-selector`,
              `${BLOCK_NAME}__time-selector--from`,
            )}
            value={row.allDay ? "—" : row.start}
            options={fromTimeSelectOptions}
            placeholder={row.allDay ? "—" : fromTimeSelectOptions[0].label}
            showIconBesideSelected
            onChange={(val) => handleRowUpdate({ start: val })}
          />
          <div
            className={classNames(`${BLOCK_NAME}__label`, {
              [`${BLOCK_NAME}__label__all-day`]: row.allDay,
            })}
          >
            to
          </div>
          <Select
            isInvalid={isFieldInvalid("startEnd")}
            disabled={row.allDay}
            customClassName={`${BLOCK_NAME}__time-selector ${BLOCK_NAME}__time-selector--to`}
            value={row.allDay ? "—" : row.end}
            options={toTimeSelectOptions}
            placeholder="—"
            onChange={(val) => handleRowUpdate({ end: val })}
          />
        </div>
        {Boolean(getFieldErrorMessage("startEnd")) && (
          <p className={`${BLOCK_NAME}__row__error`}>
            {getFieldErrorMessage("startEnd")}
          </p>
        )}
      </div>

      <div className={`${BLOCK_NAME}__all-day`}>
        <div className={`${BLOCK_NAME}__all-day__checkbox`}>
          <Checkbox
            checked={row.allDay}
            handleToggle={(val) => handleRowUpdate({ allDay: val })}
          />
        </div>
        <div className={`${BLOCK_NAME}__all-day__label`}>All Day</div>
      </div>

      <Button
        customClassName={`${BLOCK_NAME}__remove-row`}
        onClick={() => onDelete({ index: rowIndex })}
        icon="CircleRemove"
        fillColor={colors.colorUIBad}
        clear
      />
    </div>
  );
}
