// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import { type OktaAuth } from "@okta/okta-auth-js";
import { withOktaAuth } from "@okta/okta-react";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logoutUser as logoutUserAction, saveUserAction } from "actions";
import { updateOne } from "actions/resources";
import { Button } from "components/Common/Button";
import { InputPassword } from "components/Common/InputPassword";
import { PasswordGuidelines } from "components/Common/PasswordGuidelines";
import { Select } from "components/Common/Select";
import { SelectSearchDropdown } from "components/Common/SelectSearchDropdown";
import { VALID_PASSWORD_LENGTH } from "constants/passwordGuidelines";
import { type UserAttributes } from "resourceModels/User";
import { getResource } from "selectors/resources";
import { useClient } from "services/client";
import { NO_OP_FUNCTION } from "services/helpers";
import { TIME_ZONES_CANONICAL_ARR } from "services/timezones";
import {
  validatePasswordAlphaNum,
  validatePasswordSpecialChar,
} from "services/validations";

import { ModalProfileErrorEmails } from "./ModalProfileErrorEmails";
import { ModalProfileSecurity } from "./ModalProfileSecurity";
import { updatePassword as updatePasswordAction } from "./actions";

import "./style.scss";

const USER_ROLES = [
  {
    label: "Agent",
    value: "agent",
  },
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Owner",
    value: "owner",
  },
  {
    label: "Read Only",
    value: "read_only",
  },
];

interface Props {
  oktaAuth: OktaAuth;
}

function ModalProfileComponent({ oktaAuth }: Props) {
  const { client } = useClient();
  const user = useSelector((state) => getResource(state, "user"));
  const dispatch = useDispatch();

  const [showPasswordGuidelines, setShowPasswordGuidelines] = useState(false);
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [fullName, setFullName] = useState(user.name);
  const [newTimeZone, setNewTimeZone] = useState("");

  const timeZoneChanged = Boolean(newTimeZone) && newTimeZone !== user.timeZone;
  const isPasswordValid =
    password.length >= VALID_PASSWORD_LENGTH &&
    password !== oldPassword &&
    validatePasswordAlphaNum(password) &&
    validatePasswordSpecialChar(password);

  const updateUser = useCallback(
    (data: Partial<UserAttributes>) => {
      dispatch(updateOne("user", user.id, data));
    },
    [dispatch, user.id],
  );

  const handleSave = useCallback(() => {
    if (user.name !== fullName) {
      updateUser({ name: fullName });
    }

    if (Boolean(newTimeZone) && user.timeZone !== newTimeZone) {
      updateUser({ timeZone: newTimeZone });
    }

    dispatch(saveUserAction(user.id));
  }, [dispatch, fullName, newTimeZone, updateUser, user]);

  const handlePasswordSubmit = useCallback(async () => {
    if (password && oldPassword && isPasswordValid) {
      try {
        await dispatch(updatePasswordAction(oldPassword, password, user.id));
        setPassword("");
        setOldPassword("");
      } catch (e) {
        /* Do nothing */
      }
    }
  }, [dispatch, isPasswordValid, oldPassword, password, user.id]);

  const logoutUser = useCallback(() => {
    if (client.features.sso_dashboard) {
      oktaAuth.tokenManager.clear();
      dispatch(logoutUserAction(() => oktaAuth.signOut()));
    } else {
      dispatch(logoutUserAction());
    }
  }, [dispatch, client.features.sso_dashboard, oktaAuth]);

  if (!user) {
    return <span>Error: user not found</span>;
  }

  return (
    <div className="ModalProfile">
      <h5 className="Modal__title">Your Profile</h5>
      <div className="Modal__content">
        <section className="Modal__section ModalProfile__details">
          <h5 className="Modal__section__title">Edit Profile</h5>
          <div className="ModalProfile__details__user-info">
            <div className="ModalProfile__details__user-info__inputs">
              <div className="ModalProfile__details__user-info__inputs__input">
                <label
                  className="g-input__label ModalProfile__label"
                  htmlFor="ModalProfile__name"
                >
                  Full Name
                </label>
                <input
                  name="name"
                  id="ModalProfile__name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Jane Doe"
                  className={classNames("g-input", {
                    "g-input--invalid": user.invalidFields.includes("name"),
                    "ph-no-capture": true,
                  })}
                />
              </div>
              <div className="ModalProfile__details__user-info__role">
                <label
                  className="g-input__label ModalProfile__label"
                  htmlFor="ModalProfile__user-info__role"
                >
                  Role
                </label>
                <Select
                  options={USER_ROLES}
                  value={user.role}
                  onChange={NO_OP_FUNCTION}
                  placeholder="Role"
                  disabled
                />
              </div>
            </div>
          </div>
        </section>
        {!user.ssoLogin && (
          <>
            <section className="Modal__section ModalProfile__details">
              <h5 className="Modal__section__title">Change Password</h5>
              <section className="Modal__section__sub-section">
                <div className="Modal__section__inputs ModalProfile__details__passwords__inputs">
                  <div className="Modal__section__inputs ModalProfile__details__passwords__inputs__input">
                    <label
                      className="g-input__label ModalProfile__label"
                      htmlFor="ModalProfile__old-password"
                    >
                      Current Password
                    </label>
                    <InputPassword
                      value={oldPassword}
                      onPasswordChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => setOldPassword(e.target.value)}
                      customClassName="ModalProfile__details__passwords__inputs__input"
                      onKeyDown={handlePasswordSubmit}
                    />
                  </div>
                  <div className="Modal__section__inputs ModalProfile__details__passwords__inputs__input">
                    <label
                      className="g-input__label ModalProfile__label"
                      htmlFor="ModalProfile__new-password"
                    >
                      New Password
                    </label>
                    <InputPassword
                      value={password}
                      onPasswordChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => setPassword(e.target.value)}
                      customClassName="ModalProfile__details__passwords__inputs__input"
                      onKeyDown={handlePasswordSubmit}
                      onFocus={() => setShowPasswordGuidelines(true)}
                      inputId="new-password-button"
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                {showPasswordGuidelines && (
                  <PasswordGuidelines
                    customClassName="ModalProfile__details__passwords"
                    password={password}
                    oldPassword={oldPassword}
                  />
                )}
                <div className="ModalProfile__details__passwords__button-container">
                  <Button
                    onClick={handlePasswordSubmit}
                    text="Change Password"
                    title="Update Password"
                    customClassName="ModalProfile__details__passwords__inputs__button"
                    disabled={!password || !oldPassword || !isPasswordValid}
                  />
                </div>
              </section>
            </section>
            <section className="Modal__section">
              <ModalProfileSecurity />
            </section>
          </>
        )}
        <section className="Modal__section">
          <h5 className="Modal__section__title">Time Zone</h5>
          <section className="Modal__section__sub-section">
            <div className="ModalProfile__details__timezone">
              <SelectSearchDropdown
                options={TIME_ZONES_CANONICAL_ARR}
                value={newTimeZone || user.timeZone}
                onChange={setNewTimeZone}
                placeholder="Time Zone"
                placeholderIcon="Globe"
                minWidth={374}
              />
            </div>
          </section>
        </section>
        <ModalProfileErrorEmails user={user} />
      </div>
      <div className="Modal__bottom ModalProfile__bottom">
        <Button
          onClick={logoutUser}
          text="Logout"
          title="Logout"
          light
          icon="SignOut"
          customClassName="ModalProfile__bottom__logout"
        />
        <Button
          onClick={handleSave}
          text="Save"
          title="Save"
          icon="Cloud"
          customClassName="ModalProfile__details"
          disabled={Boolean(
            user.invalidFields.size ||
              (user.name === fullName && !user.isDirty && !timeZoneChanged),
          )}
        />
      </div>
    </div>
  );
}

export const ModalProfile = withOktaAuth(ModalProfileComponent);
