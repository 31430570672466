/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  type ExpressionState,
  type UnansweredQuestionsDto,
} from "reducers/expressions/types";

export const CREATE_EXPRESSION_REQUEST = "CREATE_EXPRESSION_REQUEST";
export interface CreateExpressionsAction {
  type: typeof CREATE_EXPRESSION_REQUEST;
}

export const CREATE_EXPRESSION_SUCCESS = "CREATE_EXPRESSION_SUCCESS";
export interface CreateExpressionSuccessAction {
  type: typeof CREATE_EXPRESSION_SUCCESS;
  response: {
    data: {
      expression: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
    };
  };
}

export const CREATE_EXPRESSION_FAILURE = "CREATE_EXPRESSION_FAILURE";
export interface CreateExpressionFailureAction {
  type: typeof CREATE_EXPRESSION_FAILURE;
}

export const PATCH_EXPRESSION_REQUEST = "PATCH_EXPRESSION_REQUEST";
export interface PatchExpressionRequestAction {
  type: typeof PATCH_EXPRESSION_REQUEST;
}

export const PATCH_EXPRESSION_SUCCESS = "PATCH_EXPRESSION_SUCCESS";
export interface PatchExpressionSuccessAction {
  type: typeof PATCH_EXPRESSION_SUCCESS;
  response: {
    data: {
      updatedExpressions: {
        id: string;
        body: string;
        bodySimple: string;
        preTranslationBody: string;
      }[];
    };
  };
  payload: {
    previousLanguage: string;
    language: string | null;
  };
}

export const PATCH_EXPRESSION_LANGUAGE_REQUEST =
  "PATCH_EXPRESSION_LANGUAGE_REQUEST";
export interface PatchExpressionLanguageRequestAction {
  type: typeof PATCH_EXPRESSION_LANGUAGE_REQUEST;
}

export const PATCH_EXPRESSION_LANGUAGE_SUCCESS =
  "PATCH_EXPRESSION_LANGUAGE_SUCCESS";
export interface PatchExpressionLanguageSuccessAction {
  type: typeof PATCH_EXPRESSION_LANGUAGE_SUCCESS;
  response: {
    data: {
      updatedExpressions: {
        id: string;
        language: string;
        body: string;
        bodySimple: string;
        preTranslationBody: string;
      }[];
    };
  };
  payload: {
    previousLanguage: string;
    language: string | null;
  };
}

export const PATCH_EXPRESSION_LANGUAGE_FAILURE =
  "PATCH_EXPRESSION_LANGUAGE_FAILURE";
export interface PatchExpressionLanguageFailureAction {
  type: typeof PATCH_EXPRESSION_LANGUAGE_FAILURE;
}

export const GET_EXPRESSIONS_REQUEST = "GET_EXPRESSIONS_REQUEST";
export interface GetExpressionsRequestAction extends ExpressionState {
  type: typeof GET_EXPRESSIONS_REQUEST;
}

interface TrainedExpressionSuccess {
  clear: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  response: {
    data: {
      expressions: any[];
      count: number;
      title: string | null | undefined;
      keywords: any[];
      more_available: any;
      last_sort: any[];
    };
  };
}

export const GET_EXPRESSIONS_SUCCESS = "GET_EXPRESSIONS_SUCCESS";
export interface GetExpressionsSuccessAction extends TrainedExpressionSuccess {
  type: typeof GET_EXPRESSIONS_SUCCESS;
  origin: string;
}

export const GET_TRAINED_EXPRESSIONS_SUCCESS =
  "GET_TRAINED_EXPRESSIONS_SUCCESS";
interface GetTrainedExpressionSuccessAction extends TrainedExpressionSuccess {
  type: typeof GET_TRAINED_EXPRESSIONS_SUCCESS;
}

export const GET_CONVERSATIONS_TOPIC_EXPRESSIONS_SUCCESS =
  "GET_CONVERSATIONS_TOPIC_EXPRESSIONS_SUCCESS";
export interface GetConversationsTopicExpressionsSuccessAction {
  type: typeof GET_CONVERSATIONS_TOPIC_EXPRESSIONS_SUCCESS;
  unansweredQuestions: UnansweredQuestionsDto[];
  clientId: string;
}

export const GET_EXPRESSIONS_FAILURE = "GET_EXPRESSIONS_FAILURE";
export interface GetExpressionsFailureAction {
  type: typeof GET_EXPRESSIONS_FAILURE;
}

export const GET_TOTAL_EXPRESSIONS_COUNT_SUCCESS =
  "GET_TOTAL_EXPRESSIONS_COUNT_SUCCESS";
export interface GetTotalExpressionCountSuccessAction {
  type: typeof GET_TOTAL_EXPRESSIONS_COUNT_SUCCESS;
  response: {
    data: {
      count: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
    };
  };
}

export const DELETE_EXPRESSION_SUCCESS = "DELETE_EXPRESSION_SUCCESS";
export interface DeleteExpressionSuccessAction {
  type: typeof DELETE_EXPRESSION_SUCCESS;
  response: {
    data: {
      expression: {
        _id: string;
      };
    };
  };
}

export const DELETE_UNTRAINED_EXPRESSIONS_SUCCESS =
  "DELETE_UNTRAINED_EXPRESSIONS_SUCCESS";
export interface DeleteUntrainedExpressionsSuccessAction {
  type: typeof DELETE_UNTRAINED_EXPRESSIONS_SUCCESS;
  ids: any;
}

export const TRAIN_EXPRESSIONS_SUCCESS = "TRAIN_EXPRESSIONS_SUCCESS";
export interface TrainExpressionsSuccessAction {
  type: typeof TRAIN_EXPRESSIONS_SUCCESS;
  ids: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
}

export const MOVE_ALL_EXPRESSIONS_SUCCESS = "MOVE_ALL_EXPRESSIONS_SUCCESS";
export interface MoveAllExpressionSuccessAction {
  type: typeof MOVE_ALL_EXPRESSIONS_SUCCESS;
  response: {
    data: {
      errors: any;
    };
  };
}

export const TRAIN_EXPRESSION_SUCCESS = "TRAIN_EXPRESSION_SUCCESS";
export interface TrainExpressionSuccessAction {
  type: typeof TRAIN_EXPRESSION_SUCCESS;
  response: {
    data: {
      expression: {
        _id: string;
      };
    };
  };
}

export const MOVE_EXPRESSION_SUCCESS = "MOVE_EXPRESSION_SUCCESS";
export interface MoveExpressionSuccessAction {
  type: typeof MOVE_EXPRESSION_SUCCESS;
  response: {
    data: {
      expression: {
        _id: string;
      };
    };
  };
}

const DELETE_EXPRESSIONS_SUCCESS = "DELETE_EXPRESSIONS_SUCCESS";
interface DeleteExpressionsSuccessAction {
  type: typeof DELETE_EXPRESSIONS_SUCCESS;
  response: {
    data: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  };
}

const MOVE_EXPRESSIONS_SUCCESS = "MOVE_EXPRESSIONS_SUCCESS";
export interface MoveExpressionsSuccessAction {
  type: typeof MOVE_EXPRESSIONS_SUCCESS;
  response: {
    data: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  };
}

const REPLACE_TRAINING = "REPLACE_TRAINING";
export interface ReplaceTrainingAction {
  type: typeof REPLACE_TRAINING;
  expressions: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
}

const SET_EXPRESSIONS_LIST = "SET_EXPRESSIONS_LIST";
export interface SetExpressionsListAction {
  type: typeof SET_EXPRESSIONS_LIST;
  payload: any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
}

const TOGGLE_VIEWING_TRANSLATIONS = "TOGGLE_VIEWING_TRANSLATIONS";
export interface ToggleViewingTranslationsAction {
  type: typeof TOGGLE_VIEWING_TRANSLATIONS;
}

export type ExpressionActions =
  | CreateExpressionsAction
  | CreateExpressionSuccessAction
  | CreateExpressionFailureAction
  | PatchExpressionRequestAction
  | PatchExpressionSuccessAction
  | PatchExpressionLanguageRequestAction
  | PatchExpressionLanguageSuccessAction
  | PatchExpressionLanguageFailureAction
  | GetExpressionsRequestAction
  | GetExpressionsSuccessAction
  | GetTrainedExpressionSuccessAction
  | GetConversationsTopicExpressionsSuccessAction
  | GetExpressionsFailureAction
  | GetTotalExpressionCountSuccessAction
  | DeleteExpressionSuccessAction
  | DeleteUntrainedExpressionsSuccessAction
  | TrainExpressionsSuccessAction
  | MoveAllExpressionSuccessAction
  | TrainExpressionSuccessAction
  | MoveExpressionSuccessAction
  | DeleteExpressionsSuccessAction
  | MoveExpressionsSuccessAction
  | ReplaceTrainingAction
  | SetExpressionsListAction
  | ToggleViewingTranslationsAction;
