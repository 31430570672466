import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "slices";
import { type DeepReadonly } from "types";

export type InferredVariableType = "text" | "number" | "boolean";
export type WebActionContentType = "json" | "xml";
export type WebActionMethodType = "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
export interface InferredVariable {
  name: string;
  type: InferredVariableType;
}

export type CaptureMode = "conversation" | "sms" | "dtmf";

export type WebAction = DeepReadonly<{
  _id: string;
  client_id: string;
  name: string;
  description: string;
  url: string;
  method: WebActionMethodType;
  request_body: string;
  content_type: WebActionContentType;
  headers: ReadonlyArray<{
    name: string;
    value: string;
  }>;
  inputs: Array<{
    name: string;
    type: InferredVariableType;
    capture_modes?: CaptureMode[];
  }>;
  outputs: ReadonlyArray<{
    name?: string;
    key: string;
  }>;
  is_disabled: boolean;
  can_run_standalone: boolean;
  rules: string | null | undefined;
  authentication_variables?: Readonly<Record<string, string>>;
  updated_at?: string;
  referencing_processes?: { handle: string; _id: string }[];
}>;

export type AuthVariableReferences = Record<string, string[]>;

export const webActionsApi = createApi({
  reducerPath: "webActions",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["WebAction"],
  endpoints: (build) => ({
    getWebActions: build.query({
      query: () => ({
        method: "GET",
        url: "/generative_actions/web_actions",
      }),
      transformResponse: (response: {
        web_actions: WebAction[];
        enabled_count: number;
      }) => ({
        webActions: response.web_actions,
        enabledCount: response.enabled_count,
      }),
      providesTags: ["WebAction"],
    }),

    getWebAction: build.query({
      query: (id: string) => ({
        method: "GET",
        url: `/generative_actions/web_actions/${id}`,
      }),
      transformResponse: (response: { web_action: WebAction }) =>
        response.web_action,
      providesTags: (result, error, id) => [{ type: "WebAction", id }],
    }),

    createWebAction: build.mutation<
      { web_action_id: string },
      Omit<WebAction, "_id">
    >({
      query: (body) => ({
        method: "POST",
        url: "/generative_actions/web_actions",
        data: body,
      }),
      invalidatesTags: ["WebAction"],
    }),

    updateWebAction: build.mutation({
      query: ({ _id: id, ...body }: WebAction) => ({
        method: "PUT",
        url: `/generative_actions/web_actions/${id}`,
        data: body,
      }),
      invalidatesTags: ["WebAction"],
    }),

    bulkUpdateWebActions: build.mutation({
      query: (data: {
        idsToUpdate: string[];
        updates: Partial<WebAction>;
      }) => ({
        method: "POST",
        url: `/generative_actions/web_actions/update_many`,
        data: {
          web_action_ids: data.idsToUpdate,
          updates: data.updates,
        },
      }),
      invalidatesTags: ["WebAction"],
    }),

    deleteWebAction: build.mutation({
      query: (id: string) => ({
        method: "DELETE",
        url: `/generative_actions/web_actions/${id}`,
      }),
      invalidatesTags: ["WebAction"],
    }),

    duplicateWebAction: build.mutation<{ web_action_id: string }, string>({
      query: (id) => ({
        method: "POST",
        url: `/generative_actions/web_actions/${id}/duplicate`,
      }),
      invalidatesTags: ["WebAction"],
    }),

    getWebActionAuthReferences: build.query({
      query: () => ({
        method: "GET",
        url: "/generative_actions/web_actions/auth/references",
      }),
      transformResponse: (response: { data: AuthVariableReferences }) =>
        response.data,
      providesTags: ["WebAction"],
    }),
  }),
});

export const {
  useGetWebActionsQuery,
  useUpdateWebActionMutation,
  useBulkUpdateWebActionsMutation,
  useCreateWebActionMutation,
  useGetWebActionQuery,
  useGetWebActionAuthReferencesQuery,
  useDeleteWebActionMutation,
  useDuplicateWebActionMutation,
} = webActionsApi;
