import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Loading } from "components/Common/Loading";
import RootContainer from "components/containers/RootContainer";
import {
  fetchClientAction,
  selectClient,
  selectClientNotFound,
} from "services/client";

export function SecurityContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const clientNotFound = useSelector(selectClientNotFound);

  const OKTA_AUTH_CLIENT = useMemo(() => {
    if (client?.features.sso_dashboard && client.ssoDashboardSettings) {
      return new OktaAuth({
        clientId: client.ssoDashboardSettings.client_id,
        issuer: `https://${client.ssoDashboardSettings.domain}/oauth2/default`,
        redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
        pkce: true,
      });
    }

    return null;
  }, [client]);

  useEffect(() => {
    if (!client) {
      dispatch(fetchClientAction());
    }
  }, [client, dispatch]);

  useEffect(() => {
    if (!client && clientNotFound) {
      // TODO replace this with public login page when live
      window.location.replace("https://www.ada.cx/404");
    }
  }, [client, clientNotFound]);

  if (!client) {
    return <Loading />;
  }

  if (OKTA_AUTH_CLIENT) {
    const restoreOriginalUri = async (
      oktaAuth: OktaAuth,
      originalUri: string,
    ) => {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
      <Security
        oktaAuth={OKTA_AUTH_CLIENT}
        restoreOriginalUri={restoreOriginalUri}
      >
        <RootContainer />
      </Security>
    );
  }

  return <RootContainer />;
}
