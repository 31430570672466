// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import classnames from "classnames";
import Immutable from "immutable";
import React, { type ReactNode } from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";
import SvgIcon from "components/Common/SvgIcon";
import { colorPrimitives, colorTokens } from "constants/css";
import { NO_OP_FUNCTION } from "services/helpers";

import * as S from "./styles";
import { type SelectOption, type SelectProps } from "./types";

import "./style.scss";

const BLOCK_NAME = "Select";

export { SelectProps } from "./types";

interface SelectState {
  activeIndex: number;
  hoverItemValue: number | string | null;
  subMenuState: Immutable.Map<string | number, unknown>;
  subActiveIndex: number | null;
  subHoverItemValue: number | string | null;
  isVisible: boolean;
  elementWidth: number | null;
  elementHeight: number | null;
  bottomAligned: boolean;
  rightAligned: boolean;
  onSubMenu: boolean;
}

const renderIcon = (
  Icon: string | (() => JSX.Element) | undefined,
  customClassName: string,
  iconFillColor: string,
) => {
  if (!Icon) return null;

  if (typeof Icon === "string") {
    return (
      <SvgIcon
        icon={Icon}
        customClassName={customClassName}
        fillColor={iconFillColor}
      />
    );
  }

  return (
    <div className={customClassName}>
      <Icon />
    </div>
  );
};

/** @deprecated Use Select from @adasupport/byron instead */
export class Select extends React.Component<SelectProps, SelectState> {
  RESULTS_MAX_WIDTH: number;
  RESULTS_MIN_WIDTH: number;
  RESULTS_MAX_HEIGHT: number;
  listElementRef: React.RefObject<HTMLUListElement>;
  selectElementRef: React.RefObject<HTMLDivElement>;
  constructor(props: SelectProps) {
    super(props);

    this.state = {
      activeIndex: 0,
      isVisible: false,
      elementWidth: null,
      elementHeight: null,
      bottomAligned: false,
      rightAligned: false,
      hoverItemValue: null,
      onSubMenu: false,
      subActiveIndex: null,
      subMenuState: Immutable.Map(
        props.options.map((option) => [option.value, false]),
      ),
      subHoverItemValue: null,
    };

    this.RESULTS_MAX_WIDTH = 280;
    this.RESULTS_MIN_WIDTH = 140;
    this.RESULTS_MAX_HEIGHT = props.resultsMaxHeight as number;

    this.listElementRef = React.createRef();
    this.selectElementRef = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSelectKeyDown = this.handleSelectKeyDown.bind(this);
    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
    this.handleMousedownOutside = this.handleMousedownOutside.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
  }

  componentDidMount() {
    const selectElementNode = this.selectElementRef.current;

    if (!selectElementNode) {
      return;
    }

    const { width, height } = selectElementNode.getBoundingClientRect();

    this.setState({
      elementWidth: width,
      elementHeight: height,
    });
  }

  componentDidUpdate(prevProps: SelectProps, prevState: SelectState) {
    const { hoverItemValue, activeIndex, subHoverItemValue, subActiveIndex } =
      this.state;

    const { customSelectedValue } = this.props;

    this.handleResultsToggle(prevState);

    if (prevState.hoverItemValue !== hoverItemValue) {
      this.removeHightlight("keyboard-main");
    }

    if (prevState.subHoverItemValue !== subHoverItemValue) {
      this.removeHightlight("keyboard-sub");
    }

    if (prevState.activeIndex !== activeIndex) {
      this.removeHightlight("mouse-main");
    }

    if (
      prevState.subActiveIndex &&
      prevState.subActiveIndex !== subActiveIndex
    ) {
      this.removeHightlight("mouse-sub");
    }

    if (prevProps.customSelectedValue !== customSelectedValue) {
      this.handleCloseWithCustomSubMenu();
    }
  }

  getSelectedOption() {
    const {
      options,
      value = null,
      subMenuValue = null,
      customParentValue,
    } = this.props;

    let selectedOption;

    if (subMenuValue) {
      // As far as I can tell this is the clearest way to express the logic as it's written here
      // Resolving this error would probably need a refactor of the logic as well (quite possibly
      // worth doing, but out of scope for now)

      for (const option of options) {
        // if subMenu exists, find the value inside subMenu. Else, find the value from option
        if (option.subMenu) {
          selectedOption = option.subMenu.find(
            (subOption) => subOption.value === subMenuValue,
          );
        }

        // if selectedOption has been found, return it, else continue the for loop.
        if (selectedOption) {
          return selectedOption;
        }
      }
    } else if (customParentValue) {
      options.forEach((option) => {
        if (customParentValue === option.value) {
          selectedOption = option.customSelectedElement;
        }
      });
    } else {
      selectedOption = options.find((option) => option.value === value);
    }

    return selectedOption;
  }

  getActiveElementNode(activeIndex: number) {
    if (!this.listElementRef.current) {
      return null;
    }

    return this.listElementRef.current.childNodes.item(
      activeIndex,
    ) as HTMLElement;
  }

  getSelectText() {
    const {
      showIconAsSelected = null,
      showIconBesideSelected = false,
      placeholder = null,
      placeholderIcon = null,
      placeholderIconColor = "",
      disabled = false,
    } = this.props;
    const selectedOption = this.getSelectedOption();
    let rendered: ReactNode;

    if (!selectedOption) {
      rendered = placeholder;

      if (placeholderIcon) {
        rendered = (
          <div className={`${BLOCK_NAME}__select__placeholder-rich`}>
            <div className={`${BLOCK_NAME}__select__placeholder-rich__icon`}>
              <SvgIcon
                icon={placeholderIcon}
                fillColor={placeholderIconColor}
              />
            </div>
            <div className={`${BLOCK_NAME}__select__placeholder-rich__label`}>
              {placeholder}
            </div>
          </div>
        );
      }

      return rendered;
    }

    let icon;

    if (selectedOption.icon) {
      icon = (
        <SvgIcon
          icon={selectedOption.icon as string}
          customClassName={`${BLOCK_NAME}__select__icon`}
          fillColor={
            disabled ? colorTokens.actionDisabled : colorPrimitives.black
          }
        />
      );
    }

    if (showIconAsSelected && selectedOption.icon) {
      rendered = icon;
    } else if (
      showIconBesideSelected &&
      selectedOption.icon &&
      selectedOption.label
    ) {
      rendered = (
        <>
          {icon}
          <span>{selectedOption.label}</span>
        </>
      );
    } else if (selectedOption.selected) {
      rendered = selectedOption.selected;
    } else if (selectedOption.label) {
      rendered = selectedOption.label;
    } else if (typeof selectedOption === "object") {
      rendered = selectedOption as ReactNode;
    } else if (typeof selectedOption === "string") {
      rendered = selectedOption;
    }

    return rendered;
  }

  getOptionsSubMenu(parent: SelectOption, subMenu: SelectOption[]) {
    const { subMenuValue = null } = this.props;

    const { subActiveIndex, subHoverItemValue } = this.state;

    const iconFillColor = colorPrimitives.black;

    return subMenu.map((subitem, i) => {
      const isActive = subitem.value === subMenuValue;
      const isSelected =
        i === subActiveIndex || subHoverItemValue === subitem.value;

      return (
        <React.Fragment key={subitem.label as string}>
          <li
            className={classnames(`${BLOCK_NAME}__list__item`, {
              [`${BLOCK_NAME}__list__item--active`]: isActive,
              [`${BLOCK_NAME}__list__item--focus`]: isSelected,
            })}
          >
            <button
              className={`${BLOCK_NAME}__list__item__button`}
              onClick={() => this.handleClose(parent, subitem)}
              onMouseEnter={() => this.handleMenuHover(subitem, "sub")}
              type="button"
            >
              <span className={`${BLOCK_NAME}__list__item__button__label`}>
                {subitem.label}
              </span>
              {isActive && (
                <SvgIcon
                  icon="Checkmark"
                  customClassName={`${BLOCK_NAME}__list__item__button__icon`}
                  fillColor={iconFillColor}
                />
              )}
            </button>
          </li>
        </React.Fragment>
      );
    });
  }

  getOptionsList() {
    const {
      value = null,
      toggleValues = Immutable.Map(),
      options,
      useRadioButtons = false,
      plainDropdown = false,
      onlyShowIconBesideSelected = false,
    } = this.props;

    const { activeIndex, hoverItemValue, subMenuState } = this.state;

    return options.map((option, i) => {
      const isActive =
        value === option.value ||
        (option.type === "toggle" && toggleValues.get(option.value));
      const isSelected = i === activeIndex || hoverItemValue === option.value;
      const isReservedOption = option.type === "sub-header";
      const isDivider = option.type === "divider";
      const iconFillColor = option.disabled
        ? colorTokens.actionDisabled
        : colorPrimitives.black;

      if (isReservedOption) {
        return (
          <li
            key={`${option.type}-${option.label}`}
            className={`${BLOCK_NAME}__list__item ${BLOCK_NAME}__list__item--sub-header`}
          >
            {!onlyShowIconBesideSelected && (
              <>
                {renderIcon(
                  option.icon,
                  `${BLOCK_NAME}__list__item__option-icon`,
                  iconFillColor,
                )}
              </>
            )}

            <span className={`${BLOCK_NAME}__list__item__label`}>
              {option.label}
            </span>
          </li>
        );
      }

      if (isDivider) {
        return (
          <li
            // Don't know what to do about this one
            // eslint-disable-next-line react/no-array-index-key
            key={`divider-${i}`}
            className={`${BLOCK_NAME}__list__item ${BLOCK_NAME}__list__item--divider`}
          />
        );
      }

      return (
        <React.Fragment key={option.value}>
          <li
            className={classnames(`${BLOCK_NAME}__list__item`, {
              [`${BLOCK_NAME}__list__item--active`]: isActive,
              [`${BLOCK_NAME}__list__item--focus`]: isSelected,
              [`${BLOCK_NAME}__list__item--no-sub-menu`]: !option.subMenu,
              [`${BLOCK_NAME}__list__item--disabled`]: option.disabled,
            })}
            onMouseEnter={() => this.handleMenuHover(option, "main")}
          >
            <button
              className={`${BLOCK_NAME}__list__item__button`}
              onMouseDown={() => {
                if (!option.subMenu && !option.customSubMenu) {
                  this.handleMouseDown(i);
                }
              }}
              onClick={() => {
                if (!option.subMenu && !option.customSubMenu) {
                  this.handleClose(option, null);
                }
              }}
              type="button"
            >
              {!onlyShowIconBesideSelected && (
                <>
                  {renderIcon(
                    option.icon,
                    `${BLOCK_NAME}__list__item__button__option-icon`,
                    iconFillColor,
                  )}
                </>
              )}
              <S.ListItemContainer>
                <span
                  className={`
                  ${BLOCK_NAME}__list__item__button__label
                  ${
                    option.type === "link"
                      ? `${BLOCK_NAME}__list__item__button__label__clear`
                      : ""
                  }
                `}
                >
                  {option.label}
                </span>
                <S.ListItemDescription>
                  {option.description}
                </S.ListItemDescription>
              </S.ListItemContainer>
              {option.tooltip && (
                <InfoTooltip
                  blurb={option.tooltip}
                  iconDefault="QuestionCircle"
                  iconClicked="QuestionCircleFilled"
                  absolute
                  inModal
                />
              )}
              {(option.subMenu || option.customSubMenu) && (
                <SvgIcon
                  icon="TriangleArrowRight"
                  customClassName={`${BLOCK_NAME}__list__item__button__icon`}
                  height={8}
                />
              )}
              {isActive &&
                !plainDropdown &&
                !option.subMenu &&
                !option.customSubMenu && (
                  <SvgIcon
                    icon={useRadioButtons ? "CircleRadio" : "Checkmark"}
                    customClassName={`${BLOCK_NAME}__list__item__button__icon`}
                    fillColor={iconFillColor}
                  />
                )}
            </button>
            {subMenuState.get(option.value) && !option.customSubMenu && (
              <div className={`${BLOCK_NAME}__list-sub-menu-wrapper`}>
                <ul
                  className={`${BLOCK_NAME}__list ${BLOCK_NAME}__list--sub-menu`}
                >
                  {this.getOptionsSubMenu(
                    option,
                    option.subMenu as SelectOption[],
                  )}
                </ul>
              </div>
            )}
            {option.customSubMenu && subMenuState.get(option.value) && (
              <div className={`${BLOCK_NAME}__list-sub-menu-wrapper`}>
                {option.customSubMenu}
              </div>
            )}
          </li>
        </React.Fragment>
      );
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIndexValue(index: number, options: any) {
    const newActiveElement = this.getActiveElementNode(index);

    if (!newActiveElement || !this.listElementRef.current) {
      return;
    }

    // if you hit the bottom, loop back up to the top
    if (index === 0) {
      this.listElementRef.current.scrollTop = 0;
    }

    // if you hit the top, loop back down to the bottom
    if (index === options.length - 1) {
      this.listElementRef.current.scrollTop =
        newActiveElement.offsetHeight * (options.length - 1);
    }

    // the offset of active element from top of viewport is greater than element 2*offsetHeight
    if (
      newActiveElement.offsetTop - this.listElementRef.current.scrollTop >
      2 * newActiveElement.offsetHeight
    ) {
      // scroll down
      // scroll the viewport the offset height of the element
      this.listElementRef.current.scrollTop += newActiveElement.offsetHeight;
      // scroll up
    } else if (
      newActiveElement.offsetTop - this.listElementRef.current.scrollTop <
      2 * newActiveElement.offsetHeight
    ) {
      this.listElementRef.current.scrollTop -= newActiveElement.offsetHeight;
    }
  }

  handleCloseWithCustomSubMenu() {
    this.setState({
      isVisible: false,
    });
  }

  removeHightlight(type: string) {
    const { options, value = null } = this.props;
    const { hoverItemValue, subMenuState } = this.state;
    let newHoverItemMap = Immutable.Map<string | number, unknown>();

    switch (type) {
      case "keyboard-main":
        subMenuState
          .keySeq()
          .toArray()
          .forEach((subMenu) => {
            if (subMenu === hoverItemValue) {
              newHoverItemMap = newHoverItemMap.set(hoverItemValue, true);
            } else {
              newHoverItemMap = newHoverItemMap.set(subMenu, false);
            }
          });
        this.setState({
          subMenuState: newHoverItemMap,
          activeIndex: options.findIndex((option) => option.value === value),
        });
        break;
      case "keyboard-sub":
        this.setState({
          subActiveIndex: null,
        });
        break;
      case "mouse-main":
        this.setState({
          hoverItemValue: null,
        });

        break;
      case "mouse-sub":
        this.setState({
          subHoverItemValue: null,
        });
        break;
      default:
    }
  }

  handleMenuHover(option: SelectOption, menu: string) {
    switch (menu) {
      case "main":
        if (option.subMenu || option.customSubMenu) {
          this.setState({
            hoverItemValue: option.value,
          });
        } else {
          this.setState({
            hoverItemValue: null,
          });
        }

        break;
      case "sub":
        this.setState({
          subHoverItemValue: option.value,
        });
        break;
      default:
    }
  }

  handleKeyDown(event: KeyboardEvent | React.KeyboardEvent) {
    const { activeIndex, onSubMenu, subActiveIndex } = this.state;
    const { options } = this.props;
    event.stopPropagation();

    if (
      event.keyCode === 38 ||
      event.keyCode === 37 ||
      event.keyCode === 40 ||
      event.keyCode === 13 ||
      event.keyCode === 32 ||
      event.keyCode === 39 ||
      event.keyCode === 27
    ) {
      event.preventDefault();
    }

    if (event.keyCode === 38 && activeIndex >= 0) {
      // Arrow up
      this.handleArrow("up");
    } else if (event.keyCode === 40 && activeIndex <= options.length - 1) {
      // Arrow down
      this.handleArrow("down");
    } else if (event.keyCode === 37) {
      // Arrow left
      this.handleArrow("left");
    } else if (event.keyCode === 13 || event.keyCode === 32) {
      const option = options[activeIndex];

      // Enter key and Space key
      if (option.subMenu) {
        if (onSubMenu) {
          this.handleClose(option, option.subMenu[subActiveIndex as number]);
        } else {
          this.handleArrow("right");
        }
      } else {
        this.handleClose(options[activeIndex]);
      }
    } else if (event.keyCode === 39 || event.keyCode === 32) {
      // Arrow right and Space key
      this.handleArrow("right");
    } else if (event.keyCode === 27) {
      // Esc key
      this.setState({
        isVisible: false,
      });
    }
  }

  handleArrow(dir: "up" | "down" | "left" | "right") {
    const { activeIndex, subActiveIndex, onSubMenu, subMenuState } = this.state;
    const { options } = this.props;
    let directionOffset: number;

    switch (dir) {
      case "up":
        directionOffset = -1;
        break;
      case "down":
        directionOffset = 1;
        break;
      case "left":
        if (onSubMenu) {
          this.setState({
            subMenuState: subMenuState.set(options[activeIndex].value, false),
            onSubMenu: false,
            subActiveIndex: null,
          });
        }

        break;
      case "right":
        if (options[activeIndex].subMenu) {
          this.setState({
            subMenuState: subMenuState.set(options[activeIndex].value, true),
            onSubMenu: true,
            subActiveIndex: 0,
            hoverItemValue: null,
          });
        }

        break;
      default:
        return;
    }

    if (onSubMenu) {
      this.setState(
        (prevState) => ({
          subActiveIndex: this.skipNonClickableElements(
            prevState.subActiveIndex as number,
            directionOffset,
          ),
        }),
        () =>
          this.setIndexValue(subActiveIndex as number, options[activeIndex]),
      );
    } else {
      this.setState(
        (prevState) => ({
          activeIndex: this.skipNonClickableElements(
            prevState.activeIndex,
            directionOffset,
          ),
        }),
        () => this.setIndexValue(activeIndex, options),
      );
    }
  }

  handleResultsToggle(prevState: SelectState) {
    const { isVisible } = this.state;
    const { value = null, options } = this.props;

    if (prevState.isVisible !== isVisible) {
      if (isVisible) {
        this.setState(
          {
            activeIndex:
              options.findIndex((option) => option.value === value) || 0,
          },
          () => {
            const { activeIndex } = this.state;
            const activeNode = this.getActiveElementNode(activeIndex);

            if (!activeNode) {
              return;
            }

            // center the selected element in the dropdown
            if (this.listElementRef.current) {
              this.listElementRef.current.scrollTop =
                activeNode.offsetTop - 2 * activeNode.offsetHeight;
            }
          },
        );

        document.addEventListener(
          "mousedown",
          this.handleMousedownOutside,
          true,
        );
        document.addEventListener("keydown", this.handleKeyDown, true);
      } else {
        document.removeEventListener(
          "mousedown",
          this.handleMousedownOutside,
          true,
        );
        document.removeEventListener("keydown", this.handleKeyDown, true);
      }
    }
  }

  handleToggleDropdown(event: React.KeyboardEvent | React.MouseEvent) {
    const { isVisible } = this.state;
    const { forceRightAligned = false, forceBottomAligned = false } =
      this.props;

    const eventTarget = event.target as HTMLDivElement;

    if (
      !this.listElementRef.current ||
      !this.listElementRef.current.contains(eventTarget)
    ) {
      const clientRect = eventTarget.getBoundingClientRect();
      /**
       * 320 represents the max-height of the drop
       * 280 is the max-width
       */
      this.clearSubMenuState();
      this.setState({
        isVisible: !isVisible,
        hoverItemValue: null,
        rightAligned:
          forceRightAligned ||
          window.innerWidth - clientRect.right <= this.RESULTS_MAX_WIDTH,
        bottomAligned:
          forceBottomAligned ||
          clientRect.top + this.RESULTS_MAX_HEIGHT >= window.innerHeight,
      });
    }
  }

  handleSelectKeyDown(event: React.KeyboardEvent) {
    if (
      event.keyCode === 40 || // arrow down
      event.keyCode === 38 || // arrow up
      event.keyCode === 13 || // enter
      event.keyCode === 32 // space
    ) {
      this.handleToggleDropdown(event);
    }
  }

  skipNonClickableElements(index: number, step: number): number {
    const { options } = this.props;
    const { activeIndex } = this.state;
    let tmp;

    if (index + step === options.length) {
      tmp = 0;
    } else if (index + step < 0) {
      tmp = options.length - 1;
    } else {
      tmp = index + step;
    }

    const newIndex = tmp;
    const option = options[newIndex];

    if (!option) {
      return activeIndex;
    }

    return option.type === "sub-header" || option.disabled
      ? this.skipNonClickableElements(newIndex, step)
      : newIndex;
  }

  handleMousedownOutside(event: MouseEvent) {
    if (
      this.selectElementRef.current &&
      !this.selectElementRef.current.contains(event.target as Node)
    ) {
      event.stopPropagation();
      this.setState({
        isVisible: false,
        hoverItemValue: null,
      });

      this.clearSubMenuState();
    }
  }

  /**
   * Close all submenu by clearing subMenuState
   */
  clearSubMenuState() {
    const { options } = this.props;
    this.setState({
      subMenuState: Immutable.Map(
        options.map((option) => [option.value, false]),
      ),
      subActiveIndex: null,
      subHoverItemValue: null,
      onSubMenu: false,
    });
  }

  handleMouseDown(index: number) {
    this.setState({
      activeIndex: index,
    });
  }

  handleClose(option: SelectOption, subMenu: SelectOption | null = null) {
    const {
      onToggleChange = NO_OP_FUNCTION,
      toggleValues = Immutable.Map(),
      onChange,
    } = this.props;

    /**
     * If the option being clicked is a toggle, don't close the dropdown
     */
    this.clearSubMenuState();
    this.setState(
      {
        isVisible: option.type === "toggle",
        hoverItemValue: null,
      },
      () => {
        // Need to use setTimeout to avoid "render clobbering" slowdown
        setTimeout(() => {
          if (option.type === "toggle") {
            onToggleChange(
              option.value,
              !toggleValues.get(option.value as string),
            );
          } else if (subMenu) {
            onChange([option.value, subMenu.value]);
          } else {
            onChange(option.value);
          }
        }, 0);

        // Re-focus button
        if (this.selectElementRef.current) {
          this.selectElementRef.current.focus();
        }
      },
    );
  }

  renderAllHiddenOptions() {
    const { options, subMenuValue = null } = this.props;

    if (subMenuValue) {
      return (
        <span
          className={`${BLOCK_NAME}__select__hidden-options__option`}
          key={`${subMenuValue}--hidden`}
        >
          {this.getSelectText()}
        </span>
      );
    }

    return options.map((option) => (
      <span
        className={`${BLOCK_NAME}__select__hidden-options__option`}
        key={`${option.value}--hidden`}
      >
        {option.label}
      </span>
    ));
  }

  renderList() {
    const { disabled = false, options, title } = this.props;

    const {
      isVisible,
      elementHeight,
      elementWidth,
      rightAligned,
      bottomAligned,
    } = this.state;

    if (isVisible && !disabled && options.length) {
      return (
        <div
          className={`${BLOCK_NAME}__list-wrapper`}
          style={{
            [bottomAligned ? "bottom" : "top"]: (elementHeight as number) + 8,
          }}
        >
          <ul
            className={classnames(`${BLOCK_NAME}__list`, {
              [`${BLOCK_NAME}__list--align-bottom`]: bottomAligned,
              [`${BLOCK_NAME}__list--align-right`]: rightAligned,
            })}
            onKeyDown={this.handleKeyDown}
            style={{
              maxWidth: this.RESULTS_MAX_WIDTH,
              minWidth:
                (elementWidth as number) > this.RESULTS_MIN_WIDTH
                  ? elementWidth
                  : this.RESULTS_MIN_WIDTH,
              maxHeight: this.RESULTS_MAX_HEIGHT,
            }}
            ref={this.listElementRef}
            role="listbox"
          >
            {title && <S.Title>{title}</S.Title>}
            {this.getOptionsList()}
          </ul>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      customClassName = "",
      isInvalid,
      isSmall = false,
      disabled = false,
      noBorder = false,
      options,
      customButton,
      showIconAsSelected = null,
      showIconBesideSelected = false,
      size = "medium",
      className = "",
      warningMessage,
      label,
    } = this.props;

    const { isVisible } = this.state;

    return (
      <>
        {label && <S.Label>{label}</S.Label>}
        <S.Select
          className={classnames(`${BLOCK_NAME}`, {
            [customClassName]: customClassName,
            [className]: className, // Supporting styled-component syntax: styled(Select)
            [`${BLOCK_NAME}--invalid`]: isInvalid,
            [`${BLOCK_NAME}--size-small`]: isSmall || size === "small",
            [`${BLOCK_NAME}--disabled`]: disabled || !options.length,
            [`${BLOCK_NAME}--size-x-small`]: size === "x-small",
          })}
          ref={this.selectElementRef}
          onMouseDown={this.handleToggleDropdown}
          onKeyDown={this.handleSelectKeyDown}
          tabIndex={disabled || !options.length ? -1 : 0}
          role="button"
        >
          {customButton ? (
            <SvgIcon
              customClassName={classnames(`${BLOCK_NAME}__custom-button`, {
                [`${BLOCK_NAME}__custom-button--active`]: isVisible,
              })}
              icon={customButton}
            />
          ) : (
            <S.SelectButton
              className={classnames(`${BLOCK_NAME}__select`, {
                [`${BLOCK_NAME}__select--no-border`]: noBorder,
              })}
            >
              {!showIconAsSelected && (
                <div className={`${BLOCK_NAME}__select__hidden-options`}>
                  {this.renderAllHiddenOptions()}
                </div>
              )}
              <div
                className={classnames(`${BLOCK_NAME}__select__text`, {
                  [`${BLOCK_NAME}__select__text--placeholder`]:
                    !this.getSelectedOption(),
                  [`${BLOCK_NAME}__select__text--relative`]: showIconAsSelected,
                  [`${BLOCK_NAME}__select__text--icon-visible`]:
                    showIconBesideSelected,
                })}
              >
                {this.getSelectText()}
              </div>
            </S.SelectButton>
          )}
          {this.renderList()}
          {!customButton && (
            <div className={`${BLOCK_NAME}__icon-container`}>
              <SvgIcon
                icon="ChevronDown"
                customClassName={`${BLOCK_NAME}__icon-container__icon`}
                fillColor={colorPrimitives.slate700}
              />
            </div>
          )}
        </S.Select>
        {isInvalid && warningMessage && (
          <div className={`${BLOCK_NAME}__warning-message`}>
            {warningMessage}
          </div>
        )}
      </>
    );
  }
}
