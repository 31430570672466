import { Select } from "@adasupport/byron";
import React from "react";

import { TitleWithSubtitle } from "components/Common/ByronSelectUtilities";
import { InputLabel } from "components/Common/InputLabel";
import {
  type CaptureMode,
  type InferredVariableType,
} from "services/webActions";

export function CaptureModeSelectorLabel() {
  return (
    <InputLabel
      label="Capture Modes"
      tooltip={{
        blurb:
          "How the AI Agent will gather the input. Only used in Voice conversations.",
        iconDefault: "Info",
      }}
    />
  );
}

interface Props {
  selectedCaptureModes: CaptureMode[];
  dataType: InferredVariableType;
  onChange: (value: CaptureMode[]) => void;
  isDisabled?: boolean;
}

export function CaptureModeSelector({
  selectedCaptureModes,
  dataType,
  onChange,
  isDisabled,
}: Props) {
  const options = [
    {
      label: (
        <TitleWithSubtitle
          title="Speech"
          description="The AI Agent will capture this input from the spoken conversation with the customer."
        />
      ),
      triggerLabel: "Speech",
      value: "conversation",
      isDisabled:
        selectedCaptureModes.includes("conversation") &&
        selectedCaptureModes.length === 1,
    },
    {
      label: (
        <TitleWithSubtitle
          title="SMS Capture"
          description="Use SMS sent to the customer's device to capture an input. This will only be used in Voice conversations."
        />
      ),
      triggerLabel: "SMS Capture",
      value: "sms",
      isDisabled:
        (selectedCaptureModes.includes("sms") &&
          selectedCaptureModes.length === 1) ||
        selectedCaptureModes.includes("dtmf"),
    },
    {
      label: (
        <TitleWithSubtitle
          title="DTMF Capture"
          description="Use dial tones captured from the keypad of the customer's device to capture an input. This will only be used in Voice conversations and is only available with the Number capture type."
        />
      ),
      triggerLabel: "DTMF Capture",
      value: "dtmf",
      isDisabled:
        (selectedCaptureModes.includes("dtmf") &&
          selectedCaptureModes.length === 1) ||
        selectedCaptureModes.includes("sms") ||
        dataType !== "number",
    },
  ] as const;

  return (
    <Select
      value={selectedCaptureModes}
      isMultiSelect
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
}
