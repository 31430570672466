import { type Key } from "rc-tree/lib/interface";
import { type LegacyDataNode } from "rc-tree-select/lib/interface";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchOneFolderAction } from "actions/folders";
import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { FolderSelect } from "components/Common/FolderSelect";
import { createTreeStructure } from "services/folders";

import * as S from "../styles";

interface CollectiveEditsFolderModalProps {
  onSave: (checkedItems: Key[], newParentFolderId: Key) => void;
  checkedItems: Key[];
}

export function CollectiveEditsFolderModal(
  props: CollectiveEditsFolderModalProps,
) {
  const dispatch = useDispatch();
  const foldersState = useSelector((state) => state.foldersState);

  const { onSave, checkedItems = [] } = props;

  const { rootFolders, rootFolderId, foldersById } = foldersState;

  const [newParentFolderId, setNewParentFolderId] = useState<Key>(rootFolderId);

  const treeSelectData = useMemo(() => {
    if (rootFolderId) {
      return createTreeStructure({
        rootFolders,
        rootFolderId,
        foldersById,
        isTreeSelect: true,
        checkedItems,
      });
    }

    return [];
  }, [rootFolders, rootFolderId, foldersById, checkedItems]);

  const handleMoveItems = () => {
    if (newParentFolderId) {
      onSave(checkedItems, newParentFolderId);
    }
  };

  const onExpandFolder = async (dataNode: LegacyDataNode) => {
    const { key } = dataNode;

    if (key) {
      await dispatch(fetchOneFolderAction(key));
    }
  };

  return (
    <S.Modal>
      <S.ModalHeader>
        <S.ModalTitle>Move {checkedItems.length} Item(s)</S.ModalTitle>
        <S.ModalDescription>
          Select the folder you would like these items moved to.
        </S.ModalDescription>
      </S.ModalHeader>
      <S.ModalBody>
        <FolderSelect
          initialValue={newParentFolderId}
          onSelectFolder={setNewParentFolderId}
          folders={treeSelectData}
          defaultOpen
          onExpandFolder={onExpandFolder}
          excludeIds={checkedItems}
        />
      </S.ModalBody>
      <S.ModalFooter>
        <Button
          title="Close"
          text="Cancel"
          light
          icon=""
          onClick={() => {
            dispatch(closeModalAction());
          }}
        />
        <Button
          onClick={handleMoveItems}
          disabled={!newParentFolderId}
          title="Move Items"
          text="Move"
        />
      </S.ModalFooter>
    </S.Modal>
  );
}
