import { createSelector } from "@reduxjs/toolkit";
import Immutable from "immutable";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { type ThunkAction } from "actions/types";
import {
  MESSAGING_MODALITY,
  type Modality,
  VOICE_MODALITY,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type State } from "reducers";
import { type MessageRecord } from "reducers/responses/messageRecords";
import { adaAPI } from "services/api";
import { type LanguageCode } from "services/language";
import { selectActiveRootMessagesIdKeyPathMap } from "services/responses/selectors/selectActiveRootMessagesIdKeyPathMap";

import { createMessageRecordsFromApiResponse } from "../api";
import {
  selectActiveModality,
  selectResponseMessagesLanguages,
} from "../selectors";

export const applyTranslationPreviewAction = (
  responseId: string,
  languageCode: LanguageCode,
  modality: Modality = MESSAGING_MODALITY,
) =>
  ({
    type: "APPLY_TRANSLATION_PREVIEW",
    responseId,
    languageCode,
    modality,
  } as const);

export const addPreviewLanguageToResponseMessagesAction = ({
  responseId,
  language,
  messages,
  modality,
}: {
  responseId: string;
  language: LanguageCode;
  messages: Immutable.List<MessageRecord>;
  modality: Modality;
}) =>
  ({
    type: "ADD_PREVIEW_LANGUAGE_TO_RESPONSE_MESSAGES",
    language,
    messages,
    responseId,
    modality,
  } as const);

interface TranslationPreviewResponse {
  messages: Record<string, unknown>[];
}

export const addLanguageWithPreview =
  (
    responseId: string,
    language: LanguageCode,
    modality: Modality,
  ): ThunkAction =>
  async (dispatch) => {
    dispatch({ type: "TRANSLATION_PREVIEW_REQUEST" });

    const response = await adaAPI.request<TranslationPreviewResponse>({
      method: "get",
      url: `/responses/${responseId}/translation_preview`,
      params: { language, modality },
    });

    const messages = createMessageRecordsFromApiResponse(
      response.data.messages,
    );

    dispatch(
      addPreviewLanguageToResponseMessagesAction({
        language,
        messages: Immutable.List(messages),
        responseId,
        modality,
      }),
    );

    // If there are no messages, go straight to edit mode, there's nothing to preview
    if (response.data.messages.length === 0) {
      dispatch(applyTranslationPreviewAction(responseId, language, modality));
    }

    dispatch({ type: "TRANSLATION_PREVIEW_COMPLETE" });
  };

const selectIsTranslationPreviewLoading = (state: State) =>
  state.responsesPage.isTranslationPreviewLoading;

export const selectIsTranslationPreviewMode = createSelector(
  [
    selectResponseMessagesLanguages,
    selectActiveModality,
    selectActiveRootMessagesIdKeyPathMap,
    selectIsTranslationPreviewLoading,
  ],
  (
      responseLanguages,
      activeModality,
      activeMessagesIdKeyPathMap,
      isTranslationPreviewLoading,
    ) =>
    (language: LanguageCode) => {
      if (
        !isTranslationPreviewLoading &&
        activeMessagesIdKeyPathMap.size === 0
      ) {
        return false;
      }

      return !responseLanguages.includes(language);
    },
);

export function useTranslationPreview() {
  const dispatch = useDispatch();

  return {
    isTranslationPreviewMode: useSelector(selectIsTranslationPreviewMode),
    addLanguageWithPreview: useCallback(
      (
        responseId: string,
        languageCode: LanguageCode,
        modality: Modality = VOICE_MODALITY,
      ) => {
        dispatch(addLanguageWithPreview(responseId, languageCode, modality));
      },
      [dispatch],
    ),
  };
}
