import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";

import { SUNSHINE_CONVERSATIONS_INTEGRATION_GUIDE_URL } from "../constants";

const IntroScreen = ({ openOauthWindow, closeModal }) => (
  <>
    <div className="Modal__content SunshineConversations__Intro">
      <h2>Before You Start</h2>
      <p>
        To ensure you are successful we recommend you have our setup guide and
        your Sunshine Conversations dashboard open during this process.
      </p>
      <a
        href={SUNSHINE_CONVERSATIONS_INTEGRATION_GUIDE_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="SunshineConversations__Intro__popout-link"
      >
        <SvgIcon icon="PopOut" />
        <div>Open Sunshine Conversations Guide</div>
      </a>
    </div>
    <div className="Modal__bottom SunshineConversations__Intro__bottom">
      <Button
        onClick={closeModal}
        text="Cancel"
        title="Cancel"
        customClassName="SunshineConversationsModal__cancel-button"
        light
      />
      <Button onClick={openOauthWindow} text="Connect" title="Connect" />
    </div>
  </>
);

IntroScreen.propTypes = {
  openOauthWindow: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default IntroScreen;
