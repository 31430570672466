import styled from "styled-components";

import { colorPrimitives, colorTokens, v } from "constants/css";

import { type analyticsColors } from "./constants";

const transitionDuration = "200ms";

const AnalyticsYearEndWrapUpReportModal = styled.div`
  width: 600px;
  height: 600px;
  border-radius: ${v.mainBorderRadius};
  overflow: hidden;
  position: relative;
`;

export const Root = AnalyticsYearEndWrapUpReportModal;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  height: 52px;
  background: linear-gradient(
    135deg,
    ${colorPrimitives.teal300} 5%,
    ${colorPrimitives.plum300} 95%
  );
  display: grid;
  place-items: center;
`;

export const ContentWrapper = styled.div<{ dark: boolean }>`
  display: flex;
  flex: 1;
  background: ${({ dark }) =>
    dark ? colorPrimitives.black : colorPrimitives.white};
  overflow: hidden;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: ${v.gUnitQuadruple} ${v.spaceIntermediate};
  position: relative;
  z-index: 3;
`;

export const ChatBubble = styled.span<{
  bg: "plum200" | "orange400" | "blue200";
  bottom?: boolean;
  right?: boolean;
}>`
  position: absolute;
  z-index: 2;
  border-radius: 64px;
  padding: 11px ${v.gUnitTriple};
  background-color: ${({ bg }) => colorPrimitives[bg]};
  font-weight: ${v.lightFontWeight};
  font-size: 32px;
  white-space: nowrap;
  transition: all ${transitionDuration} linear;
  top: ${({ bottom }) => (bottom ? "250px" : "80px")};
  left: ${({ right }) => (right ? "100%" : 0)};
  transform: translateX(${({ right }) => (right ? "-100%" : 0)});
`;

export const DataBackground = styled.div<{
  position: "left" | "center" | "right";
}>`
  color: ${colorTokens.textHeadings};
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  padding: ${v.gUnitQuadruple};
  width: 335px;
  border-radius: 30px;
  box-shadow: ${v.boxShadow2};
  transition: margin ${transitionDuration} linear;
  ${({ position }) =>
    position === "center"
      ? `
    margin-left: auto;
    margin-right: auto;
  `
      : `
margin-left: ${position === "right" ? "185px" : 0}; 
margin-right: 0;
`}
`;

export const DataContent = styled.div<{ bottom?: boolean; fadeIn: boolean }>`
  font-weight: ${v.heavyFontWeight};
  font-size: ${v.bigFontSize};
  line-height: ${v.xxLargeLineHeight};
  margin-top: ${({ bottom }) => (bottom ? "140px" : "50px")};
  flex: 1;
  visibility: ${({ fadeIn }) => (fadeIn ? "hidden" : "visible")};
  opacity: ${({ fadeIn }) => (fadeIn ? 0 : 1)};
  transition: opacity 500ms linear;
`;

export const MetricString = styled.span<{
  metricColor?: analyticsColors;
}>`
  white-space: pre-wrap;
  display: block;
  font: inherit;
  color: ${({ metricColor }) =>
    metricColor ? colorTokens[metricColor] : "inherit"};
`;

export const MetricNote = styled.div<{ fadeIn: boolean }>`
  font-size: ${v.xSmallFontSize};
  color: ${colorTokens.textMuted};
  visibility: ${({ fadeIn }) => (fadeIn ? "hidden" : "visible")};
  opacity: ${({ fadeIn }) => (fadeIn ? 0 : 1)};
  transition: opacity 500ms linear;
  a {
    color: inherit;
  }
`;

export const ReportLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${v.largeFontSize};
`;

export const ReportLink = styled.span`
  font-weight: ${v.xHeavyFontWeight};
  color: ${colorPrimitives.black};
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: underline;
`;

export const AdaLogo = styled.div`
  background: ${colorPrimitives.black};
  height: 132px;
  width: 132px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  bottom: -15px;
  right: 40px;
`;

export const AdaLogoImg = styled.img`
  height: 46px;
`;

export const LastPageContent = styled.div`
  background: rgba(255, 255, 255, 0.85);
  width: 400px;
  height: 420px;
  margin: auto;
  padding: 70px 40px;
  text-align: center;
  font-size: ${v.bigFontSize};
  font-weight: ${v.xHeavyFontWeight};
  border-radius: 30px;
`;

export const Spacer = styled.div`
  height: 35px;
`;

export const AdaNamedLogo = styled.img`
  height: 20px;
`;

export const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${v.gUnitDouble} ${v.spaceIntermediate};
  border-top: 1px solid ${colorTokens.borderDefault};
`;

export const DotsBackground = styled.div<{ step: number }>`
  position: absolute;
  top: 50%;
  left: 0%;
  transition: transform ${transitionDuration} linear;
  transform: translateY(-50%) translateX(${({ step }) => -600 * step}px);
  transform: 
  z-index: 2;
  display: flex;
`;

export const LineBackground = styled.div<{ left: number; rotate: number }>`
  position: absolute;
  top: 50%;
  left: ${({ left }) => left}%;
  transform: translateY(-50%) rotate(${({ rotate }) => rotate}deg);
  z-index: 2;
`;

export const CircleBackground = styled.div<{ left: number; top: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(${({ left, top }) => `${left}%, ${top}%`});
  z-index: 2;
  svg {
    fill: none;
  }
`;

export const ImageBackground = styled.img<{
  top: number;
  left: number;
  isVisible: boolean;
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: 1;
  opacity: ${({ isVisible }) => (isVisible ? 100 : 0)}%;
  transition: opacity ${transitionDuration} linear;
`;
