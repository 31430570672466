import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { setPage as goToPage } from "actions/router";

export function useCloseModal() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(closeModalAction());
    dispatch({ type: "RESET_SMART_SEARCH_INTEGRATIONS_MODAL" });
  }, [dispatch]);
}

export function useGoToKnowledgeHub() {
  const dispatch = useDispatch();
  const closeModal = useCloseModal();

  return useCallback(() => {
    dispatch(goToPage("/content/knowledge"));
    closeModal();
  }, [dispatch, closeModal]);
}

export function useCloseWebsiteImportModal() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(closeModalAction());
  }, [dispatch]);
}
