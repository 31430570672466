export enum CsatSurveyType {
  ANSWER_FLOW = "answer_flow",
  END_CHAT_CSAT = "end_chat",
  PROACTIVE_CSAT = "proactive",
  AGENT_LIVE_CHAT_CSAT = "live_chat",
}

export interface CsatResultAttributes {
  style: CSATSatisfactionStyle;
  score: number;
  originalScore?: string;
  surveyType: CsatSurveyType;
  feedback?: string[];
  isPositive?: boolean;
  resolved?: boolean;
  comment?: string;
}

export enum CSATSatisfactionStyle {
  THUMBS = "THUMBS",
  NUMERIC = "NUMERIC",
  NUMERIC_TEN = "NUMERIC_TEN",
  EMOJI = "EMOJI",
}
