// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import * as Immutable from "immutable";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchMany } from "actions/resources";
import { Loading } from "components/Common/Loading";
import { Language } from "resourceModels";
import { getClientTranslatedLanguages } from "selectors/languages";
import { getResources } from "selectors/resources";
import { useClientLegacy } from "services/clientLegacy";

import { MultilingualInputLanguageButton } from "./MultilingualInputLanguageButton";

import "./style.scss";

type LanguageCode = Language["code"];
type LanguageCodeHandler = (code: LanguageCode) => void;

interface Props {
  activeLanguage: string;
  invalidFields?: Immutable.List<string>;
  message: Immutable.Map<LanguageCode, string>;
  addLanguage: LanguageCodeHandler;
  deleteLanguage: LanguageCodeHandler;
  setActiveLanguage: LanguageCodeHandler;
}

export function MultilingualInputLanguages(props: Props) {
  const {
    activeLanguage,
    invalidFields = Immutable.List<string>(),
    message,
    addLanguage,
    deleteLanguage,
    setActiveLanguage,
  } = props;

  const languages = useSelector((state) => getResources(state, "language"));
  const { client } = useClientLegacy();
  const clientTranslatedLanguages = useSelector((state) =>
    getClientTranslatedLanguages(state),
  );

  const dispatch = useDispatch();

  // If languages are not already fetched, go fetch
  useEffect(() => {
    if (!languages) {
      dispatch(fetchMany("language"));
    }
  }, [dispatch, languages]);

  const onLanguageSelect = (language: Language) => {
    setActiveLanguage(language.code);
  };

  function messageLanguages(): Immutable.List<Language> {
    return message
      .keySeq()
      .map((code) => languages.find((language) => language.code === code))
      .filter((language): language is Language => language instanceof Language)
      .toList();
  }

  function enabledLanguages(): Immutable.List<Language> {
    let newLanguages = messageLanguages();
    const clientLanguage = languages.find((l) => l.code === client.language);

    if (clientLanguage && !newLanguages.includes(clientLanguage)) {
      newLanguages = newLanguages.unshift(clientLanguage);
    }

    // Put clientLanguage first
    return newLanguages.sort((language) =>
      language === clientLanguage ? -1 : 0,
    );
  }

  function availableLanguages(): Immutable.List<Language> {
    const newLanguages = messageLanguages();

    return clientTranslatedLanguages.filter(
      (language) => !newLanguages.includes(language),
    );
  }

  return !languages ? (
    <Loading />
  ) : (
    <div className="MultilingualInputLanguages">
      <MultilingualInputLanguageButton
        availableLanguages={availableLanguages()}
        onClick={onLanguageSelect}
        addLanguageAction={addLanguage}
        activeLanguage={activeLanguage}
        enabledLanguages={enabledLanguages()}
        invalidFields={invalidFields}
        deleteLanguage={deleteLanguage}
        languages={languages}
        client={client}
        invalidLanguageCodes={new Set()}
      />
    </div>
  );
}
