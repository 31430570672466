export const RULE_DSL = {
  ALL: "all",
  ANY: "any",
  IS: "equals",
  IS_NOT: "is_not",
  IS_SET: "is_set",
  IS_NOT_SET: "is_not_set",
  GREATER_THAN: "gt",
  LESS_THAN: "lt",
  STARTS_WITH: "starts_with",
  ENDS_WITH: "ends_with",
  CONTAINS: "contains",
  NOT_CONTAINS: "not_contains",
  LENGTH_IS: "len_is",
  LENGTH_GREATER_THAN: "len_gt",
  LENGTH_LESS_THAN: "len_lt",
} as const;

export const DSL_OPERATORS_TO_PLAIN_TEXT = {
  [RULE_DSL.ALL]: "all",
  [RULE_DSL.ANY]: "any",
  [RULE_DSL.IS]: "is",
  [RULE_DSL.IS_NOT]: "is not",
  [RULE_DSL.IS_SET]: "is set",
  [RULE_DSL.IS_NOT_SET]: "is not set",
  [RULE_DSL.GREATER_THAN]: "is greater than",
  [RULE_DSL.LESS_THAN]: "is less than",
  [RULE_DSL.STARTS_WITH]: "starts with",
  [RULE_DSL.ENDS_WITH]: "ends with",
  [RULE_DSL.CONTAINS]: "contains",
  [RULE_DSL.NOT_CONTAINS]: "does not contain",
  [RULE_DSL.LENGTH_IS]: "length is",
  [RULE_DSL.LENGTH_GREATER_THAN]: "length is greater than",
  [RULE_DSL.LENGTH_LESS_THAN]: "length is less than",
} as const;

export const RULE_DSL_WITH_VALUES: string[] = [
  RULE_DSL.IS,
  RULE_DSL.IS_NOT,
  RULE_DSL.STARTS_WITH,
  RULE_DSL.ENDS_WITH,
  RULE_DSL.CONTAINS,
  RULE_DSL.NOT_CONTAINS,
  RULE_DSL.LENGTH_IS,
  RULE_DSL.LENGTH_GREATER_THAN,
  RULE_DSL.LENGTH_LESS_THAN,
  RULE_DSL.GREATER_THAN,
  RULE_DSL.LESS_THAN,
];

export type VariableArgument = Readonly<{
  type: "variable";
  id: string;
}>;

export type RuleGroup =
  | {
      name: typeof RULE_DSL.ALL;
      args: ReadonlyArray<RuleDefinition>;
    }
  | {
      name: typeof RULE_DSL.ANY;
      args: ReadonlyArray<RuleDefinition>;
    };

export type RuleConditionUnary =
  | {
      name: typeof RULE_DSL.IS_SET;
      args: [VariableArgument];
    }
  | {
      name: typeof RULE_DSL.IS_NOT_SET;
      args: [VariableArgument];
    };

export type RuleCondition =
  | {
      name: typeof RULE_DSL.IS;
      args:
        | Readonly<[VariableArgument, string | number | boolean]>
        | Readonly<[VariableArgument, string, boolean]>; // Third argument is "Match Case" setting
    }
  | {
      name: typeof RULE_DSL.IS_NOT;
      args:
        | [VariableArgument, string | number | boolean]
        | [VariableArgument, string, boolean];
    }
  | {
      name: typeof RULE_DSL.LESS_THAN;
      args: [VariableArgument, number];
    }
  | {
      name: typeof RULE_DSL.GREATER_THAN;
      args: [VariableArgument, number];
    }
  | {
      name: typeof RULE_DSL.STARTS_WITH;
      args: [VariableArgument, string] | [VariableArgument, string, boolean];
    }
  | {
      name: typeof RULE_DSL.ENDS_WITH;
      args: [VariableArgument, string] | [VariableArgument, string, boolean];
    }
  | {
      name: typeof RULE_DSL.CONTAINS;
      args: [VariableArgument, string] | [VariableArgument, string, boolean];
    }
  | {
      name: typeof RULE_DSL.NOT_CONTAINS;
      args: [VariableArgument, string] | [VariableArgument, string, boolean];
    }
  | {
      name: typeof RULE_DSL.LENGTH_IS;
      args: [VariableArgument, number];
    }
  | {
      name: typeof RULE_DSL.LENGTH_GREATER_THAN;
      args: [VariableArgument, number];
    }
  | {
      name: typeof RULE_DSL.LENGTH_LESS_THAN;
      args: [VariableArgument, number];
    }
  | RuleConditionUnary;

export type RuleDefinition = RuleGroup | RuleCondition;
