// TODO FEED-12 FEED-16: Fix strict mode errors
// @ts-strict-ignore
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updatePlatform } from "actions";
import { InfoTooltip, Select } from "components/Common";
import { type Platform } from "reducers/platforms/types";
import { convertToMS } from "services/time";

import * as S from "./styles";

const PERSISTENCE_OPTIONS = [
  {
    label: "Never Forget",
    value: "normal",
  },
  {
    label: "Forget After Tab Close",
    value: "session",
  },
  {
    label: "Forget After Reload",
    value: "private",
  },
  {
    label: "Forget After...",
    value: "custom",
  },
];

const TIME_OPTIONS = [
  {
    label: "Minute(s)",
    value: "mins",
  },
  {
    label: "Hour(s)",
    value: "hours",
  },
  {
    label: "Day(s)",
    value: "days",
  },
  {
    label: "Week(s)",
    value: "weeks",
  },
];

export function PersistenceTab() {
  const CHAT_PLATFORM = "chat";
  const chatPlatform = useSelector((s) =>
    s.platforms.get(CHAT_PLATFORM),
  ) as Platform;

  const persistence = chatPlatform.getIn(["record", "persistence"]);
  const timeToExpiry = chatPlatform.getIn(["record", "timeToExpiry"]);

  const currentPersistence =
    persistence === "normal" && timeToExpiry ? "custom" : persistence;

  const [displayedPersistence, setDisplayedPersistence] =
    useState(currentPersistence);

  const dispatch = useDispatch();

  const onPersistenceChange = (newPersistenceValue: string) => {
    setDisplayedPersistence(newPersistenceValue);

    if (newPersistenceValue === "custom") {
      dispatch(updatePlatform(CHAT_PLATFORM, { persistence: "normal" }));
    } else {
      dispatch(
        updatePlatform(CHAT_PLATFORM, {
          persistence: newPersistenceValue,
          timeToExpiry: 0,
        }),
      );
    }
  };

  const updateTimeToExpiry = (
    expiryUnitOfTime: "mins" | "hours" | "days" | "weeks",
    expiryTime: number,
  ) => {
    const newTimeToExpiry = convertToMS(expiryUnitOfTime, expiryTime);

    dispatch(
      updatePlatform(CHAT_PLATFORM, {
        timeToExpiry: newTimeToExpiry,
        expiryUnitOfTime,
        expiryTime,
      }),
    );
  };

  const onTimeChange = (time: number) => {
    if (time >= 0 && time <= 9999) {
      updateTimeToExpiry(
        chatPlatform.getIn(["record", "expiryUnitOfTime"]),
        time,
      );
    } else {
      dispatch(
        updatePlatform(CHAT_PLATFORM, {
          expiryUnitOfTime: chatPlatform.getIn(["record", "expiryUnitOfTime"]),
          expiryTime: null,
          timeToExpiry: null,
        }),
      );
    }
  };

  const onTimeUnitChange = (
    unitOfTime: "mins" | "hours" | "days" | "weeks",
  ) => {
    updateTimeToExpiry(
      unitOfTime,
      chatPlatform.getIn(["record", "expiryTime"]),
    );
  };

  return (
    <div className="Persistence Modal__content">
      <S.PersistenceLabel>
        <label
          htmlFor="persistence"
          className="g-input__label g-input__label--no-description"
        >
          Customer Persistence
          <InfoTooltip
            customClassName="SettingsPlatformChatModal__tooltip"
            blurb="Select when to forget a customer's information."
            iconDefault="QuestionCircle"
            iconClicked="QuestionCircleFilled"
            inModal
          />
        </label>
      </S.PersistenceLabel>
      <S.PersistenceOptions>
        <Select
          onChange={onPersistenceChange}
          value={displayedPersistence}
          options={PERSISTENCE_OPTIONS}
          placeholder="Choose a Persistence Mode"
          customClassName="SettingsPlatformsChatModal__select"
        />
        {displayedPersistence === "custom" && (
          <>
            <S.ExpiryTimeInput
              type="number"
              placeholder="1"
              className="g-input"
              isInvalid={!chatPlatform.getIn(["record", "expiryTime"])}
              value={chatPlatform.getIn(["record", "expiryTime"])}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onTimeChange(parseInt(event.currentTarget.value, 10))
              }
            />
            <Select
              onChange={onTimeUnitChange}
              value={chatPlatform.getIn(["record", "expiryUnitOfTime"])}
              options={TIME_OPTIONS}
              placeholder="Unit"
              customClassName="SettingsPlatformsChatModal__select"
            />
          </>
        )}
      </S.PersistenceOptions>
    </div>
  );
}
