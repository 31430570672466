import { type User } from "resourceModels";
import { type Client } from "services/client";
import { trackException } from "services/errorTracker";

/*
This function tries to execute a Pendo function and reports
any errors as console warnings and to Sentry
*/

const tryPendoCommandWithFunction = (f: () => void) => {
  try {
    f();
  } catch (error) {
    trackException(error as Error);
  }
};

/**
 * This function binds User properties to Pendo user objects
 */
export function identifyPendoUserWithUserAndClientRecords(
  user: User,
  client: Client,
) {
  tryPendoCommandWithFunction(() => {
    if (window.pendo?.isReady()) {
      return;
    }

    window.pendo?.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        name: user.name || "",
        isAda: user.email?.includes("@ada.support"),
        created: user.created,
        role: user.role,
        botStatus: client.botStatus || "",
      },
      account: {
        id: client.handle,
        name: client.name || "",
      },
    });
  });
}

/**
 * An event tracking function for Pendo
 */
export function pendoTrackEvent(
  name: string,
  additionalInfo: Record<string, unknown>,
) {
  try {
    window.pendo?.track(name, additionalInfo);
  } catch (e) {
    // silence error if pendo is undefined - probably just the user's adblock blocking it
  }
}
