// TODO FEED-12 FEED-21: Fix strict mode errors
// @ts-strict-ignore
import { type AnyAction, type Middleware } from "@reduxjs/toolkit";
import axios, { type AxiosResponse } from "axios";

import {
  checkUserExpiry,
  resetSessionTimeout,
  setSessionTimeoutModal,
} from "actions/authentication";
import { setErrorPage } from "actions/error";
import { goToPage } from "actions/router";
import { type CallApiAction, type ThunkAction } from "actions/types";
import { adaAPI } from "services/api";

import { type Store } from "./types";

function statusCodeToStringCode(returnedStatusCode: number): string {
  if (!returnedStatusCode) {
    return "error";
  }

  return returnedStatusCode.toString()[0] === "2" ? "success" : "error";
}

const middleware: Middleware =
  (store: Store) => (next) => (action?: CallApiAction | Promise<void>) => {
    if (!action) {
      return null;
    }

    if (action instanceof Promise) {
      return null;
    }

    const { CALL_API } = action;

    // So the middleware doesn't get applied to every single action
    if (typeof CALL_API === "undefined") {
      return next(action as unknown as AnyAction);
    }

    const {
      method,
      endpoint,
      external,
      payload,
      types,
      args,
      params,
      dispatchCallbacks,
      cancelPrevious,
      responseType,
    } = CALL_API;
    const [
      requestType = null,
      successType = null,
      errorType = null,
      abortedType = null,
    ] = types || [];

    // Make a clone of payload to be used by request actions
    const payloadClone = { ...payload };

    // Generate REQUEST action-type
    if (requestType) {
      next({
        ...args,
        requestPayload: payloadClone,
        type: requestType,
      });
    }

    // Check password expiry status on each call
    store.dispatch(checkUserExpiry());

    // Cancel previous request of same type if specified
    if (cancelPrevious) {
      adaAPI.cancelLastRequest(requestType);
    }

    return adaAPI
      .request({
        method,
        url: endpoint,
        params,
        data: payload,
        requestType,
        responseType,
      })
      .then(
        (
          response,
        ):
          | { response: AxiosResponse<Record<string, unknown>> }
          | { type: typeof successType } => {
          store.dispatch(resetSessionTimeout());

          if (!successType) {
            return {
              response,
            };
          }

          return next({ response, ...args, type: successType });
        },
        (error) => {
          if (axios.isCancel(error)) {
            console.warn("Request aborted");

            return next({
              response: {
                status: 499,
                statusText: "CLIENT CLOSED REQUEST",
              },
              type: abortedType,
            });
          }

          const errorResponse = error.response;
          const includes401 =
            dispatchCallbacks &&
            dispatchCallbacks.some(
              (dispatchCallback) => dispatchCallback.fireOnStatus === 401,
            );

          // Handling the case when there's no internet connection (no response)
          if (!errorResponse) {
            if (!errorType) {
              return { response: { status: "error" } };
            }

            return next({
              response: {
                status: null,
              },
              ...args,
              type: errorType,
            });
          }

          // Error codes from other API requests (such as the one we use for testing HTTP blocks)
          // could return a code that would trigger a user to become unauthenticated. To combat
          // against this, we check for specific fields on the request to ensure we only perform
          // error authentication actions when they are legitimate.
          if (
            errorResponse.data &&
            errorResponse.data.type === "ada_not_authorized" &&
            errorResponse.data.source === "ada"
          ) {
            if (
              errorResponse.status === 401 &&
              !includes401 &&
              !external &&
              !(window.location.pathname === "/")
            ) {
              if (errorResponse.data.reason === "mfa_not_setup") {
                store.dispatch({
                  type: "SET_MODAL",
                  payload: {
                    isOpen: true,
                    view: "MODAL_ENFORCE_MFA",
                    modalProps: {},
                  },
                });
              } else {
                // Auto logout user if unauthorized status code
                store.dispatch({
                  type: "CLEAR_CREDENTIALS",
                });

                store.dispatch({
                  type: "UNAUTHENTICATE",
                });

                store.dispatch(goToPage("/"));
              }
            } else if (errorResponse.status === 403) {
              // Token invalid
              store.dispatch({
                type: "CLEAR_CREDENTIALS",
              });

              store.dispatch({
                type: "UNAUTHENTICATE",
              });
            } else if (
              errorResponse.status === 419 &&
              store.getState().session.isAuthenticated
            ) {
              // Session has timed out - if already unauthenticated, no need to set modal
              store.dispatch(setSessionTimeoutModal());
            } else if (errorResponse.status === 503) {
              store.dispatch(setErrorPage(true, errorResponse.status));
            }
          }

          if (errorType) {
            return next({
              response: errorResponse,
              ...args,
              type: errorType,
            });
          }

          return {
            response: errorResponse,
          };
        },
      )
      .then((newAction) => {
        // Dispatch other actions on completion
        if ("response" in newAction && dispatchCallbacks) {
          dispatchCallbacks.forEach((dispatchCallback) => {
            if (
              newAction.response?.status === dispatchCallback.fireOnStatus ||
              statusCodeToStringCode(newAction.response?.status) ===
                dispatchCallback.fireOnStatus
            ) {
              const newArgs = {
                ...newAction,
                ...(dispatchCallback.args ? dispatchCallback.args : {}),
              };
              store.dispatch(dispatchCallback.request(newArgs) as ThunkAction);
            }
          });
        }

        // TODO: Find a better solution; we sometimes need this promise to reject
        if (
          "type" in newAction &&
          newAction.type?.indexOf("VARIABLE_FAILURE") !== -1
        ) {
          return Promise.reject(newAction);
        }

        return undefined;
      });
  };

export default middleware;
