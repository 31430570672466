// @ts-strict-ignore
import Immutable from "immutable";

import { c } from "constants/css";
import { type FeatureFlag } from "services/clientLegacy";
import RecordClassCreator, {
  type SingleResourcePayload,
} from "services/record";
import { type TypedMap } from "types/global";

import { Features } from "./client-features";
import { getInvalidFields } from "./validation";

export * from "./validation";
export * from "./client-features";

export type FallbackUiLink = TypedMap<{
  label: string;
  url: string;
}>;

/** @deprecated Use Client */
export interface ClientAttributes {
  language: string;
  agentAvatar: TypedMap<{
    default: boolean;
    image: string;
  }>;
  avatar: TypedMap<{
    useLetter: boolean;
    letter: string;
    image: string;
  }>;
  autoTranslation?: boolean;
  bestGuesses?: unknown;
  botPersona: TypedMap<{
    personality: Immutable.List<string>;
    emojiUsage: boolean;
    allowedEmojis: string;
  }>;
  chatButton: TypedMap<{
    iconType: "default" | "custom";
    iconPath: string;
    size: 44 | 56 | 64;
    backgroundColor: string;
  }>;
  uiSettings: TypedMap<{
    chat: TypedMap<{
      theme: "light" | "dark" | "auto";
      style: "round" | "rectangular";
      headerColorEnabled: boolean;
      headerColor: string | null;
      advancedColorsEnabled: boolean;
      brandedCampaigns: "branded" | "unbranded";
      textOverAccentColor: string;
      headerTextColor: string;
      textSize: "small" | "default" | "large";
      botName: Immutable.Map<string, string>;
      botDescriptionEnabled: boolean;
      botDescription: Immutable.Map<string, string>;
      defaultSoundOn: boolean;
      emailTranscriptSetting: boolean;
      fallbackUiSettings: TypedMap<{
        default: boolean;
        icon: TypedMap<{
          default: boolean;
          image: string;
        }>;
        message: Immutable.Map<string, string>;
        links: Immutable.Map<string, Immutable.List<FallbackUiLink>>;
      }>;
    }>;
    embed: TypedMap<{
      style: "round" | "text";
      buttonText: Immutable.Map<string, string> | null;
    }>;
  }>;
  enforceMfa?: Immutable.Map<string, unknown>;
  expressionFilters: Immutable.List<unknown>;
  features: Immutable.Map<FeatureFlag, boolean>;
  handoff: Immutable.Map<string, unknown>;
  handle?: string | null;
  marketingCampaignsOrder: Immutable.List<string>;
  name?: string | null;
  negResponse?: boolean;
  numPredictiveSuggestions: number;
  onsched?: Immutable.Map<string, unknown>;
  posResponse?: boolean;
  redactions: Immutable.List<
    TypedMap<{
      active: boolean;
      key: string;
      regex: string;
    }>
  >;
  responses: Immutable.Map<string, unknown>;
  scheduledBlockSchedules?: Immutable.List<unknown>;
  schedules?: Immutable.List<unknown>;
  tint?: string;
  token?: string | null;
  privacySettings: Immutable.Map<string, unknown>;
  translatedLanguages?: Immutable.List<string>;
  ssoDashboardSettings?: Immutable.Map<string, unknown>;
  allowList: Immutable.List<string>;
  securitySettings: TypedMap<{
    chat: TypedMap<{
      iframeAllowList: Immutable.List<string>;
    }>;
    app: TypedMap<{
      showAllowList: boolean;
    }>;
  }>;
  currency: string;
  privateConfig: TypedMap<{
    goalLimit: number;
  }>;
}

/** @deprecated Use Client */
export class ClientLegacy extends RecordClassCreator<ClientAttributes>({
  type: "client",
  agentAvatar: Immutable.Map({
    default: true,
  }),
  avatar: Immutable.Map({
    useLetter: true,
    letter: "A",
  }),
  autoTranslation: false,
  bestGuesses: null,
  botPersona: Immutable.Map({
    personality: Immutable.List(["friendly"]),
    emojiUsage: false,
    allowedEmojis: "",
  }),
  chatButton: Immutable.Map({
    iconType: "default",
    size: 44,
    iconPath: "",
    backgroundColor: "",
  }),
  uiSettings: Immutable.Map({
    chat: Immutable.Map({
      theme: "light",
      style: "round",
      headerColorEnabled: false,
      headerColor: null,
      advancedColorsEnabled: false,
      brandedCampaigns: "unbranded",
      textOverAccentColor: null,
      headerTextColor: null,
      botName: Immutable.Map(),
      botDescriptionEnabled: false,
      botDescription: Immutable.Map(),
      defaultSoundOn: false,
      emailTranscriptSetting: true,
      fallbackUiSettings: Immutable.Map({
        default: false,
        icon: Immutable.Map({
          default: true,
          image: "",
        }),
        message: Immutable.Map(),
        links: Immutable.Map(),
      }),
    }),
    embed: Immutable.Map({
      style: "round",
      buttonText: Immutable.Map(),
    }),
  }),
  enforceMfa: Immutable.Map(),
  expressionFilters: Immutable.List(),
  features: Immutable.Map(),
  handoff: Immutable.Map(),
  handle: null,
  language: "en",
  marketingCampaignsOrder: Immutable.List(),
  name: null,
  negResponse: null,
  numPredictiveSuggestions: null,
  onsched: Immutable.Map(),
  posResponse: null,
  redactions: Immutable.List(),
  responses: Immutable.Map(),
  scheduledBlockSchedules: Immutable.List(),
  schedules: Immutable.List(),
  tint: c.colorUiPrimary,
  token: null,
  privacySettings: Immutable.Map(),
  translatedLanguages: Immutable.List(),
  ssoDashboardSettings: Immutable.Map(),
  allowList: Immutable.List([]),
  securitySettings: Immutable.Map({
    chat: Immutable.Map({
      iframeAllowList: Immutable.List([]),
    }),
    app: Immutable.Map({
      showAllowList: false,
    }),
  }),
  currency: "USD",
  // Ordinarily, all values in the private config will have values, since defaults are populated for any unconfigured
  // values when this is returned from `api`. However, the defaults here may be relevant (for example) for test data.
  privateConfig: Immutable.Map({ goalLimit: 5 }),
}) {
  onUpdate(): this {
    // Update document title when name changes
    // added timeout to ensure that the change has been resolved in the client object
    setTimeout(() => {
      document.title = this.name ? `${this.name} — Ada` : "Ada";
    }, 0);

    return this;
  }

  /**
   * Methods
   */

  hasFeature(featureName: Features): boolean {
    // TODO: DND-KIT remove this after dev!!
    if (featureName === Features.DND_V2) {
      return true;
    }

    return Boolean(this.features.get(featureName));
  }

  /**
   * Getters
   */

  get hasLiveAgentFeature(): boolean {
    return [
      Features.ADA_GLASS_NUANCE,
      Features.ADA_GLASS_SALESFORCE,
      Features.ADA_GLASS_ZENDESK,
    ].some((feature) => this.hasFeature(feature));
  }

  get isZendeskEnabled(): boolean {
    return Boolean(this.handoff.getIn(["integrations", "zendesk", "enabled"]));
  }

  get invalidFields() {
    return getInvalidFields(this);
  }

  static getFetchOneUrl(): string {
    return "/";
  }

  static resolveFetchOneResult(payload: {
    client: { _id: string };
  }): SingleResourcePayload {
    return {
      ...payload,
      data: {
        ...payload.client,
        _type: "client",
        _id: payload.client._id || "unknown",
      },
    };
  }
}
