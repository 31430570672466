import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchEventsAction } from "actions/events";
import { openModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { useReadOnly } from "components/Common/ReadOnlyWrapper";
import { SelectSearchDropdown } from "components/Common/SelectSearchDropdown";
import SvgIcon from "components/Common/SvgIcon";
import SvgImage from "components/Common/SvgImage";
import { colorTokens } from "constants/css";
import EmptyImageNoneCreated from "images/EmptyState.svg";
import EmptyImageNoResults from "images/SearchEmptyState.svg";

import * as S from "./styles";

const NoEvents = (
  <S.EmptyState>
    <SvgImage imageHash={EmptyImageNoneCreated} />
    <S.EmptyHeader>You haven’t created any events yet.</S.EmptyHeader>
    <S.EmptyText>
      Create one to start tracking customer interactions.
    </S.EmptyText>
    <S.EmptyLink target="_blank" href="https://docs.ada.cx/track-events">
      See what&apos;s possible&nbsp;
      <SvgIcon
        icon="ExternalLinkArrow"
        height={10}
        fillColor={colorTokens.actionPrimaryText}
      />
    </S.EmptyLink>
  </S.EmptyState>
);

const HasEventsButEmpty = (
  <S.EmptyState>
    <SvgImage imageHash={EmptyImageNoResults} />
    <S.EmptyHeader>No Events found</S.EmptyHeader>
    <S.EmptyText>Try clearing your search or a different keywords</S.EmptyText>
  </S.EmptyState>
);

interface Props {
  onChange: (eventKey: string) => void;
  selectedEventKey: string | null;
  isInvalid?: boolean;
}

export function BusinessEventSelector({
  onChange,
  selectedEventKey,
  isInvalid = false,
}: Props) {
  const { events } = useSelector((state) => state.businessEventState);
  const { isReadOnly } = useReadOnly();
  const dispatch = useDispatch();

  // ensure events are fetched
  useEffect(() => {
    dispatch(fetchEventsAction());
  }, [dispatch]);

  const createNewEvent = () =>
    dispatch(openModalAction("MODAL_CREATE_BUSINESS_EVENT", {}));
  const eventOptions = events
    .filter(({ archived }) => !archived)
    .map(({ name, eventKey }) => ({ label: name, value: eventKey }));

  const emptyState = eventOptions.length > 0 ? HasEventsButEmpty : NoEvents;

  return (
    <SelectSearchDropdown
      placeholder="Select or create an event"
      onChange={(eventKey: string) => onChange(eventKey)}
      options={eventOptions}
      value={selectedEventKey}
      isInvalid={isInvalid}
      minWidth={400}
      dropdownHeader={
        eventOptions.length > 0 && (
          <S.DropDownHeaderRoot>
            <S.CreateNewEvent>
              <Button
                clear
                light
                text="Create a new event"
                iconColor={colorTokens.actionPrimaryText}
                tint={colorTokens.actionPrimaryText}
                icon="Add"
                onClick={createNewEvent}
              />
            </S.CreateNewEvent>

            <S.SavedEvents>Saved Events</S.SavedEvents>
            <S.SeeAllEvents to="/analytics/events">View All</S.SeeAllEvents>
          </S.DropDownHeaderRoot>
        )
      }
      emptyState={emptyState}
      disabled={isReadOnly}
    />
  );
}
