// TODO FEED-12 FEED-21: Fix strict mode errors
// @ts-strict-ignore
import Immutable from "immutable";

import { createAlert } from "actions/alerts";
import { closeModalAction, openModalAction } from "actions/modal";
import { saveResponse, updateResponse } from "actions/responses";
import { type Dispatch, type ThunkAction } from "actions/types";
import { createUnsavedVariable } from "actions/variables";
import { BUILDER_AB_TESTS_VARIABLE_SCOPE } from "constants/variables";
import {
  saveBuilderABTestAction,
  updateBuilderABTestsAction,
} from "features/ABTesting/actionsUsedInResponseActions";
import {
  generateABTestResponseMessages,
  getAllVariantMessages,
  getResponseABTest,
} from "features/ABTesting/services";
import { type BuilderABTest } from "features/ABTesting/types";
import { adaApiRequest } from "services/api";
import { selectClient } from "services/client";
import { mongoObjectId } from "services/objectid";
import { flattenVariables } from "services/records/variables";
import { type VariableRecord } from "services/variables";

export function fetchBuilderABTestsAction(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const { loading, loaded } = state.builderABTestsState;
    const client = selectClient(state);

    if (loading || loaded || !client) {
      return;
    }

    if (!client.features.afm_ab_testing) {
      dispatch({ type: "SET_EMPTY_BUILDER_AB_TESTS" });

      return;
    }

    dispatch({ type: "FETCH_BUILDER_AB_TESTS_REQUEST" });

    try {
      const response = await dispatch(
        adaApiRequest({ method: "GET", url: "/builder_ab_tests" }),
      );
      dispatch(updateBuilderABTestsAction(response.data.builder_ab_tests));
    } catch (error) {
      dispatch({ type: "FETCH_BUILDER_AB_TESTS_FAILURE" });
    }
  };
}

export function deleteBuilderABTestAction(testId: string): ThunkAction {
  return async (dispatch) => {
    try {
      const response = await dispatch(
        adaApiRequest({
          method: "DELETE",
          url: `/builder_ab_tests/${testId}`,
        }),
      );
      dispatch(updateBuilderABTestsAction(response.data.builder_ab_tests));

      return response.data;
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to delete A/B Test.",
          alertType: "error",
        }),
      );

      throw error;
    }
  };
}

// Updates a test in Redux without saving it
export function updateBuilderABTestAction(
  testId: string,
  updatedAttributes: Partial<BuilderABTest>,
): ThunkAction {
  return (dispatch, getState) => {
    const oldTest = getState().builderABTestsState.builderABTests.find(
      (test) => test.id === testId,
    );
    dispatch({
      type: "UPDATE_BUILDER_AB_TEST",
      testId,
      updatedTest: {
        ...oldTest,
        ...updatedAttributes,
      },
    });
  };
}

function findABTestVariable(
  testId: string,
  variables: Immutable.Map<string, unknown>,
) {
  return flattenVariables(variables).find(
    (variable: VariableRecord) =>
      variable.scope === BUILDER_AB_TESTS_VARIABLE_SCOPE &&
      variable.name === `builder-ab-test-${testId}`,
  );
}

function generateABTestVariableAction(
  testId: string,
  responseId: string,
): ThunkAction<VariableRecord> {
  return (dispatch) => {
    const newVariable = {
      name: `builder-ab-test-${testId}`,
      scope: BUILDER_AB_TESTS_VARIABLE_SCOPE,
      type: "long",
      responseId,
      originId: responseId,
      id: mongoObjectId(),
      responseReferences: [responseId],
    };

    return dispatch(
      createUnsavedVariable({
        variable: newVariable,
        responseId,
      }),
    );
  };
}

export function convertToABTestResponseAction(
  responseId: string,
  testId: string,
): ThunkAction {
  return async (dispatch, getState) => {
    const response = getState().responsesLoaded.get(responseId);

    if (!response) {
      throw new Error(
        `[A/B_test_invalid_state]: Failed to convert response with id ${responseId} to A/B test response: response not found`,
      );
    }

    let abTestVariable = findABTestVariable(testId, getState().variables);

    if (!abTestVariable) {
      abTestVariable = dispatch(
        generateABTestVariableAction(testId, response.id as unknown as string),
      );
    }

    let newMessages = response.messages;
    const languages = response.messages.keySeq().toArray();

    languages.forEach((language) => {
      const languageMessages = generateABTestResponseMessages(
        testId,
        abTestVariable.id,
        [1, 1],
        Immutable.List([
          response.messages.get(language),
          response.messages.get(language),
        ]),
      );

      newMessages = newMessages.merge({ [language]: languageMessages });
    });

    dispatch(
      updateResponse(response.id, {
        messages: newMessages,
      }),
    );

    dispatch(
      saveResponse(
        response.merge({
          messages: newMessages,
        }),
      ),
    );
  };
}

export function addVariantAction(
  responseId: string,
  indexOfVariantToCopy: number | null = null,
): ThunkAction {
  return async (dispatch, getState) => {
    const response = getState().responsesLoaded.get(responseId);
    const responseABTest = getResponseABTest(
      responseId,
      getState().builderABTestsState.builderABTests,
    );

    if (!response || !responseABTest) {
      const errorMessage = !response
        ? "not found"
        : "has no corresponding A/B test";
      throw new Error(
        `Failed to add variant to A/B test: response with id ${responseId} ${errorMessage}`,
      );
    }

    const testId = responseABTest.id;
    const abTestVariable = findABTestVariable(testId, getState().variables);

    let newMessages = response.messages;
    const languages = response.messages.keySeq().toArray();
    const updatedVariantWeights = [...responseABTest.variantWeights, 1];

    languages.forEach((language) => {
      const languageVariantMessages = getAllVariantMessages(
        response.messages.get(language),
      );
      // Boolean(indexOfVariantToCopy) is incorrect since we want index 0 to still be true
      const newVariantMessages =
        indexOfVariantToCopy !== null
          ? languageVariantMessages.get(indexOfVariantToCopy)
          : Immutable.List();

      const languageMessages = generateABTestResponseMessages(
        testId,
        abTestVariable.id,
        updatedVariantWeights,
        // The appended empty list represents the messages for the newly added variant
        Immutable.List(languageVariantMessages.push(newVariantMessages)),
      );

      newMessages = newMessages.merge({ [language]: languageMessages });
    });

    dispatch(
      updateResponse(response.id, {
        messages: newMessages,
      }),
    );

    // We want to do this _after_ the response has already been updated so that the new variant
    // messages are ready when we create the new tab
    const newVariantName = `Variant ${responseABTest.variantNames.length}`;
    const updatedVariantNames = [
      ...responseABTest.variantNames,
      newVariantName,
    ];
    dispatch(
      updateBuilderABTestAction(responseABTest.id, {
        variantNames: updatedVariantNames,
        variantWeights: updatedVariantWeights,
      }),
    );
  };
}

// Returns a copy of an array identical to the original except with the element at `index` removed
function removeArrayElement<T>(arr: T[], indexToRemove: number) {
  return arr.filter((val, i) => i !== indexToRemove);
}

export function deleteVariantAction(
  responseId: string,
  deletedVariantIndex: number,
): ThunkAction {
  return async (dispatch, getState) => {
    const response = getState().responsesLoaded.get(responseId);
    const responseABTest = getResponseABTest(
      responseId,
      getState().builderABTestsState.builderABTests,
    );

    if (!response || !responseABTest) {
      const errorMessage = !response
        ? "not found"
        : "has no corresponding A/B test";
      throw new Error(
        `Failed to remove variant from A/B test: response with id ${responseId} ${errorMessage}`,
      );
    }

    const updatedVariantWeights = removeArrayElement(
      responseABTest.variantWeights,
      deletedVariantIndex,
    );

    dispatch(
      updateBuilderABTestAction(responseABTest.id, {
        variantNames: removeArrayElement(
          responseABTest.variantNames,
          deletedVariantIndex,
        ),
        variantWeights: updatedVariantWeights,
      }),
    );

    const testId = responseABTest.id;
    const abTestVariable = findABTestVariable(testId, getState().variables);

    let newMessages = response.messages;
    const languages = response.messages.keySeq().toArray();

    languages.forEach((language) => {
      const languageVariantMessages = getAllVariantMessages(
        response.messages.get(language),
      );
      const languageMessages = generateABTestResponseMessages(
        testId,
        abTestVariable.id,
        updatedVariantWeights,
        Immutable.List(languageVariantMessages.delete(deletedVariantIndex)),
      );

      newMessages = newMessages.merge({ [language]: languageMessages });
    });

    dispatch(
      updateResponse(response.id, {
        messages: newMessages,
      }),
    );
  };
}

export function completeABTestAction(
  testId: string,
  winningVariantIndex: number,
): ThunkAction {
  return (dispatch, getState) => {
    const abTest = getState().builderABTestsState.builderABTests.find(
      (test) => test.id === testId,
    );

    if (!abTest) {
      throw new Error(
        `Failed to complete A/B test: test with id ${testId} not found`,
      );
    }

    const response = getState().responsesLoaded.get(abTest.responseId);

    if (!response) {
      throw new Error(
        `Failed to complete A/B test: response with id ${abTest.responseId} (referenced in test with id ${testId}) not found`,
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newMessages = response.messages.map((languageMessages: any) =>
      languageMessages.getIn([
        1,
        "statements",
        winningVariantIndex,
        "messages",
      ]),
    );

    dispatch(
      saveBuilderABTestAction({
        ...abTest,
        status: "complete",
      }),
    );

    dispatch(
      updateResponse(response.id, {
        messages: newMessages,
      }),
    );

    dispatch(
      saveResponse(
        response.merge({
          messages: newMessages,
        }),
      ),
    );
  };
}

export function undoABTestChangesAction(testId: string): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: "UNDO_BUILDER_AB_TEST",
      testId,
    });
  };
}

export function updateWeightsAction(
  testId: string,
  responseId: string,
  weights: number[],
): ThunkAction {
  return async (dispatch, getState) => {
    const response = getState().responsesLoaded.get(responseId);

    if (!response) {
      const errorMessage = "not found";
      throw new Error(
        `Failed to update A/B test weights: response with id ${responseId} ${errorMessage}`,
      );
    }

    const abTestVariable = findABTestVariable(testId, getState().variables);

    let newMessages = response.messages;
    const languages = response.messages.keySeq().toArray();

    languages.forEach((language) => {
      const languageVariantMessages = getAllVariantMessages(
        response.messages.get(language),
      );
      const languageMessages = generateABTestResponseMessages(
        testId,
        abTestVariable.id,
        weights,
        languageVariantMessages,
      );

      newMessages = newMessages.merge({ [language]: languageMessages });
    });

    dispatch(
      updateResponse(response.id, {
        messages: newMessages,
      }),
    );

    dispatch(
      updateBuilderABTestAction(testId, {
        variantWeights: weights,
      }),
    );
  };
}

export function confirmStartTesting(
  hasUnsavedChanges: boolean,
  abTest: BuilderABTest,
) {
  return (dispatch: Dispatch) => {
    if (hasUnsavedChanges) {
      return dispatch(
        openModalAction("MODAL_WARNING", {
          title: "Unsaved Changes",
          message:
            "You have unsaved changes in this Answer, you must save or discard your changes before turning on this test",
          actions: [
            {
              title: "Ok",
              onClick() {
                dispatch(closeModalAction());
              },
            },
          ],
        }),
      );
    }

    return dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Confirm Test activation",
        message:
          "Making this Test active will send variants to your customers and start collecting data.",
        actions: [
          {
            title: "Start testing",
            buttonTint: "primary",
            async onClick() {
              await dispatch(
                saveBuilderABTestAction({ ...abTest, status: "active" }),
              );
              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick() {
              dispatch(closeModalAction());
            },
          },
        ],
      }),
    );
  };
}

export function confirmStopTesting(abTest: BuilderABTest) {
  return (dispatch: Dispatch) =>
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Stop this Test?",
        message:
          "Stopping a Test will allow you to add or delete variants, but will also mean any performance data gathered so far will not be meaningful.",
        actions: [
          {
            title: "Stop this A/B Test",
            buttonTint: "primary",
            async onClick() {
              await dispatch(
                saveBuilderABTestAction({ ...abTest, status: "draft" }),
              );
              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick() {
              dispatch(closeModalAction());
            },
          },
        ],
      }),
    );
}
