import Immutable from "immutable";

import {
  DELETABLE_VARIABLE_SCOPES,
  GLOBAL_VARIABLE_SCOPE,
  LOCAL_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
} from "constants/variables";
import { flattenVariables, getVariableById } from "services/records/variables";
import { VariableRecord } from "services/variables";

import {
  ReferenceCounts,
  addResponseId,
  createVariableRecord,
  deleteResponseId,
  isActive,
  isSaved,
  updateFromServer,
} from "./helpers";

const initialState = Immutable.Map({
  global: Immutable.List(),
  local: Immutable.Map(),
  oauth: Immutable.List(),
  meta: Immutable.List(),
  internal: Immutable.List(),
  headers: Immutable.List(),
  sensitive: Immutable.List(),
  builder_ab_tests: Immutable.List(),
});

let lastSavedState;

/**
 * Changes the scope of oldVar to that of newVar
 * @param {Immutable.Record} state - The current variables state
 * @param {Object} options
 * @param {Immutable.Record} options.variable - The initial variable
 * @param {Immutable.Record} options.updatedVar - The changed variable
 * that already exists in the new scope
 * @param {string} options.responseId
 * @returns {Immutable.Map} state
 */
const changeVariableScope = (
  state,
  { variable: oldVar, updatedVar: newVar, responseId },
) => {
  // nothing to do here
  if (oldVar.scope === newVar.scope) {
    return state;
  }

  let updateMethodName;
  let updatePath;
  let updatedVar = newVar;
  let newState = state;
  const updatedVarIsSaved = isSaved(updatedVar);

  // set the appropriate properties on the updated variable
  if (oldVar.scope === LOCAL_VARIABLE_SCOPE) {
    updatedVar = updatedVar.merge({
      responseId: null,
      originId: oldVar.responseId,
    });
  } else {
    updatedVar = updatedVar.merge({
      responseId,
      originId: responseId,
      responseReferences: Immutable.List([responseId]),
      responseReferenceCounts: Immutable.Map({
        [responseId]: new ReferenceCounts({
          unsaved: Number(!updatedVarIsSaved),
          saved: Number(updatedVarIsSaved),
        }),
      }),
    });
  }

  updatedVar = updatedVar.set("modified", true);

  // filter out the old variable from its previous location
  updateMethodName = "update";
  updatePath = oldVar.scope;

  if (
    oldVar.scope === LOCAL_VARIABLE_SCOPE &&
    newState.get(LOCAL_VARIABLE_SCOPE).has(oldVar.responseId)
  ) {
    updateMethodName = "updateIn";
    updatePath = [LOCAL_VARIABLE_SCOPE, oldVar.responseId];
  }

  newState = newState[updateMethodName](updatePath, (variables) =>
    variables.filter((variable) => variable.id !== oldVar.id),
  );

  if (updatedVar.scope === LOCAL_VARIABLE_SCOPE) {
    newState = newState.updateIn(
      [LOCAL_VARIABLE_SCOPE, updatedVar.responseId],
      (variables) =>
        variables ? variables.push(updatedVar) : Immutable.List([updatedVar]),
    );
  } else {
    newState = newState.update(updatedVar.scope, (variables) =>
      variables.push(updatedVar),
    );
  }

  return newState;
};

/**
 * Merge variables from server with local data correctly
 * the same ID but different scope. We want to keep the one we modified.
 * @param {Immutable.List} stateVariables - The current variables in state
 * @param {Immutable.List} serverVariables - Variables returned from server
 * @returns {Immutable.Map}
 */
const mergeVariables = (stateVariables, serverVariables) => {
  const allVars = stateVariables.concat(serverVariables);
  // group variables by ID and only take the modified one
  let newState = allVars
    .groupBy((x) => x.id)
    .map((value) => {
      if (value.size === 1) {
        return value;
      }

      const modifiedVar = value.find((v) => v.modified);

      // return either the modified version, or the first one
      return modifiedVar || value.get(0);
    })
    .valueSeq()
    .toList()
    .flatten(true)
    // regroup by scope into a map
    .groupBy((x) => x.scope);
  let newStateLocals = newState.get(LOCAL_VARIABLE_SCOPE) || Immutable.Map();

  // regroup the local variables into a map by responseId
  if (newStateLocals) {
    newStateLocals = newStateLocals.groupBy((x) => x.responseId).toMap();
  }

  newState = newState.set(LOCAL_VARIABLE_SCOPE, newStateLocals);

  return initialState.merge(newState);
};

const modifyVariableInState = (state, variable, modifierFn) => {
  let newState = state;

  if (variable) {
    const updatePath =
      variable.scope === LOCAL_VARIABLE_SCOPE
        ? [variable.scope, variable.responseId]
        : [variable.scope];

    newState = newState.updateIn(updatePath, (vars) =>
      vars.map((v) => {
        if (v.id === variable.id) {
          return modifierFn(variable);
        }

        return v;
      }),
    );
  }

  return newState;
};

export const variables = (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case "GET_ALL_VARIABLES_SUCCESS":

    // We shouldn't do fallthrough, but I can't do anything about this right now
    // eslint-disable-next-line no-fallthrough
    case "GET_ALL_CLIENT_VARIABLES_SUCCESS": {
      const allVariables = Immutable.List(action.response.data.variables).map(
        (variable) => createVariableRecord(variable, true),
      );
      const currentStateFlattened = flattenVariables(nextState);

      nextState = mergeVariables(currentStateFlattened, allVariables);
      // maintain the empty keys from the previous "locals" map
      nextState = nextState.update(LOCAL_VARIABLE_SCOPE, (locals) =>
        state.get(LOCAL_VARIABLE_SCOPE).merge(locals),
      );
      lastSavedState = nextState;

      return nextState;
    }

    case "CREATE_UNSAVED_VARIABLE": {
      const variableData = action.variable;
      const { existingVar, responseId } = action;

      if (existingVar) {
        const updatePath =
          existingVar.scope === LOCAL_VARIABLE_SCOPE
            ? [LOCAL_VARIABLE_SCOPE, responseId]
            : [GLOBAL_VARIABLE_SCOPE];
        const isPreviouslyInactiveGlobalVar =
          existingVar.scope === GLOBAL_VARIABLE_SCOPE && !isActive(existingVar);

        return state.updateIn(updatePath, (scopedVariables) =>
          scopedVariables.map((scopedVariable) => {
            if (scopedVariable.id === existingVar.id) {
              const updatedVariable = existingVar.merge({
                key: variableData.key,
                modified: true,
                ...(isPreviouslyInactiveGlobalVar
                  ? { originId: responseId }
                  : {}),
              });

              return addResponseId(updatedVariable, responseId);
            }

            return scopedVariable;
          }),
        );
      }

      let varRecordInstance = variableData;

      if (!(variableData instanceof VariableRecord)) {
        varRecordInstance = createVariableRecord(variableData, false);
      }

      const updatePath =
        variableData.scope === LOCAL_VARIABLE_SCOPE
          ? [LOCAL_VARIABLE_SCOPE, variableData.responseId]
          : [variableData.scope];

      return state.updateIn(updatePath, Immutable.List(), (list) =>
        list.push(varRecordInstance),
      );
    }

    case "DELETE_VARIABLE": {
      const variableToDelete = getVariableById(action.variableId, state);

      // Variable may not exist, for example, if no variable is selected in deleted dropdown field
      if (!variableToDelete) {
        return state;
      }

      const { scope } = variableToDelete;

      if (!DELETABLE_VARIABLE_SCOPES.includes(scope)) {
        return state;
      }

      const updatePath =
        scope === LOCAL_VARIABLE_SCOPE
          ? [scope, variableToDelete.responseId]
          : [scope];

      return state.updateIn(updatePath, (vars) =>
        vars.map((variable) => {
          if (variable.id === variableToDelete.id) {
            return deleteResponseId(variable, action.responseId);
          }

          return variable;
        }),
      );
    }

    case "UPDATE_VARIABLE_SUCCESS":
    case "CREATE_VARIABLE_SUCCESS":
      if (action.variable.scope === GLOBAL_VARIABLE_SCOPE) {
        nextState = nextState.update(GLOBAL_VARIABLE_SCOPE, (vars) =>
          vars.map((variable) =>
            action.variable.id === variable.id
              ? updateFromServer(
                  variable,
                  action?.response?.data?.variable,
                  action.responseId,
                )
              : variable,
          ),
        );
      } else if (action.variable.scope === SENSITIVE_VARIABLE_SCOPE) {
        nextState = nextState.update(SENSITIVE_VARIABLE_SCOPE, (vars) =>
          vars.map((variable) =>
            action.variable.id === variable.id
              ? updateFromServer(
                  variable,
                  action?.response?.data?.variable,
                  action.responseId,
                )
              : variable,
          ),
        );
      } else if (action.variable.scope === LOCAL_VARIABLE_SCOPE) {
        // only local variables should have responseId
        nextState = nextState.updateIn(
          [LOCAL_VARIABLE_SCOPE, action.variable.responseId],
          (vars) =>
            vars.map((variable) =>
              action.variable.id === variable.id
                ? updateFromServer(
                    variable,
                    action?.response?.data?.variable,
                    action.responseId,
                  )
                : variable,
            ),
        );
      }

      lastSavedState = nextState;

      return nextState;

    case "DELETE_VARIABLE_SUCCESS":
      if (action.variable.scope === GLOBAL_VARIABLE_SCOPE) {
        nextState = nextState.update(GLOBAL_VARIABLE_SCOPE, (vars) =>
          vars.map((variable) =>
            action.variable.id === variable.id
              ? updateFromServer(
                  variable,
                  action?.response?.data?.variable,
                  action.responseId,
                )
              : variable,
          ),
        );
      } else if (action.variable.scope === SENSITIVE_VARIABLE_SCOPE) {
        nextState = nextState.update(SENSITIVE_VARIABLE_SCOPE, (vars) =>
          vars.map((variable) =>
            action.variable.id === variable.id
              ? updateFromServer(
                  variable,
                  action?.response?.data?.variable,
                  action.responseId,
                )
              : variable,
          ),
        );
      } else if (action.variable.scope === LOCAL_VARIABLE_SCOPE) {
        // only local variables should have responseId
        nextState = nextState.updateIn(
          [LOCAL_VARIABLE_SCOPE, action.variable.responseId],
          (vars) =>
            vars.map((variable) =>
              action.variable.id === variable.id
                ? updateFromServer(
                    variable,
                    action?.response?.data?.variable,
                    action.responseId,
                  )
                : variable,
            ),
        );
      }

      lastSavedState = nextState;

      return nextState;

    case "UNDO_RESPONSE":
      return lastSavedState || state;

    case "UPDATE_VARIABLE": {
      const { variable, updatedVar, responseId } = action;
      let newState = state;

      if (variable.scope !== updatedVar.scope) {
        return changeVariableScope(newState, {
          variable,
          updatedVar,
          responseId,
        });
      }

      if (updatedVar.scope === LOCAL_VARIABLE_SCOPE) {
        newState = newState.updateIn(
          [LOCAL_VARIABLE_SCOPE, responseId],
          (vars) =>
            vars.map((v) => {
              if (v.id === variable.id) {
                return updatedVar;
              }

              return v;
            }),
        );
      } else {
        newState = newState.update(updatedVar.scope, (vars) =>
          vars.map((v) => {
            if (v.id === variable.id) {
              return updatedVar;
            }

            return v;
          }),
        );
      }

      return newState;
    }

    case "SET_DROPDOWN_VARIABLE": {
      const { variable, newVariable, responseId } = action;

      let newState = state;

      // clicked on the same variable, do nothing
      if (variable && newVariable && variable.id === newVariable.id) {
        return state;
      }

      /*
        variable might be undefined when we are choosing for the first time
      */
      // delete responseId from old variable
      newState = modifyVariableInState(newState, variable, (v) =>
        deleteResponseId(v, responseId),
      );

      /*
        newVariable might be undefined when unsetting dropdown (on make new for example)
      */
      newState = modifyVariableInState(newState, newVariable, (v) =>
        addResponseId(v, responseId),
      );

      return newState;
    }

    case "UPDATE_COPIED_CONTENT_LOCAL_VARIABLES": {
      const { responseId } = action;

      let newState = state;

      const localVariables =
        state.getIn([LOCAL_VARIABLE_SCOPE, responseId]) || [];

      localVariables.forEach((variable) => {
        newState = modifyVariableInState(newState, variable, (v) =>
          addResponseId(v, responseId),
        );
      });

      return newState;
    }

    case "SAVE_BOT_CONTENT_SUCCESS": {
      const { responseId } = action;
      const variablesData = action.variables;

      if (!variablesData) {
        return nextState;
      }

      Object.keys(variablesData).forEach((variableId) => {
        const variableData = variablesData[variableId];
        let path = [variableData.scope];

        if (variableData.scope === LOCAL_VARIABLE_SCOPE) {
          path = [variableData.scope, responseId];
        }

        nextState = nextState.updateIn(path, (vars) =>
          vars.map((variable) =>
            variableData._id === variable.id
              ? updateFromServer(variable, variableData, responseId)
              : variable,
          ),
        );
      });

      lastSavedState = nextState;

      return nextState;
    }

    default:
      return state;
  }
};
