import { uuid } from "services/generate-uuid";

import { createBaseMessageRecord } from "./base";

export type ProcessInstructionMessageRecord = ReturnType<
  typeof createProcessInstructionMessageRecord
>;

export const createProcessInstructionMessageRecord = ({
  id = uuid(),
  instruction = "",
}: {
  id?: string;
  instruction?: string;
}) =>
  ({
    ...createBaseMessageRecord("process_instruction_block"),
    id,
    instruction,

    invalidFields: ((fields) => {
      // Instruction must have a value
      if (!instruction.trim()) fields.add("instruction.empty");
      else if (instruction.length > 320) fields.add("instruction.tooLong");

      return fields;
    })(new Set<`instruction.${"empty" | "tooLong"}`>()),

    merge(attributes: Record<string, unknown>) {
      return createProcessInstructionMessageRecord({
        ...this,
        ...attributes,
      });
    },
  } as const);
