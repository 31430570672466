// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import queryString from "query-string";
import TreeSelect from "rc-tree-select";
import {
  type DataNode,
  type LegacyDataNode,
} from "rc-tree-select/lib/interface";
import React, {
  type Key,
  type ReactElement,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  dropdownStyle,
  renderClearIcon,
  renderIcon,
  renderPlaceholder,
  treeStyles,
} from "components/Declarative/Pages/Folders/Modals/Helpers/iconRenderHelpers";
import { SwitcherIcon } from "components/Declarative/Pages/Folders/SwitcherIcon";
import { type CustomNode } from "reducers/folders/types/folderRecord";
import { adaApiRequest } from "services/api";
import { type Dispatch } from "types";

import { InputLabel } from "../InputLabel";
import { Loading } from "../Loading";

import * as S from "./styles";

export interface FolderSelectProps {
  initialValue?: React.Key;
  onSelectFolder: (id: Key) => void;
  folders: CustomNode[];
  defaultOpen?: boolean;
  label?: ReactNode;
  onExpandFolder: (data: LegacyDataNode) => Promise<void>;
  excludeIds?: Key[];
}
interface ParentPath {
  title: string;
  _id: string;
}
interface FolderSearchResult {
  breadcrumbs: ParentPath[];
  title: string;
  _id: string;
}

interface FolderSearchResultLabelProps {
  breadcrumbs: ParentPath[];
  title: string;
}

const FolderSearchResultLabel = ({
  title,
  breadcrumbs,
}: FolderSearchResultLabelProps) => (
  <S.FolderSearchResultLabelStyle>
    <div>{title}</div>
    {Boolean(breadcrumbs.length) && <div>In: {breadcrumbs[0].title}</div>}
  </S.FolderSearchResultLabelStyle>
);

export const FolderSelect = (props: FolderSelectProps) => {
  const {
    onSelectFolder,
    folders,
    defaultOpen = false,
    label = "Select Folder",
    onExpandFolder,
    excludeIds = [],
    initialValue = "",
  } = props;
  const dispatch: Dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [folderSearchResults, setFolderSearchResults] =
    useState<DataNode[]>(folders);

  const ref = useRef<HTMLDivElement>(null);

  const onDropdownVisibleChange = (open: boolean) => {
    setIsOpen(open);
  };

  const renderDropDown = (dropdownMenu: ReactElement) => {
    const { open } = dropdownMenu.props;

    return (
      <S.DropDownContainer isOpen={open}>
        {isLoading ? <Loading /> : dropdownMenu}
      </S.DropDownContainer>
    );
  };

  const [fetchSearchResults] = useDebouncedCallback(async () => {
    if (!searchValue.trim().length) {
      return;
    }

    setIsLoading(true);

    const res = await dispatch(
      adaApiRequest<{ results: FolderSearchResult[]; total_count: number }>({
        method: "GET",
        url: `/folders?${queryString.stringify({
          userinput: searchValue,
        })}`,
      }),
    );

    const folderNodes = res.data.results.map(
      ({ _id: id, title, breadcrumbs }) => ({
        label: (
          <FolderSearchResultLabel
            title={title === "ada-root" ? "All Answers" : title}
            breadcrumbs={breadcrumbs}
          />
        ),
        title: title === "ada-root" ? "All Answers" : title,
        value: id,
        key: id,
        children: [],
      }),
    );

    setIsLoading(false);
    setFolderSearchResults(
      folderNodes.filter((node) => !excludeIds.includes(node.key)),
    );
  }, 1000);

  useEffect(() => {
    const valueCleared = !searchValue.trim().length;

    if (valueCleared) {
      setFolderSearchResults(folders);
    } else {
      fetchSearchResults();
    }
  }, [
    searchValue,
    fetchSearchResults,
    setFolderSearchResults,
    folders,
    initialValue,
  ]);

  return (
    <S.FolderSelect ref={ref}>
      <InputLabel label={label} />
      <TreeSelect
        data-testid="folder-select"
        virtual={false}
        prefixCls="folders-select"
        value={initialValue}
        onChange={onSelectFolder}
        switcherIcon={SwitcherIcon}
        loadData={onExpandFolder}
        treeData={folderSearchResults}
        getPopupContainer={(node) => ref.current || node.parentNode}
        onDropdownVisibleChange={onDropdownVisibleChange}
        style={treeStyles}
        maxTagTextLength={30}
        defaultOpen={defaultOpen}
        placeholder={renderPlaceholder(isOpen)}
        dropdownStyle={dropdownStyle}
        showSearch
        onSearch={setSearchValue}
        searchValue={searchValue}
        labelInValue={false}
        dropdownRender={renderDropDown}
        treeIcon={(treeNodeProps) =>
          renderIcon(treeNodeProps, folderSearchResults.length > 0)
        }
        showTreeIcon
        allowClear
        loading={isLoading}
        filterTreeNode={false}
        notFoundContent="Folder not found"
        removeIcon={renderClearIcon()}
        treeNodeLabelProp="title"
      />
    </S.FolderSelect>
  );
};
