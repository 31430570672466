import { XMLValidator } from "fast-xml-parser";
import he from "he";
import React, { type ReactNode } from "react";

import { type VariableState } from "reducers/variables/types";
import { getVariable, varRegexp } from "services/records/variables";

export const escapeHTML = he.escape;

export const unescapeHTML = he.unescape;

export const stripVariables = (str = "") => str.replace(/{[a-f0-9]*\|.*}/, "");

export const isValidJSONString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const isValidXMLString = (xmlString: string) => {
  try {
    return XMLValidator.validate(xmlString) === true;
  } catch (e) {
    return false;
  }
};

// This regex escapes all special characters for highlighting
export const escapeRegexp = (string = "") =>
  string.replace(/[-[\]{}()*+?.,\\^$#\s]/g, "\\$&");

/**
 * Returns a copy of the selected response's description with variable values
 * substituted in
 */
export const replaceVariableIDJSX = (
  description: string,
  variables: VariableState,
) => {
  let matchResult;
  let resJSX: ReactNode[] = [];
  const varRegex = new RegExp(varRegexp.source, "gi");
  matchResult = varRegex.exec(description);
  let startingMatchIndex = 0;

  if (!matchResult) {
    return [description];
  }

  while (matchResult) {
    const { index } = matchResult;
    const match = matchResult[0];
    const varID = matchResult[1] as string;
    const variable = getVariable(varID, variables, false, true);

    if (variable) {
      resJSX = resJSX
        .concat(description.slice(startingMatchIndex, index))
        .concat(
          <span className="g-variable">${variable.getIn(["name"])}</span>,
        );
    } else {
      resJSX = resJSX
        .concat(description.slice(startingMatchIndex, index))
        .concat(match);
    }

    startingMatchIndex = index + match.length;

    matchResult = varRegex.exec(description);
  }

  resJSX = resJSX.concat(description.slice(startingMatchIndex));

  return resJSX;
};

export const camelCaseToSnakeCase = (str: string) =>
  str.replace(/([A-Z])/g, (g) => `_${(g[0] || "").toLowerCase()}`);

export const capitalizeFirstLetters = (strings: string[]) =>
  strings.map((str) => (str[0] || "").toUpperCase() + str.substring(1));

export function removeTemplateVariables(str: string): string {
  return str.replace(/{([\w]{24})\|(.*?)}/g, "");
}

export function onlyWhiteSpace(str: string): boolean {
  return !/\S/.test(str);
}

/**
 * returns a capitalized version of the string
 */
export function capitalizeFirstLetter(str: unknown = ""): string {
  if (typeof str !== "string") {
    throw new TypeError("Type of parameter str is not a string");
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * validateResponseMessageURL
 * return true if the url is a full, complete url string, or, starts with a variable.
 */
export const validateResponseMessageURL = (url = ""): boolean => {
  const linkWithTemplateVariablesRegex =
    /((([a-z]+:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b)|^({([\w]{24})\|(.*?)}))([-a-zA-Z0-9@:%_+.~#?&/=]*)/;

  const linkPattern = new RegExp(
    [
      "(^([a-z]+:(\\/\\/)?)", // protocol
      "(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]|", // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))", // OR ip (v4) address
      "(\\:\\d+)?", // port
      "(\\/[-a-z\\d%@_.~+&:]*)*", // path
      "(\\?[;&a-z\\d%@_.,~+&:=-]*)?", // query string
      "(\\#[-a-z\\d_]*)?$",
    ].join(""),
    "i",
  );

  const emailUrlPattern = new RegExp(
    [
      '^(mailto:)((([^<>()\\[\\]\\.,;:\\s@"]',
      '+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@',
      "((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|",
      "(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))",
      "|{(w{24})|([^}]+)?})$", // allow variables
    ].join(""),
    "i",
  );

  const telUrlPattern = new RegExp(
    [
      "^(tel:)",
      "(\\\\?)",
      "(\\*?)",
      "(\\+?)",
      "([0-9]{3,15}|{(w{24})|([^}]+)?})", // can be a number or a variable
      "$",
    ].join(""),
    "i",
  );

  const customProtocol = /^([a-z]+):\/\//i;

  const containsScript = /^(javascript:)/i;

  if (containsScript.test(url)) {
    return false;
  }

  return (
    linkWithTemplateVariablesRegex.test(url) ||
    linkPattern.test(url) ||
    emailUrlPattern.test(url) ||
    telUrlPattern.test(url) ||
    customProtocol.test(url)
  );
};

/**
 * If a string has a length greater than maxNameLength * 1.1, it's truncated at
 * maxNameLength or the first instance of whitespace between maxNameLength and maxNameLength * 1.1
 *
 * If a string has a length between maxNameLength and maxNameLength * 1.1, it's
 * truncated at the full length or first instance of whitespace between
 * maxNameLength and maxNameLength * 1.1
 *
 * If a string has a length of maxNameLength or less than maxNameLength, it's not truncated
 */
export function truncateLongNames(name: string, maxNameLength: number): string {
  if (name.length > maxNameLength) {
    let indexPositionToTruncateAt = name.indexOf(" ", maxNameLength);

    if (
      indexPositionToTruncateAt === -1 ||
      indexPositionToTruncateAt >
        Math.round(maxNameLength * 0.1) + maxNameLength
    ) {
      indexPositionToTruncateAt = maxNameLength;
    }

    const nameToRenderTruncated = name
      .slice(0, indexPositionToTruncateAt)
      .trimEnd();

    return `${nameToRenderTruncated}...`;
  }

  return name;
}

export function applyRedactionFont(text: string): string {
  const repeatCharacterRegex = /•/g;
  const repeatCharactersRegex = /•{3,}/g;
  const possibleCharacters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  // Test to see if there is a redaction
  const redacted = repeatCharactersRegex.test(text);

  // if no redactions, do not apply the font
  if (!redacted) {
    return text;
  }

  const redactionReplacement = () => {
    const randomCharacter =
      possibleCharacters[Math.floor(Math.random() * possibleCharacters.length)];

    return `<span style="font-family: redacted;">${randomCharacter}</span>`;
  };

  return text.replace(repeatCharacterRegex, redactionReplacement);
}

export const truncateEnd = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return `…${str.slice(-maxLength)}`;
  }

  return str;
};

export function utf8ToBase64(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(parseInt(p1, 16)),
    ),
  );
}

export function canEvalToEmptyString(str: string): boolean {
  // returns true if
  // the string can eval to empty after fallback substitutions
  let i = 0;
  const N = str.length;

  if (N === 0) {
    return true;
  }

  const regex = /{([\w]{24})\|(.*?)}/g;
  let lastMatchEnd = 0;

  while (i < N) {
    const match = regex.exec(str);

    // If no match is found or characters outside placeholders are detected
    if (!match || match.index !== i) {
      return false;
    }

    // Check if the fallback is an empty string
    if (match[2] !== "") {
      return false;
    }

    i = regex.lastIndex;
    lastMatchEnd = regex.lastIndex;
  }

  // Ensure that the last match ended exactly at the end of the string
  return lastMatchEnd === N;
}
