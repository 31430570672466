// @ts-strict-ignore
import Immutable from "immutable";

import { CSATSatisfactionStyle } from "services/csat/types";
import RecordClassCreator, {
  type RecordAttributeMapper,
} from "services/record";

import { CSATSettingsFeedbackOption, type TranslationMap } from "./helpers";

export interface CSATSettingsAttributes {
  satisfactionStyle?: CSATSatisfactionStyle;
  satisfactionRange?: 2 | 5;
  satisfactionLabel: TranslationMap;
  followUpFeedbackIsEnabled?: boolean;
  followUpFeedbackNegativeLabel: TranslationMap;
  followUpFeedbackPositiveLabel: TranslationMap;
  followUpPositiveFeedbackOptions: Immutable.List<CSATSettingsFeedbackOption>;
  followUpNegativeFeedbackOptions: Immutable.List<CSATSettingsFeedbackOption>;
  resolutionIsEnabled?: boolean;
  resolutionLabel: TranslationMap;
  openFeedbackIsEnabled?: boolean;
  openFeedbackLabel: TranslationMap;
  postChatSurveyIsEnabled: boolean;
  respondToNegativeFeedback?: boolean;
  respondToPositiveFeedback?: boolean;
  anytimeSurveyIsEnabled?: boolean;
  csatSubmit: TranslationMap;
  csatClose: TranslationMap;
  csatYes: TranslationMap;
  csatNo: TranslationMap;
  csatTerrible: TranslationMap;
  csatGood: TranslationMap;
  csatExcellent: TranslationMap;
  order: string[];
}

export class CSATSettings extends RecordClassCreator<CSATSettingsAttributes>({
  type: "csatSettings",

  satisfactionStyle: CSATSatisfactionStyle.EMOJI,
  satisfactionRange: 5,
  satisfactionLabel: null,
  followUpFeedbackIsEnabled: false,
  followUpFeedbackNegativeLabel: null,
  followUpPositiveFeedbackOptions: Immutable.List(),
  followUpNegativeFeedbackOptions: Immutable.List(),
  followUpFeedbackPositiveLabel: null,
  resolutionIsEnabled: false,
  resolutionLabel: null,
  openFeedbackIsEnabled: false,
  openFeedbackLabel: null,
  postChatSurveyIsEnabled: false,
  respondToNegativeFeedback: false,
  respondToPositiveFeedback: false,
  anytimeSurveyIsEnabled: true,
  csatSubmit: null,
  csatClose: null,
  csatYes: null,
  csatNo: null,
  csatTerrible: null,
  csatGood: null,
  csatExcellent: null,
  order: [],
}) {
  static attributeMapper: RecordAttributeMapper<CSATSettingsAttributes> = {
    followUpPositiveFeedbackOptions: (
      value: {
        id: string;
        label: Record<string, string>;
      }[],
    ) =>
      value.map(
        (option) =>
          new CSATSettingsFeedbackOption({
            id: option.id,
            label: Immutable.Map(option.label),
          }),
      ),
    followUpNegativeFeedbackOptions: (
      value: {
        id: string;
        label: Record<string, string>;
      }[],
    ) =>
      value.map(
        (option) =>
          new CSATSettingsFeedbackOption({
            id: option.id,
            label: Immutable.Map(option.label),
          }),
      ),
  };
}
