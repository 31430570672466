// @ts-strict-ignore
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModalAction } from "actions/modal";
import { getResponseById } from "actions/responses";
import { Banner } from "components/Common/Banner";
import { BusinessEventSelector } from "components/Common/BusinessEventSelector";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import ResponseSelector from "components/Common/ResponseSelector";
import { convertToABTestResponseAction } from "features/ABTesting/actions";
import { saveBuilderABTestAction } from "features/ABTesting/actionsUsedInResponseActions";
import { DEFAULT_BUILDER_AB_TEST } from "features/ABTesting/constants";

import * as S from "./styles";

const MAX_AB_TEST_NAME_LENGTH = 60;

interface Props {
  initialResponseId?: string;
}

export function EditBuilderABTestModal({ initialResponseId }: Props) {
  const [name, setName] = useState("");
  const [responseId, setResponseId] = useState(initialResponseId);
  const [businessEventKey, setBusinessEventKey] = useState<string | null>(null);
  const dispatch = useDispatch();

  const tests = useSelector(
    (state) => state.builderABTestsState.builderABTests,
  );
  const testEventKeys = tests
    .filter((test) => test.status !== "complete")
    .map((test) => test.businessEventKey);
  const eventAlreadyUsed = testEventKeys.includes(businessEventKey);
  const responseABTests = useSelector(
    (state) => state.builderABTestsState.builderABTests,
  );
  const response = useSelector((state) =>
    responseId ? state.responsesLoaded.get(responseId) : null,
  );
  const isNameInvalid = !name.trim() || name.length > MAX_AB_TEST_NAME_LENGTH;

  // We need the response for when a new test is created and its answer is converted to AB test
  // format - otherwise it will fail to convert and the data will be in an invalid state.
  useEffect(() => {
    if (responseId) {
      dispatch(getResponseById({ responseId }));
    }
  }, [dispatch, responseId]);

  return (
    <div className="Modal__modal Modal__modal--small">
      <S.ModalContent>
        <S.ModalSection>
          <S.ModalTitle>Create a new A/B Test</S.ModalTitle>
          <S.ModalDescription>
            Give your Test a descriptive name to remind you of what outcome
            you&apos;re trying to improve.
          </S.ModalDescription>
        </S.ModalSection>
        <S.ModalSection>
          <DebouncedInput
            id="builderABTestName"
            label="Test name"
            value={name}
            isInvalid={isNameInvalid}
            characterCounter={MAX_AB_TEST_NAME_LENGTH}
            onChange={setName}
          />
        </S.ModalSection>
        <S.ModalSection>
          <label className="g-input__label" htmlFor="builderABTestResponseId">
            Answer to test
          </label>
          <ResponseSelector
            onChange={(newResponseId) => setResponseId(newResponseId)}
            selectedResponseId={responseId}
            isInvalid={!responseId}
            responseIdsToExclude={responseABTests
              .filter((test) => test.status !== "complete")
              .map((test) => test.responseId)}
            disabled={Boolean(initialResponseId)}
          />
          {response?.isDirty && (
            <S.MarginTop8px>
              <Banner icon="InfoFilled" intent="negative">
                This Answer is in an unsaved state, which can&apos;t be used
                with an A/B Test. Save or discard your changes before setting up
                this Test.
              </Banner>
            </S.MarginTop8px>
          )}
        </S.ModalSection>
        <S.ModalSection>
          <label
            className="g-input__label"
            htmlFor="builderABTestBusinessEventId"
          >
            Metric to measure
          </label>
          <S.InfoText>
            The Event you choose to measure against for this Test will determine
            which variant is the ‘winner’ when there are conclusive test
            results.
          </S.InfoText>
          <BusinessEventSelector
            onChange={(eventKey: string) => setBusinessEventKey(eventKey)}
            selectedEventKey={businessEventKey}
            isInvalid={!businessEventKey}
          />
          {eventAlreadyUsed && (
            <S.MarginTop8px>
              <Banner icon="InfoFilled">
                This Event is already being used in another Test. You can use it
                for this Test too, but it may lead to less reliable results.
              </Banner>
            </S.MarginTop8px>
          )}
        </S.ModalSection>
      </S.ModalContent>
      <S.ButtonsContainer className="Modal__bottom">
        <Button
          light
          onClick={() => dispatch(closeModalAction())}
          text="Cancel"
          title="Cancel"
          disabled={false}
        />
        <Button
          onClick={async () => {
            const data = await dispatch(
              saveBuilderABTestAction({
                ...DEFAULT_BUILDER_AB_TEST,
                name,
                responseId,
                businessEventKey,
                status: "draft",
              }),
            );
            // Not sure how to assign type to the http response
            dispatch(
              convertToABTestResponseAction(
                responseId,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data.upserted_id,
              ),
            );
            dispatch(closeModalAction());
          }}
          text="Create"
          title="Create"
          disabled={
            isNameInvalid ||
            !responseId ||
            !businessEventKey ||
            !response ||
            response.isDirty
          }
          isLoading={responseId && !response}
        />
      </S.ButtonsContainer>
    </div>
  );
}
