import { Button, Notification } from "@adasupport/byron";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import isURL from "validator/lib/isURL";

import { closeModalAction, openModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { InputText } from "components/Common";
import * as Modal from "components/Declarative/Pages/Folders/Modals/styles";
import {
  type StartUrl,
  type TransformedScraperCollection,
} from "components/Generative/Pages/Knowledge/SourcesItem/types";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { HUBSPOT_EVENTS, useHubspotEvents } from "hooks/useHubspotEvents";
import {
  useCreateWebScraperMutation,
  useDeleteWebScrapeMutation,
  useGetWebScraperDataQuery,
} from "slices/webScraper/webScraperApiSlice";

import { useCloseWebsiteImportModal } from "../hooks";

import { RadioButtonRow } from "./RadioButtonRow";
import {
  IMPORT_OPTIONS,
  INITIAL_KNOWLEDGE_SOURCE_NAME,
  IS_URL_CONFIG,
  checkDuplicateUrls,
  fixProtocolAndTrimUrl,
} from "./helpers";
import * as S from "./styles";

interface Props {
  scrapeData?: TransformedScraperCollection;
}

export const WebsiteImportModal = ({ scrapeData }: Props) => {
  const dispatch = useDispatch();
  const { sendHubspotEvent } = useHubspotEvents();
  const { data: scraperSources } = useGetWebScraperDataQuery();
  const [createWebScrape] = useCreateWebScraperMutation();
  const [deleteWebScrape] = useDeleteWebScrapeMutation();
  const [sourceName, setSourceName] = useState("");
  const [crawlUrl, setCrawlUrl] = useState("");
  const [crawlMultipleUrls, setCrawlMultipleUrls] = useState("");
  const [selectedImport, setSelectedImport] = useState(IMPORT_OPTIONS.CRAWL);
  const [isRescrape, setIsRescrape] = useState(false);
  const closeModal = useCloseWebsiteImportModal();
  const [showErrors, setShowErrors] = useState(false);
  const [hasDuplicateUrlsError, setHasDuplicateUrlsError] = useState(false);
  const [hasSingleUrlError, setHasSingleUrlError] = useState(false);
  const [hasCrawlMultipleUrlsError, setHasCrawlMultipleUrlsError] =
    useState(false);
  const MAX_LENGTH_URLS = 5000;

  useComponentDidMount(() => {
    if (scrapeData) {
      setIsRescrape(true);
      setSourceName(scrapeData.name);

      const firstUrl = scrapeData.startUrls[0];

      // In future, we might configure `crawl` on a per-url basis
      if (firstUrl?.crawl && scrapeData.startUrls.length === 1) {
        setCrawlUrl(firstUrl.url);
      } else {
        setSelectedImport(IMPORT_OPTIONS.SPECIFIC_PAGES);
        setCrawlMultipleUrls(
          scrapeData.startUrls.map((url) => url.url).join(", "),
        );
      }
    }
  });

  useEffect(() => {
    if (
      isRescrape ||
      (selectedImport === IMPORT_OPTIONS.CRAWL && crawlUrl.length === 0) ||
      (selectedImport === IMPORT_OPTIONS.SPECIFIC_PAGES &&
        crawlMultipleUrls.length === 0)
    ) {
      setHasDuplicateUrlsError(false);

      return;
    }

    let startUrls: StartUrl[] = [];
    let existingStartUrls: StartUrl[] = [];
    existingStartUrls = scraperSources
      ? scraperSources.map((source) => source.startUrls).flat()
      : [];

    if (selectedImport === IMPORT_OPTIONS.CRAWL) {
      const crawlUrlTrimmed = fixProtocolAndTrimUrl(crawlUrl);
      startUrls.push({ url: crawlUrlTrimmed, crawl: true });
    } else if (selectedImport === IMPORT_OPTIONS.SPECIFIC_PAGES) {
      startUrls = crawlMultipleUrls
        .split(",")
        .map((url) => fixProtocolAndTrimUrl(url))
        .map((url) => ({ url, crawl: false }));
    }

    if (startUrls.length > 0 && existingStartUrls.length > 0) {
      const hasDuplicateUrl = checkDuplicateUrls(startUrls, existingStartUrls);

      if (hasDuplicateUrl) {
        setHasDuplicateUrlsError(true);
      } else {
        setHasDuplicateUrlsError(false);
      }
    }
  }, [crawlMultipleUrls, crawlUrl, scraperSources, selectedImport, isRescrape]);

  const deleteSource = async () => {
    const message =
      "All articles from this source will be removed and your AI Agent will no longer be able to answer inquiries using this content.";
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Delete source",
        message,
        actions: [
          {
            title: "Delete",
            buttonTint: "alert",
            onClick: async () => {
              if (scrapeData) {
                const isOnboardingScrapeCollection =
                  scrapeData.name === INITIAL_KNOWLEDGE_SOURCE_NAME;
                await deleteWebScrape({
                  knowledgeSourceId: scrapeData.knowledgeSourceId,
                  isOnboardingScrapeCollection,
                });
              }

              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };

  const submitRequest = async () => {
    if (sourceName === "") {
      setShowErrors(true);

      return;
    }

    setShowErrors(false);
    setHasSingleUrlError(false);
    setHasCrawlMultipleUrlsError(false);

    let startUrls: StartUrl[] = [];
    let knowledgeSourceId: string | undefined;
    let knowledgeSourceName: string = sourceName;

    if (!isRescrape) {
      // Check URLs are valid, set errors, construct startUrls
      if (selectedImport === IMPORT_OPTIONS.CRAWL) {
        const crawlUrlTrimmed = fixProtocolAndTrimUrl(crawlUrl);
        startUrls.push({ url: crawlUrlTrimmed, crawl: true });

        if (!crawlUrlTrimmed || !isURL(crawlUrlTrimmed, IS_URL_CONFIG)) {
          setHasSingleUrlError(true);
          setShowErrors(true);

          return;
        }
      }

      if (selectedImport === IMPORT_OPTIONS.SPECIFIC_PAGES) {
        startUrls = crawlMultipleUrls
          .split(",")
          .map((url) => fixProtocolAndTrimUrl(url))
          .map((url) => ({ url, crawl: false }));

        if (
          startUrls.length === 0 ||
          startUrls.some((startUrl) => !isURL(startUrl.url, IS_URL_CONFIG))
        ) {
          setHasCrawlMultipleUrlsError(true);
          setShowErrors(true);

          return;
        }
      }
    }

    try {
      if (isRescrape && scrapeData) {
        knowledgeSourceName = scrapeData.name;
        startUrls = scrapeData.startUrls;
        knowledgeSourceId = scrapeData.knowledgeSourceId;
      }

      await createWebScrape({
        sourceName: knowledgeSourceName,
        startUrls,
        knowledgeSourceId,
      });

      const nonInitialSources = scraperSources?.filter(
        (source) => source.name !== INITIAL_KNOWLEDGE_SOURCE_NAME,
      );

      if (nonInitialSources?.length === 0) {
        sendHubspotEvent(HUBSPOT_EVENTS.ADD_KNOWLEDGE);
      }

      if (!window.location.pathname.endsWith("/external-sources")) {
        dispatch(setPageAction("/content/knowledge/external-sources"));
      }

      closeModal();
    } catch (error) {
      closeModal();
    }
  };

  return (
    <Modal.Modal>
      <S.ModalHeader>
        <S.ModalTitle>Import website</S.ModalTitle>
      </S.ModalHeader>
      <S.ModalBody>
        <S.ModalSubtitle>
          Train your AI Agent with content from your website, such as a blog or
          FAQ page.
        </S.ModalSubtitle>
        <S.InputRow>
          <S.ModalInput label="Source name">
            <InputText
              value={sourceName}
              onChange={(value: string) => {
                setSourceName(value);
                setShowErrors(false);
              }}
              placeholder="Ada Website"
              invalid={showErrors && sourceName === ""}
              disabled={isRescrape}
              invalidMessage="Required"
            />
          </S.ModalInput>
        </S.InputRow>
        <S.RadioLabel>Content to import</S.RadioLabel>
        <RadioButtonRow
          id="crawl"
          selected={selectedImport}
          option={IMPORT_OPTIONS.CRAWL}
          setOption={setSelectedImport}
          label="Every webpage starting from one URL"
          description='All webpages that start with the given URL will be imported (e.g. "https://blog.ada.cx/anypage").'
          disabled={isRescrape}
        >
          <S.RadioInputContainer>
            <InputText
              value={crawlUrl}
              invalid={showErrors && hasSingleUrlError}
              invalidMessage="Specify a valid URL"
              hideScrollbars
              onChange={(value: string) => {
                setCrawlUrl(value);
                setShowErrors(false);
              }}
              placeholder="https://blog.ada.cx"
              disabled={isRescrape}
            />
          </S.RadioInputContainer>
        </RadioButtonRow>
        <RadioButtonRow
          id="specific_pages"
          selected={selectedImport}
          option={IMPORT_OPTIONS.SPECIFIC_PAGES}
          setOption={setSelectedImport}
          label="A specific list of webpages"
          description="Only these webpages will be imported."
          disabled={isRescrape}
        >
          <S.RadioInputContainer>
            <InputText
              multiline
              maxLength={MAX_LENGTH_URLS}
              minRows={3}
              showCounter
              hideScrollbars
              value={crawlMultipleUrls}
              invalid={showErrors && hasCrawlMultipleUrlsError}
              invalidMessage="List valid URLs"
              onKeyDown={(event: React.KeyboardEvent<Element>) => {
                // Prevent typing if maxLength is reached and the key pressed is not a control key (e.g., Backspace)
                if (
                  crawlMultipleUrls.length >= MAX_LENGTH_URLS &&
                  event.key.length === 1
                ) {
                  event.preventDefault();
                }
              }}
              onChange={(values: string) => {
                setCrawlMultipleUrls(values);
                setShowErrors(false);
              }}
              placeholder="https://www.ada.cx/pricing, https://www.ada.cx/faq"
              helperMessage="Separate webpages with commas"
              disabled={isRescrape}
            />
          </S.RadioInputContainer>
        </RadioButtonRow>
        {hasDuplicateUrlsError && (
          <Notification
            type="info"
            text="If you continue this import, we recommend setting redundant content to “Inactive” for best performance."
            title="Some content from this site has been imported before."
          />
        )}
      </S.ModalBody>
      <S.ModalFooter>
        <div>
          {isRescrape && (
            <Button
              variant="tertiary danger"
              text="Delete source"
              onClick={deleteSource}
            />
          )}
        </div>
        <div>
          <Button
            variant="secondary"
            text="Cancel"
            onClick={closeModal}
            dataTestId="knowledge-import-modal-cancel"
          />
          <Button
            text={isRescrape ? "Import again" : "Import"}
            isDisabled={
              showErrors &&
              (sourceName === "" ||
                hasSingleUrlError ||
                hasCrawlMultipleUrlsError)
            }
            dataTestId={`knowledge-import-modal-${
              isRescrape ? "rescrape" : "import"
            }`}
            onClick={submitRequest}
          />
        </div>
      </S.ModalFooter>
    </Modal.Modal>
  );
};
