import type Immutable from "immutable";

import {
  type TriggerCondition,
  type TriggerConditionDto,
} from "reducers/common/types";
import { type Goal } from "reducers/goals/types";

export type StatusOption = "draft" | "active" | "off" | "deleted";
export type MessageFrequencyOption =
  | "every-time"
  | "once-per-user"
  | "once-per-session";
export type CampaignChannelOption = "web" | "sms";

export interface BaseCampaign {
  id: string;
  campaignKey: string;
  name: string;
  description: string;
  status: StatusOption;
  goals: Goal[];
  created: number | null;
  updated: number | null;
  updatedBy: string;
  responseId?: string | null;
  channel: CampaignChannelOption;
}

export interface BaseCampaignDto {
  _id: string;
  campaign_key: string;
  updated_by: string;
  name: string;
  description: string;
  status: StatusOption;
  goals: unknown[];
  created: number | null;
  updated: number | null;
  channel: CampaignChannelOption;
}

export interface WebCampaignDto extends BaseCampaignDto {
  trigger_conditions: (TriggerConditionDto | null)[];
  channel: "web";
  message_frequency: MessageFrequencyOption;
  message_delay: number;
  message_duration: number | null;
  follow_up_response_id: string | null | undefined;
  response_id: string | null;
  message_text: Immutable.Map<string, string> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface SMSCampaignDto extends BaseCampaignDto {
  is_compliance_confirmed: boolean;
  channel: "sms";
  response_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type CampaignDto = WebCampaignDto | SMSCampaignDto;

const DEFAULT_BASE_CAMPAIGN: BaseCampaign = {
  id: "",
  campaignKey: "",
  name: "",
  description: "",
  status: "draft",
  updatedBy: "",
  goals: [],
  updated: null,
  created: null,
  channel: "web",
};

export interface WebCampaign extends BaseCampaign {
  triggerConditions: TriggerCondition[];
  messageFrequency: MessageFrequencyOption;
  messageDelay: number;
  messageDuration: number | null;
  followUpResponseId?: string | null;
  responseId?: string | null;
  messageText?: Immutable.Map<string, string> | null;
  channel: "web";
}

export const DEFAULT_WEB_CAMPAIGN: WebCampaign = {
  ...DEFAULT_BASE_CAMPAIGN,
  triggerConditions: [],
  messageFrequency: "every-time",
  messageDelay: 0,
  messageDuration: null,
  followUpResponseId: null,
  responseId: null,
  messageText: null,
  channel: "web",
};

export interface SMSCampaign extends BaseCampaign {
  isComplianceConfirmed: boolean;
  channel: "sms";
  responseId: string;
}

export const DEFAULT_SMS_CAMPAIGN: SMSCampaign = {
  ...DEFAULT_BASE_CAMPAIGN,
  isComplianceConfirmed: false,
  channel: "sms",
  responseId: "",
  status: "active",
} as const;

export type Campaign = WebCampaign | SMSCampaign;

export interface CampaignsState {
  loading: boolean;
  loaded: boolean;
  campaigns: Campaign[];
  searchQuery: string;
  filters: Set<string>;
}

export const DEFAULT_CAMPAIGNS_STATE: CampaignsState = {
  loading: false,
  loaded: false,
  campaigns: [],
  searchQuery: "",
  filters: new Set(),
};
