import { tokens } from "@adasupport/byron";
import React from "react";
import styled from "styled-components";

import { RULE_DSL, type RuleGroup } from "services/rules";

export const RULE_GROUP_OPERATOR_WIDTH = 43;

const S = {
  RuleGroupOperator: styled.div`
    color: ${tokens.colors.text.muted};
    display: flex;
    height: 36px;
    align-items: center;
    min-width: ${RULE_GROUP_OPERATOR_WIDTH}px;
    justify-content: flex-end;
    font-size: 14px;
  `,
};

export const RuleGroupOperator = ({
  ruleGroup,
  index,
}: {
  ruleGroup: RuleGroup;
  index: number;
}) => (
  <S.RuleGroupOperator>
    {(() => {
      if (index === 0) return "Where";

      switch (ruleGroup.name) {
        case RULE_DSL.ALL:
          return "and";
        case RULE_DSL.ANY:
          return "or";
        default:
          return null;
      }
    })()}
  </S.RuleGroupOperator>
);
